@import (reference) "../shared/mixins";
@import (reference) "../shared/variables";

.paginator {
  .font-size(12);

  padding: 10px;
  color: @color-grey;
  border-top: 1px solid @color-borders;

  .pg-page-count,
  .pg-show-all-files {
    float: left;
    font-weight: bold;
    padding: 3px 0;
  }

  .pg-show-all-files {
    float: right;
  }

  .pg-page-nav {
    float: right;

    a {
      background: @color-background;
      border-radius: 4px;
      border: 1px solid @color-borders;
      color: @color-primary;
      display: inline-block;
      padding: 3px 8px;

      &:hover {
        border-color: @color-primary;
      }
    }

    li {
      float: left;
      margin: 0 3px;

      &.highlighted a {
        border-color: @color-primary;
      }

      &.disabled a {
        color: @color-grey;
        cursor: default;

        &:hover {
          border-color: @color-grey;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .pg-page-count,
    .pg-show-all-files,
    .pg-page-nav {
      float: none;
      display: block;
      text-align: center;
    }

    .pg-page-count,
    .pg-show-all-files {
      margin-bottom: 10px;
    }

    .pg-page-nav {
      width: 100%;
      margin: 0 auto;

      li {
        float: none;
        display: inline-block;
        margin: 0 3px;
      }
    }

    .pg-page-count {
      margin-top: 5px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
