.container.news-container {
  border: 1px solid @color-borders;

  > header {
    background-color: @color-background;
    border-bottom: 1px solid @color-borders;
  }

  .news-list {
    > li {
      background-color: @color-white;
    }
  }
}
