#files-upload-container {
  .upload-progress {
    width: 100%;
    margin: 10px 0;
  }

  .upload-success {
    color: @color-success;
    line-height: 3em;
  }

  .upload-eta {
    font-size: 0.9em;
    margin-top: 0;
    margin-bottom: 2em;
    font-style: italic;
    color: @color-medium-gray;
  }

  #files-upload {
    &.active,
    &.complete {
      .file-field {
        margin-bottom: 2px;
      }

      .btn-file {
        display: none;
      }
    }

    &.complete {
      .upload-success {
        display: block;
      }
    }
  }
}
