/**
 * Style-sheet for dtpicker
 * https://github.com/mugifly/jquery-simple-datetimepicker
 */

.datepicker {
  display: inline-block;
  font: 15px/1.5 "Helvetica Neue", mplus-2c, Helvetica, Arial, "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
  font-weight: 300;
  border: 1px solid @color-light-grey;
  border-radius: 3px;
  border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0.5px 0.5px 0 @color-grey-shadow;
  box-shadow: 0.5px 0.5px 3px @color-med-grey;
  box-shadow: 0.5px 0.5px 3px @color-med-grey;
}

/*
 * datepicker_header
*/

.datepicker > .datepicker_header {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: @color-med-grey;
  color: @color-dark-grey;
  text-align: center;
  font-size: 9pt;
  font-weight: bold;
  user-select: none;
  user-select: none;
  user-select: none;
}

.datepicker > .datepicker_header > a {
  user-select: none;
  user-select: none;
  user-select: none;
  cursor: pointer;
  color: @color-primary;
}

.datepicker > .datepicker_header > a:hover {
  color: @color-dark-grey;
  background-color: @color-grey;
}

.datepicker > .datepicker_header > a:active {
  color: @color-white;
  background-color: @color-medium-gray;
}

.datepicker > .datepicker_header > span {
  margin-left: 20px;
  margin-right: 20px;
  user-select: none;
  user-select: none;
  user-select: none;
}

.datepicker > .datepicker_header > .icon-home {
  position: absolute;
  display: block;
  float: left;
  margin-top: 2px;
  margin-left: 5px;
  width: 11pt;
  height: 11pt;
  vertical-align: middle;
}

.datepicker > .datepicker_header > .icon-home > svg > g > path {
  fill: @color-primary;
}

.datepicker > .datepicker_header > a:hover > svg > g > path {
  fill: @color-dark-grey; /* Icon button hover color */
}

/*
 * datepicker_inner_container
*/

.datepicker > .datepicker_inner_container {
  margin: -2px 0;
  background-color: @color-grey;
  border: 1px solid @color-grey;
  border-radius: 3px;
  border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0.5px 0 3px @color-grey;
  box-shadow: 0.5px 0 3px @color-grey;
  box-shadow: 0.5px 0 3px @color-grey;
}

.datepicker > .datepicker_inner_container::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/*
 * datepicker_inner_container > datepicker_calendar
*/

.datepicker > .datepicker_inner_container > .datepicker_calendar {
  float: left;
  width: auto;
  margin-top: -0.5px;
  margin-left: -1px;
  margin-bottom: -2px;
  background-color: @color-white;
  border: 1px solid @color-grey;
  border-top: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
}

.datepicker > .datepicker_inner_container > .datepicker_calendar > table {
  padding: 10px;
}

/*
 * datepicker_inner_container > datepicker_calendar > datepicker_table > tbody > tr > th (WDay-cell)
*/

.datepicker > .datepicker_inner_container > .datepicker_calendar > .datepicker_table > tbody > tr > th {
  color: @color-medium-dark-gray;
  width: 18px;
  font-size: small;
  font-weight: normal;
  text-align: center;
}

/*
 * datepicker_inner_container > datepicker_calendar > datepicker_table > tbody > tr > td (Day-cell)
*/

.datepicker > .datepicker_inner_container > .datepicker_calendar > .datepicker_table > tbody > tr > td {
  color: @color-black;
  font-size: small;
  text-align: center;
  user-select: none;
  user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 1ex;
}

.datepicker > .datepicker_inner_container > .datepicker_calendar > .datepicker_table > tbody > tr > td.today {
  border-bottom: #bfbfbf solid 2px;
  margin-bottom: -2px;
}

.datepicker > .datepicker_inner_container > .datepicker_calendar > .datepicker_table > tbody > tr > td.wday_sat {
  color: @color-blue;
}

.datepicker > .datepicker_inner_container > .datepicker_calendar > .datepicker_table > tbody > tr > td.wday_sun {
  color: @color-red;
}

.datepicker > .datepicker_inner_container > .datepicker_calendar > .datepicker_table > tbody > tr > td.day_another_month {
  color: @color-grey;
}

.datepicker > .datepicker_inner_container > .datepicker_calendar > .datepicker_table > tbody > tr > td.day_in_past {
  cursor: default;
  color: @color-grey;
}

.datepicker > .datepicker_inner_container > .datepicker_calendar > .datepicker_table > tbody > tr > td.day_in_unallowed {
  cursor: default;
  color: @color-grey;
}

.datepicker > .datepicker_inner_container > .datepicker_calendar > .datepicker_table > tbody > tr > td.out_of_range {
  cursor: default;
  color: @color-grey;
}

.datepicker > .datepicker_inner_container > .datepicker_calendar > .datepicker_table > tbody > tr > td.active {
  color: @color-white;
  background-color: @color-medium-gray;
}

.datepicker > .datepicker_inner_container > .datepicker_calendar > .datepicker_table > tbody > tr > td.hover {
  color: @color-black;
  background-color: @color-grey;
}

/*
 * datepicker_inner_container > datepicker_timelist
*/

.datepicker > .datepicker_inner_container > .datepicker_timelist {
  float: left;
  width: 4.2em;
  height: 118px;
  margin-top: -0.5px;
  padding: 5px;
  padding-left: 0;
  padding-right: 0;
  overflow: auto;
  overflow-x: hidden;
  background-color: @color-white;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
}

/*
.datepicker > .datepicker_inner_container > .datepicker_timelist::after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
*/

.datepicker > .datepicker_inner_container > .datepicker_timelist::-webkit-scrollbar {
  overflow: hidden;
  width: 6px;
  background: @color-light-grey;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
}

.datepicker > .datepicker_inner_container > .datepicker_timelist::-webkit-scrollbar:horizontal {
  height: 1px;
}

.datepicker > .datepicker_inner_container > .datepicker_timelist::-webkit-scrollbar-button {
  display: none;
}

.datepicker > .datepicker_inner_container > .datepicker_timelist::-webkit-scrollbar-piece {
  background: @color-med-grey;
}

.datepicker > .datepicker_inner_container > .datepicker_timelist::-webkit-scrollbar-piece:start {
  background: @color-med-grey;
}

.datepicker > .datepicker_inner_container > .datepicker_timelist::-webkit-scrollbar-thumb {
  background: @color-grey;
  border-radius: 3px;
  border-radius: 3px;
  border-radius: 3px;
}

.datepicker > .datepicker_inner_container > .datepicker_timelist::-webkit-scrollbar-corner {
  background: @color-dark-grey;
}

.datepicker > .datepicker_inner_container > .datepicker_timelist > div.timelist_item {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 7px;
  padding-right: 25px;
  margin-top: 5px;
  margin-bottom: 2px;
  font-size: small;
  user-select: none;
  user-select: none;
  user-select: none;
  cursor: pointer;
}

.datepicker > .datepicker_inner_container > .datepicker_timelist > div.timelist_item.time_in_past {
  cursor: default;
  color: @color-grey;
}

.datepicker > .datepicker_inner_container > .datepicker_timelist > div.timelist_item.out_of_range {
  cursor: default;
  color: @color-grey;
}

.datepicker > .datepicker_inner_container > .datepicker_timelist > div.timelist_item.active {
  color: @color-white;
  background-color: @color-medium-gray;
}

.datepicker > .datepicker_inner_container > .datepicker_timelist > div.timelist_item.hover {
  color: @color-black;
  background-color: @color-grey;
}
