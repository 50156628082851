.audio-to-video {
  .video-settings {
    .video-preview-outer {
      position: relative;
      height: 450px;
    }

    .form-field {
      .drop-file-area {
        padding: 10px;
        font-size: 16px;
        text-align: left;

        .drop-area {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            top: initial;
            left: 40px;
            margin-left: 0;
          }

          .fa {
            top: 8px;
            left: 15px;
            font-size: 1.6em;
          }
        }
      }

      .color-input {
        width: 100%;
        float: left;
      }

      .color-picker {
        width: 50%;
        float: left;
        padding-left: 12px;

        img {
          width: 173px;
          height: 39px;
          .border-radius(3px);
        }
      }
    }

    .artwork-file {
      padding-top: 9px;

      > div {
        display: block;
        float: left;
        width: 33%;
      }

      .artwork-filename {
        position: relative;
        width: 37%;
        padding-right: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .fa {
          position: absolute;
          top: 2px;
          right: 0;
        }
      }

      .apply-all {
        position: relative;
        top: -3px;
        left: 8px;
        width: 30%;

        input[type="checkbox"] {
          position: absolute;
          left: 3px;
          top: 8px;
          height: auto;
        }

        label {
          position: relative;
          top: 3px;
          left: 0;
          width: 90%;
          text-transform: none;
          text-align: left;
          display: block;
          padding: 0 0 0 20px;
          font-weight: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .empty {
      width: 100%;
      padding: 40px;
      text-align: center;
      font-size: 22px;
      color: @color-grey;
    }
  }
}
