@import "a2v/container";
@import "a2v/error";
@import "a2v/form";
@import "a2v/button-group";
@import "a2v/drop-file-area";
@import "a2v/color-input-picker";
@import "a2v/artist-picker-overlay";
@import "a2v/preview";
@import "a2v/global-settings";
@import "a2v/video-settings";
@import "a2v/batch-upload";
@import "a2v/building-media";
@import "a2v/monetize-videos";
@import "a2v/call-to-action";
@import "a2v/warning";

.audio-to-video {
  .progress-tracker {
    > li {
      width: 25%;
    }
  }
}
