// https://gist.githubusercontent.com/jayj/4012969/raw/5d16162a3707f51a32904b92a185f025363a495e/flexbox.less
//
// --------------------------------------------------
// Flexbox LESS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
// flex or inline-flex
.flex-display(@display: flex) {
  display: ~"-webkit-@{display}";
  display: ~"-moz-@{display}";
  display: ~"-ms-@{display}box"; // IE10 uses -ms-flexbox
  display: ~"-ms-@{display}"; // IE11
  display: @display;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
.flex(@columns: initial) {
  flex: @columns;
  flex: @columns;
  flex: @columns;
  flex: @columns;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
.flex-direction(@direction: row) {
  flex-direction: @direction;
  flex-direction: @direction;
  flex-direction: @direction;
  flex-direction: @direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
.flex-wrap(@wrap: nowrap) {
  flex-wrap: @wrap;
  flex-wrap: @wrap;
  flex-wrap: @wrap;
  flex-wrap: @wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
.flex-flow(@flow) {
  flex-flow: @flow;
  flex-flow: @flow;
  flex-flow: @flow;
  flex-flow: @flow;
}

// Display Order
// - applies to: flex items
// <integer>
.flex-order(@order: 0) {
  order: @order;
  order: @order;
  order: @order;
  order: @order;
}

// Flex grow factor
// - applies to: flex items
// <number>
.flex-grow(@grow: 0) {
  flex-grow: @grow;
  flex-grow: @grow;
  flex-grow: @grow;
  flex-grow: @grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
.flex-shrink(@shrink: 1) {
  flex-shrink: @shrink;
  flex-shrink: @shrink;
  flex-shrink: @shrink;
  flex-shrink: @shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
.flex-basis(@width: auto) {
  flex-basis: @width;
  flex-basis: @width;
  flex-basis: @width;
  flex-basis: @width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
.justify-content(@justify: flex-start) {
  justify-content: @justify;
  justify-content: @justify;
  justify-content: @justify;
  justify-content: @justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
.align-content(@align: stretch) {
  align-content: @align;
  align-content: @align;
  align-content: @align;
  align-content: @align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
.align-items(@align: stretch) {
  align-items: @align;
  align-items: @align;
  align-items: @align;
  align-items: @align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
.align-self(@align: auto) {
  align-self: @align;
  align-self: @align;
  align-self: @align;
  align-self: @align;
}
