@tooltip-background-color: @color-medium-gray;
@tooltip-foreground-color: @color-white;

.tooltip {
  position: absolute;
  z-index: 9000;
  display: block;
  visibility: visible;
  font-size: 0.9em;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}
.tooltip-inner {
  max-width: 200px;
  padding: 1ex 0.5em;
  color: @tooltip-foreground-color;
  text-align: center;
  text-decoration: none;
  background-color: @tooltip-background-color;
  border-radius: 4px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: @tooltip-background-color;
}
.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-width: 5px 5px 0;
  border-top-color: @tooltip-background-color;
}
.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  right: 5px;
  border-width: 5px 5px 0;
  border-top-color: @tooltip-background-color;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: @tooltip-background-color;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: @tooltip-background-color;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: @tooltip-background-color;
}
.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  left: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: @tooltip-background-color;
}
.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  right: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: @tooltip-background-color;
}

// VYDIA OVERRIDES
body.tutorial & {
  .tooltip {
    font-size: 1.3em;
    z-index: 999999;
  }

  .tooltip-inner {
    max-width: initial;
    padding: 15px 30px;
    color: @tooltip-foreground-color;
    .border-radius(4px);
    border: 2px solid @tooltip-foreground-color;
    .box-shadow(2px 2px 2px fade(@color-black, 30%));
  }

  .tooltip.bottom {
    margin-top: 8px;
    .tooltip-arrow {
      top: -5px;
      margin-left: -26px;
      border-width: 0 26px 18px;
    }
  }

  .tooltip.top {
    margin-bottom: 8px;
    .tooltip-arrow {
      bottom: -5px;
      margin-left: -26px;
      border-width: 18px 26px 0;
    }
  }
}

.tooltip-inner {
  white-space: pre-wrap;
}


@tooltip-prefix-cls: rc-tooltip;

//
// Tooltips
// --------------------------------------------------
@font-size-base         : 12px;
@line-height-base       : 1.5;
@border-radius-base     : 6px;
@overlay-shadow              : 0 0 4px fade(@color-black, 17%);
// Tooltip text color
@tooltip-color: @color-white;
// Tooltip background color
@tooltip-bg: #373737;
@tooltip-opacity: 0.9;

// Tooltip arrow width
@tooltip-arrow-width: 5px;
// Tooltip distance with trigger
@tooltip-distance: @tooltip-arrow-width + 4;
// Tooltip arrow color
@tooltip-arrow-color: @tooltip-bg;

// Base class
.@{tooltip-prefix-cls} {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  // remove left/top by yiminghe
  // left: -9999px;
  // top: -9999px;
  font-size: @font-size-base;
  line-height: @line-height-base;
  opacity: @tooltip-opacity;

  &-hidden {
    display: none;
  }

  &-placement-top, &-placement-topLeft, &-placement-topRight {
    padding: @tooltip-arrow-width 0 @tooltip-distance 0;
  }
  &-placement-right, &-placement-rightTop, &-placement-rightBottom {
    padding: 0 @tooltip-arrow-width 0 @tooltip-distance;
  }
  &-placement-bottom, &-placement-bottomLeft, &-placement-bottomRight {
    padding: @tooltip-distance 0 @tooltip-arrow-width 0;
  }
  &-placement-left, &-placement-leftTop, &-placement-leftBottom {
    padding: 0 @tooltip-distance 0 @tooltip-arrow-width;
  }
}

// Wrapper for the tooltip content
.@{tooltip-prefix-cls}-inner {
  padding: 8px 10px;
  color: @tooltip-color;
  text-align: left;
  text-decoration: none;
  background-color: @tooltip-bg;
  border-radius: @border-radius-base;
  box-shadow: @overlay-shadow;
  min-height: 34px;
}

// Arrows
.@{tooltip-prefix-cls}-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.@{tooltip-prefix-cls} {
  &-placement-top &-arrow,
  &-placement-topLeft &-arrow,
  &-placement-topRight &-arrow {
    bottom: @tooltip-distance - @tooltip-arrow-width;
    margin-left: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
    border-top-color: @tooltip-arrow-color;
  }

  &-placement-top &-arrow {
    left: 50%;
  }

  &-placement-topLeft &-arrow {
    left: 15%;
  }

  &-placement-topRight &-arrow {
    right: 15%;
  }

  &-placement-right &-arrow,
  &-placement-rightTop &-arrow,
  &-placement-rightBottom &-arrow {
    left: @tooltip-distance - @tooltip-arrow-width;
    margin-top: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width @tooltip-arrow-width 0;
    border-right-color: @tooltip-arrow-color;
  }

  &-placement-right &-arrow {
    top: 50%;
  }

  &-placement-rightTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-rightBottom &-arrow {
    bottom: 15%;
  }

  &-placement-left &-arrow,
  &-placement-leftTop &-arrow,
  &-placement-leftBottom &-arrow {
    right: @tooltip-distance - @tooltip-arrow-width;
    margin-top: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width 0 @tooltip-arrow-width @tooltip-arrow-width;
    border-left-color: @tooltip-arrow-color;
  }

  &-placement-left &-arrow {
    top: 50%;
  }

  &-placement-leftTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-leftBottom &-arrow {
    bottom: 15%;
  }

  &-placement-bottom &-arrow,
  &-placement-bottomLeft &-arrow,
  &-placement-bottomRight &-arrow {
    top: @tooltip-distance - @tooltip-arrow-width;
    margin-left: -@tooltip-arrow-width;
    border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
    border-bottom-color: @tooltip-arrow-color;
  }

  &-placement-bottom &-arrow {
    left: 50%;
  }

  &-placement-bottomLeft &-arrow {
    left: 15%;
  }

  &-placement-bottomRight &-arrow {
    right: 15%;
  }
}
