.audio-to-video {
  .text-line {
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }

  .image-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }

    .fa-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -40px;
      margin-left: -40px;
      color: @color-white;
      font-size: 80px;
    }
  }

  .background-preview {
    position: relative;
    display: block;
    height: 360px;

    &.no-color {
      background-image: linear-gradient(bottom, #474747 0%, @color-black 100%);
      background-image: linear-gradient(bottom, #474747 0%, @color-black 100%);
      background-image: linear-gradient(bottom, #474747 0%, @color-black 100%);
      background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #474747), color-stop(1, @color-black));
      background-image: linear-gradient(bottom, #474747 0%, @color-black 100%);
      background-image: linear-gradient(to top, #474747 0%, @color-black 100%);
    }
  }

  .video-preview,
  .global-preview {
    position: absolute;
    width: 1280px;
    height: 720px;
    color: @color-white;

    &,
    & * {
      .undraggable();
      .unselectable();

      cursor: default;
    }

    .background-preview {
      float: none;
      width: 100%;
      height: 100%;
    }
  }

  .video-preview {
    &.with-artwork {
      .artwork {
        position: absolute;
        top: 40px;
        left: 340px;
        width: 600px;
        height: 640px;
      }
    }

    &.with-text {
      .text {
        position: relative;
        top: -450px;

        .text-line {
          height: 70px;
          font-size: 3em;
          text-align: center;

          &.song-name {
            height: 100px;
            font-size: 4em;
          }
        }
      }
    }

    &:not(.with-text) {
      .text {
        visibility: hidden;
      }
    }

    &.with-artwork.with-text {
      .artwork {
        left: 40px;
      }

      .text {
        position: absolute;
        top: 290px;
        left: 670px;
        right: 40px;

        .text-line {
          text-align: left;

          &.song-name {
            height: 70px;
            font-size: 3em;
          }
        }
      }
    }
  }

  .cover-text-enter {
    opacity: 0.01;
    transition: opacity 0.5s ease-in;
  }

  .cover-text-enter.cover-text-enter-active {
    opacity: 1;
  }

  .cover-text-leave {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  .cover-text-leave.cover-text-leave-active {
    opacity: 0.01;
  }
}
