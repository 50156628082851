.import-youtube,
.import-facebook,
.monetize {
  .artist-overlay {
    .container {
      .form-field {
        position: relative;
        padding: 10px 10px 0 175px;

        @media (max-width: @screen-xs-max) {
          padding: 10px 10px 0;
        }

        label {
          position: absolute;
          top: 10px;
          left: 10px;
          display: block;
          width: 155px;
          font-weight: bold;
          text-transform: uppercase;
          text-align: right;
          padding: 10px 10px 0 0;

          @media (max-width: @screen-xs-max) {
            position: relative;
            width: 100%;
            text-align: left;
            padding: 10px 0;
            top: 0;
            left: 0;
          }
        }

        select {
          padding: 10px;
          font-size: 16px;
          height: 40px;
          border: 1px solid @color-input-borders;
        }

        input[type="text"],
        select {
          display: block;
          width: 100%;
          margin-top: 0;
          padding-left: 12px;
        }
      }

      .container-content {
        padding-top: 10px;
      }

      .secondary-nav {
        margin-top: 20px;
        padding-right: 10px;

        @media (max-width: @screen-xs-max) {
          padding-right: 0;
        }

        p {
          line-height: 3em;
        }

        .btn-secondary {
          color: @color-primary;
          background-color: transparent;
          min-width: 100px;
          text-transform: none;
        }
      }

      .buttons-bar {
        position: relative;
        top: -25px;
        padding: 20px;
        background-color: @color-white;

        .btn {
          float: left;
        }
      }
    }
  }
}
