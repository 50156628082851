.btn-text {
  margin-left: 8px;
}

.btn-youtube {
  background: @color-youtube;
}

.btn-facebook {
  background: @color-facebook;
}

.inline-divider {
  margin-top: 20px;

  .divider,
  .divider-text {
    float: left;
  }

  .divider {
    margin: 0;
    width: 40%;
    border-top: 1px solid @color-borders;
  }

  .divider-text {
    font-size: 18px;
    line-height: 18px;
    margin-top: -0.5em;
    text-align: center;
    width: 20%;
  }
}
