.analytics {
  .legend-container {
    padding: 5px;
    background: @color-white;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .legend-item {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-bottom: 0;
    }

    .legend-color {
      background: @color-primary;
      display: inline-block;
      height: 2px;
      width: 22px;
      position: relative;

      .legend-circle {
        border: 2px solid @color-primary;
        background: @color-white;
        width: 8px;
        height: 8px;
        display: block;
        content: "";
        border-radius: 50%;
        top: -3px;
        position: absolute;
        left: 7px;
      }
    }

    .legend-title {
      margin-left: 10px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: normal;
      font-family: "Montserrat", sans-serif;
    }
  }

  .fa-spinner {
    font-size: 50px;
    height: 50px;
    width: 50px;
    margin: 0 auto;
    display: block;
    position: relative;
  }

  .empty-state {
    font-size: 1.4em;
    line-height: 1em;
    padding: 3.5% 2%;
    min-height: 5em;
    color: @color-grey;
    text-align: center;
    font-style: italic;
    clear: both;
  }

  .empty-state-container {
    border-radius: 3px;
    border-radius: 3px;
    border-radius: 3px;
    border: 1px solid @color-med-grey;
    background: @color-white;
    overflow: hidden;
    margin-bottom: 25px;

    &.loading-container {
      padding: 3.5% 2%;
    }
  }

  .empty-state-image {
    width: 185px;
    margin-left: auto;
    margin-right: auto;
  }

  .empty-title {
    text-align: center;
    color: @color-primary;
    clear: both;
    margin: 2% 0;
  }

  .empty-btn {
    margin-left: 40%;
    margin-top: 2%;
  }

  .empty-data {
    font-size: 1.4em;
    line-height: 1em;
    text-align: center;
    clear: both;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .chart-wrapper {
    background-color: @color-white;
    border: 1px solid @color-borders;
    border-top: none;
    padding: 15px;
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 15px;

    .chart-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      margin-top: 25px;

      .chart-y-label {
        font-size: 18px;
        height: 30px;
        width: 30px;
        text-align: center;
        transform: rotate(270deg);

        @media (max-width: @screen-xs-max) {
          font-size: 16px;
        }

        @media (max-width: @screen-ss-max) {
          font-size: 14px;
        }
      }

      .chart {
        width: 100%;
      }
    }

    .chart-x-label {
      font-size: 18px;
      text-align: center;

      @media (max-width: @screen-xs-max) {
        font-size: 16px;
      }

      @media (max-width: @screen-ss-max) {
        font-size: 14px;
      }
    }

    canvas {
      width: 100% !important;
      height: 500px !important;
      display: block;
      margin: 0 auto;
    }
  }
}
