@widget-margin: 15px;

.widget-container {
  padding: @widget-margin @widget-margin 0 @widget-margin;

  > header {
    margin: -@widget-margin -@widget-margin @widget-margin -@widget-margin;
  }
}

.widget-body {
  float: left;
  width: 100%;
  margin: 0 0 @widget-margin 0;

  .empty-state {
    float: left;
    width: 100%;
    font-size: 1.4em;
    line-height: 1em;
    padding: 2em 2%;
    color: @color-grey;
    text-align: center;
    font-style: italic;
  }

  .btn {
    padding: 0.3em 0;
    max-height: initial;
    width: 100%;
    font-size: 1.2em;
  }

  &.widget-success,
  &.widget-primary,
  &.widget-secondary,
  &.widget-tertiary,
  &.widget-value,
  &.widget-warning,
  &.widget-danger {
    .fa {
      border-width: 1px;
      border-style: solid;
      .box-shadow(0 1px 0 rgba(255, 255, 255, 0.5) inset);
      .transition-multiple(color, background-color, 0.2s ease);
    }
  }

  &.widget-success {
    .fa {
      background-color: @color-success;
      border-color: shade(@color-success, 5%);
    }
  }

  &.widget-primary {
    .fa {
      background-color: @color-primary;
      border-color: shade(@color-primary, 5%);
    }
  }

  &.widget-secondary {
    .fa {
      background-color: @color-secondary;
      border-color: shade(@color-secondary, 5%);
    }
  }

  &.widget-tertiary {
    .fa {
      background-color: @color-tertiary;
      border-color: shade(@color-tertiary, 5%);
    }
  }

  &.widget-value {
    .fa {
      background-color: @color-value;
      border-color: shade(@color-value, 5%);
    }
  }

  &.widget-warning {
    .fa {
      background-color: @color-warning;
      border-color: shade(@color-warning, 5%);
    }
  }

  &.widget-danger {
    .fa {
      background-color: @color-danger;
      border-color: shade(@color-danger, 5%);
    }
  }

  &:hover {
    &.widget-success {
      .fa {
        color: @color-success;
        background-color: fade(@color-success, 10%);
      }
    }

    &.widget-primary {
      .fa {
        color: @color-primary;
        background-color: fade(@color-primary, 10%);
      }
    }

    &.widget-secondary {
      .fa {
        color: @color-secondary;
        background-color: fade(@color-secondary, 10%);
      }
    }

    &.widget-tertiary {
      .fa {
        color: @color-tertiary;
        background-color: fade(@color-tertiary, 10%);
      }
    }

    &.widget-value {
      .fa {
        color: @color-value;
        background-color: fade(@color-value, 10%);
      }
    }

    &.widget-warning {
      .fa {
        color: @color-warning;
        background-color: fade(@color-warning, 10%);
      }
    }

    &.widget-danger {
      .fa {
        color: @color-danger;
        background-color: fade(@color-danger, 10%);
      }
    }
  }
}

.widget-tabs {
  .clearfix();

  > li {
    float: left;
    background-color: tint(@color-tertiary, 50%);

    a {
      display: block;
      position: relative;
      text-align: center;
      width: 100%;
      padding: 1em 1%;
      border: 1px solid transparent;
      border-right-color: @color-tertiary;
      .transition(all, 0.3s, ease 0s);
    }

    &:last-child {
      a {
        border-right-color: transparent;
      }
    }

    a,
    a:active,
    a:visited {
      color: @color-white;
    }

    &:not(.active) {
      a:hover {
        color: @color-tertiary;
        background-color: fade(@color-white, 90%);
        border-color: tint(@color-tertiary, 50%);
      }
    }

    &.active {
      background-color: @color-tertiary;

      a::before {
        z-index: 99;
        border-top: 9px solid @color-tertiary;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        content: "";
        left: 50%;
        margin-left: -10px;
        position: absolute;
        bottom: -9px;
      }
    }
  }

  &.widget-tabs-2 {
    > li {
      width: 50%;
    }
  }

  &.widget-tabs-3 {
    > li {
      width: 33.333333333%;
    }
  }

  &.widget-tabs-primary {
    > li {
      background-color: tint(@color-primary, 50%);

      a {
        border-right-color: @color-primary;
      }

      &:last-child {
        a {
          border-right-color: transparent;
        }
      }

      &:not(.active) {
        a:hover {
          color: @color-primary;
          border-color: tint(@color-primary, 50%);
        }
      }

      &.active {
        background-color: @color-primary;

        a::before {
          border-top: 9px solid @color-primary;
        }
      }
    }
  }

  &.widget-tabs-secondary {
    > li {
      background-color: tint(@color-secondary, 50%);

      a {
        border-right-color: @color-secondary;
      }

      &:last-child {
        a {
          border-right-color: transparent;
        }
      }

      &:not(.active) {
        a:hover {
          color: @color-secondary;
          border-color: tint(@color-secondary, 50%);
        }
      }

      &.active {
        background-color: @color-secondary;

        a::before {
          border-top: 9px solid @color-secondary;
        }
      }
    }
  }
}
