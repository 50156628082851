.account-buttons {

  padding-bottom: 10px;

  a {
    margin: 10px 5% 0 5%;
    width: 90%;
  }
}

.account-tos {
  max-height: 500px;
  overflow-y: scroll;
  background: @color-white;
  padding: 18px 29px;
  .box-shadow(inset 0 0 6px 0 fade(@color-black, 10%));
  border: 1px solid @color-borders;
  margin: 15px;
  font-size: 0.9em;
}

.payment-method-widget {
  position: relative;
  text-align: right;
  border: 1px solid @color-borders;
  background-color: @color-background;
  .box-shadow(1px 1px 1px fade(@color-black, 70%));
  .border-radius(6px);
  padding: 14px 4%;

  @media (max-width: @screen-xs-max) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @icon-height: 39px;

  > span {
    float: left;
    font-size: @icon-height;
    line-height: @icon-height;
  }

  > a {
    float: left;
    font-size: @icon-height / 2;
    line-height: @icon-height / 2;
    margin-top: @icon-height / 4;
    margin-right: 4%;
    color: inherit;
  }

  &.disabled {
    span, i, a, h3 {
      color: @color-grey !important;
    }
  }
}

.earnings {
  float: right;
  margin-left: 10px;

  .date-range-field {
    position: relative;
    float: right;

    .date-range-display {
      padding: 5px;
      border: 1px solid @color-warning;
      background-color: @color-white;
      font-size: 12px;
      cursor: pointer;

      .fa {
        left: 3px;
        position: relative;
        top: 2px;
        float: right;
      }

      .fa-chevron-down {
        left: 3px;
        font-size: 10px;
      }
    }

    .date-range-popup {
      display: none;
    }

    &.active {

      .date-range-popup {
        padding: 10px;
        position: absolute;
        display: block;
        width: 290px;
        height: 120px;
        margin-left: 0;
        z-index: 1000;
        background-color: @color-white;
        .box-shadow(0 0px 5px 0 fade(@color-black, 20%));

         label {
          display: block;
          float: left;
          width: 30%;
          padding: 5px;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
        }

        input {
          display: block;
          float: left;
          width: 70%;
          padding: 3px;
          text-align: center;
          font-size: 12px;
              height: 2.0em;
              select {
              }
        }

      }
    }

  }

  .form-input {
    margin-top: 10px;
    margin-left: 10px;
    height: 2.0em;
    width: 100px;
    select {
      width: 100px;
    }
  }

}

.back-to-earnings {
  float: right;

  .fa-chevron-left {
    font-size: 14px;
  }
}

.see-details-text {
  text-transform: none;
}

.download {
  float: right;
  margin-left: 10px;
}

#tipalti-iframe {
  display: block;
  margin: auto;
  width: 100%;
  height: 1000px;
}

.tipalti-container {
  margin-bottom: 25px;

  .secondary-tipalti-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    background-color: @color-white;
    border: 3px solid transparent;
    box-shadow: 0 4px 5px 0 fade(@color-black,14%), 0 1px 10px 0 fade(@color-black,12%), 0 2px 4px 0 fade(@color-black,20%);
    padding: 30px 30px;
  }
}

.payment-req-container {
  p {
    margin: 15px;
  }
  .overlay-content {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .overlay-inner {
      position: relative;
      max-height: 100%;
      overflow: auto;

      > .container,
      > .transition-group > .container {
        margin-bottom: 0;

        // Revert fix for mobile webkit for inner elements.
        cursor: auto;

        &.container-sm {
          max-width: 96%;
        }
      }
    }
  }

}

.youtube-service-container > * {
  vertical-align: middle;
}

.youtube-login-service {
  font-weight: bold;
}

.youtube-logo-login-service {
  color:  @color-youtube;
  font-size: 25px;
  margin-right: 5px;
}

.youtube-btn {
  width: 100%;
}

.youtube-success {
  font-weight: bold;
  color: @color-success;
}

.youtube-disabled {
  font-style: italic;
  font-weight: bold;
  color: @color-light-grey;
}

.sidebar-navigation.user {
  @media (max-width: @screen-sm-min) {
    display: none;
  }
  margin-bottom: 30px;

  ul {
    margin: 0;
    padding: 0;
    font-size: 15px;
    line-height: 1.25em;

    li {
      list-style: none;
      padding: 0;
      margin: 0 0 2px 0;

      a {
        display: block;
        padding: 15px;
        background: @color-light-grey;
        color: @color-dark-grey;
        position: relative;
        .transition(all, 250ms, ease);

        &:hover {
          background: @color-white;
        }

        &:before {
          display: block;
          content: "";
          height: 100%;
          left: 0;
          top: 0;
          position: absolute;
          background: @color-grey;
          width: 2px;
        }

        &:after {
          content: @fa-var-angle-right;
          font-family: "Font Awesome 6 Pro";
          font-size: 1em;
          text-align: right;
          height: 100%;
          right: 10px;
          top: 0;
          position: absolute;
          width: 10px;
          .flex-display(flex);
          .align-items(center);
        }

        &.active {
          background: @color-white;

          &:before {
            background: @color-primary;
          }

          &:after {
            color: @color-primary;
          }

        }

      }

    }

  }

}

.sidebar-navigation.mobile {
  @media (min-width: @screen-sm-min) {
    display: none;
  }
  margin-bottom: 30px;
  text-align: center;
  background: @color-white;
  text-transform: uppercase;
  margin-bottom: 30px;
  min-height: 50px;
  .clearfix();
  .box-shadow(0 0px 5px 0 fade(@color-black, 20%));

  .on-homepage({
    min-height: @header-height-large;
  });

  @media (min-width: @screen-md-min) {
    white-space: nowrap;
  }

  .dropdown {
    position: relative;
    width: 100%;

    &:hover:not(.active),
    &.hover:not(.active) {
      a {
        border-bottom-color: transparent;
      }

      ul {
        max-height:600px;
        z-index: 1002;
      }
    }

    ul {
      position: absolute;
      display: block;
      padding: 0;
      width: 100%;
      z-index: 1001;
      background: @color-white;
      .box-shadow(0 0px 5px 0 fade(@color-black, 20%));

      max-height: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
      .transition(all, .5s, linear);
    }

    li {
      float: none;
      width: 100%;
      display: block;
      text-align: center;

      > a {
        line-height: 2.5em;
        height: 2.5em;
        border: none;
        margin: 2px 0;
        padding: 0 10px;
        width: 100%;
        font-size: 0.8em;
        text-align: center;
        color: @color-medium-gray;

        &:not(.btn) {
          &.active, &.active:hover {
            color: @color-primary;
          }
          &:hover,
          &.hover {
            color: @color-secondary;
          }
        }
      }
    }
  }

  li {
    display: inline-block;

    > a {
      color: @color-primary;
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      font-family: 'source_sans_prosemibold';
      padding: 2px 15px;
      height: 50px;
      line-height: 50px;

      @media (max-width: @screen-md-max) {
        padding: 2px 20px
      }

      @media (max-width: 1060px) {
        padding: 2px 10px
      }

      @media (max-width: 845px) {
        padding: 2px 2px;
        font-size: 16px;
      }

      .on-homepage({
        height: @header-height-large;
        line-height: @header-height-large;
      });

      &:not(.btn) {
        &.active,
        &.active:hover {
          color: @color-secondary;
        }

        &:hover,
        &.hover {
          color:  @color-secondary;
        }
      }
    }
  }
}

.payment-verification {
  background-color: @color-white;
  border: 3px solid transparent;
  box-shadow: 0 4px 5px 0 fade(@color-black,14%), 0 1px 10px 0 fade(@color-black,12%), 0 2px 4px 0 fade(@color-black,20%);
  padding: 30px 30px;

  .payment-terms-check {
    margin: 15px 0 30px 0;
    display: flex;
    flex-wrap: wrap;
    > label {
      width: 90%;
      display: inline;
    }
    > input {
      width: 25px;
      margin-top: 3px;
    }
    .input-errors {
      width: 100%;
      margin-top: 15px;
      margin-left: 30px;
    }
  }

  .btn {
    float: right;
  }
}

.phone-input {
  padding-top: 2px;
  padding-bottom: 2px;
}

label[data-test-id="SecuritySettingsForm-otpMfa"] {
  span {
    span.Mui-checked {
      transform: translate(19px);
    }
    span {
      span {
        span {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.security {
  margin: 16px;
}
