.main-banner-section {
  @margin-v-md: 2%;
  @margin-h-md: 4%;

  @margin-v-lg: 4%;
  @margin-h-lg: 4%;

  display: block;
  position: relative;
  color: @color-white;
  margin-top: 25px;
  margin-bottom: 50px;
  background-size: cover;
  background-position: center;
  border: 1px solid @color-borders;
  padding-bottom: auto;

  .container-content {
    .secondary-header {
      margin: @margin-v-md @margin-h-md 0 @margin-h-md;

      h1 {
        .font-din();

        text-transform: uppercase;
        line-height: 1em;
        font-size: 2.5em;

        @media (min-width: @screen-sm) {
          font-size: 3em;
        }

        @media (min-width: @screen-md) {
          font-size: 3.5em;
        }
      }

      h2 {
        font-size: 1.2em;
      }

      > :last-child {
        margin-bottom: 0;
      }
    }

    .secondary-nav {
      margin: @margin-v-md @margin-h-md;
    }
  }

  .secondary-nav > ul > li {
    @media (max-width: @screen-xs-max) {
      &:first-child {
        .btn {
          .btn-lg();
        }
      }
    }

    @media (min-width: @screen-sm) {
      .btn {
        .btn-lg();
      }
    }

    @media (min-width: @screen-md) {
      .btn {
        // Consider enabling this for HUGE buttons.
        // .btn-xl();
      }
    }
  }

  // Banner directions.

  &.banner-left {
    .container-content .secondary-header {
      h1 {
        float: left;
        clear: left;
      }

      p {
        float: left;
        clear: left;
      }
    }

    @media (min-width: @screen-xs) {
      .container-content .secondary-nav {
        float: left;
      }
    }

    @media (min-width: @screen-sm) {
      .container-content .secondary-nav {
        li:last-child {
          margin-left: 0;
        }
      }
    }
  }

  &.banner-right {
    .container-content .secondary-header {
      h1 {
        float: right;
        clear: right;
      }

      p {
        float: right;
        clear: right;
      }
    }

    @media (min-width: @screen-xs) {
      .container-content .secondary-nav {
        float: right;
      }
    }

    @media (min-width: @screen-sm) {
      .container-content .secondary-nav {
        li:first-child {
          margin-right: 0;
        }
      }
    }
  }

  &.banner-lg {
    .container-content {
      .secondary-header {
        margin: @margin-v-lg @margin-h-lg 0 @margin-h-lg;

        h1 {
          line-height: 1em;
          font-size: 3em;

          @media (min-width: @screen-sm) {
            font-size: 3.5em;
          }

          @media (min-width: @screen-md) {
            font-size: 4em;
          }
        }

        h2 {
          font-size: 1.2em;
        }
      }

      .secondary-nav {
        margin: @margin-v-lg @margin-h-lg;
      }
    }
  }
}

.main-banner-section#a2v-banner {
  .set-asset-uri-for-path("/images/section_bg/right_guitar.jpg");

  background-image: url(@asset-uri);
  background-position: right;

  @media (min-width: @screen-sm) {
    background-position: center;
  }
}

.main-banner-section#content-id-banner {
  @media (min-width: @screen-sm) {
    background-position: right;
  }

  @media (max-width: @screen-sm) {
    color: @color-white;
  }
}

.main-banner-section#new-release-banner,
.main-banner-section#content-id-banner {
  background-position: top right;

  .dark-text {
    @media (max-width: @screen-sm) {
      color: @color-white;
      text-shadow: 1px 1px 0 fade(@color-black, 95%);
    }

    color: @color-black;
  }

  .light-text {
    @media (max-width: @screen-sm) {
      text-shadow: 1px 1px 0 fade(@color-black, 95%);
    }

    color: @color-white;
  }

  .banner-metadata {
    float: right;
    clear: left;
    margin-bottom: 20px;
    background-color: fade(@color-black, 60%);

    @media (min-width: @screen-sm) {
      white-space: nowrap;
    }

    .banner-metadata-inner {
      padding: 10px 15px;

      .artists-metadata,
      .membership-metadata {
        .font-din();

        font-weight: bold;

        .metadata-detail {
          font-weight: normal;
        }
      }
    }

    @media (max-width: @screen-xs-max) {
      margin-top: 0;
      display: block;
      width: 100%;
      text-align: center;

      .banner-metadata-inner {
        display: inline-block;
        text-align: left;
      }
    }
  }
}

.main-banner-section#audio-distro-banner {
  padding: 96px 0;

  .dark-text {
    color: @color-black;
  }

  .light-text {
    @media (max-width: @screen-sm) {
      text-shadow: 1px 1px 0 fade(@color-black, 95%);
    }

    color: @color-white;
  }

  .secondary-header {
    margin-top: 0;
  }

  .secondary-nav {
    margin-bottom: 0;
  }

  .banner-metadata {
    float: right;
    clear: left;
    margin-bottom: 20px;
    background-color: fade(@color-black, 60%);

    @media (min-width: @screen-sm) {
      white-space: nowrap;
    }

    .banner-metadata-inner {
      padding: 10px 15px;

      .artists-metadata,
      .membership-metadata {
        .font-din();

        font-weight: bold;

        .metadata-detail {
          font-weight: normal;
        }
      }
    }

    @media (max-width: @screen-xs-max) {
      margin-top: 0;
      display: block;
      width: 100%;
      text-align: center;

      .banner-metadata-inner {
        display: inline-block;
        text-align: left;
      }
    }
  }
}

.main-banner-section#royalty-center-banner {
  padding: 32px 0;
  background-repeat: no-repeat;
  background-position: center;

  .container-content {
    @media (min-width: @screen-md) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .rc-img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;

    @media (max-width: @screen-md) {
      display: none;
    }
  }

  .dark-text {
    color: @color-black;
    text-shadow: 2px 1px 4px @color-white;
  }

  .light-text {
    @media (max-width: @screen-sm) {
      text-shadow: 1px 1px 0 fade(@color-black, 95%);
    }

    color: @color-white;
  }

  .secondary-header {
    margin-top: 0;

    h1 {
      font-size: 42px;
      text-transform: none;
    }

    h2 {
      font-size: 22px;
    }
  }

  .secondary-nav {
    margin-bottom: 0;
  }

  .banner-metadata {
    float: right;
    clear: left;
    margin-bottom: 20px;
    background-color: fade(@color-black, 60%);

    @media (min-width: @screen-sm) {
      white-space: nowrap;
    }

    .banner-metadata-inner {
      padding: 10px 15px;

      .artists-metadata,
      .membership-metadata {
        .font-din();

        font-weight: bold;

        .metadata-detail {
          font-weight: normal;
        }
      }
    }

    @media (max-width: @screen-xs-max) {
      margin-top: 0;
      display: block;
      width: 100%;
      text-align: center;

      .banner-metadata-inner {
        display: inline-block;
        text-align: left;
      }
    }
  }
}

.main-banner-section#vydia-plan-banner {
  position: relative;
  background-color: @color-black;
  background-repeat: no-repeat;

  @media (max-width: @screen-lg) {
    background-size: cover;
  }

  .dark-text {
    @media (max-width: @screen-sm) {
      color: @color-white;
      text-shadow: 1px 1px 0 fade(@color-black, 95%);
    }

    color: @color-black;
  }

  .light-text {
    @media (max-width: @screen-sm) {
      text-shadow: 1px 1px 0 fade(@color-black, 95%);
    }

    color: @color-white;
  }

  .background {
    position: absolute;
    z-index: 0;
    width: 50%;
    right: 0;
    top: 0;
    height: 100%;

    .gradient-overlay {
      height: 100%;
      width: 200px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      background-image: linear-gradient(to right, @color-black, transparent);
    }

    .background-image {
      height: 100%;
      position: relative;
      z-index: 1;
      background-size: cover;
      background-position: 50% 50%;
    }
  }

  .secondary-nav {
    margin: 2% 4%;
  }

  .container-content {
    position: relative;
    z-index: 10;
  }

  .banner-metadata {
    float: right;
    clear: left;
    margin-bottom: 20px;
    background-color: fade(@color-black, 60%);

    @media (min-width: @screen-sm) {
      white-space: nowrap;
    }

    .banner-metadata-inner {
      padding: 10px 15px;

      .artists-metadata,
      .membership-metadata {
        .font-din();

        font-weight: bold;

        .metadata-detail {
          font-weight: normal;
        }
      }
    }

    @media (max-width: @screen-xs-max) {
      margin-top: 0;
      display: block;
      width: 100%;
      text-align: center;
      background-size: cover;

      .banner-metadata-inner {
        display: inline-block;
        text-align: left;
      }
    }
  }
}

.main-banner-section#new-release-banner {
  .secondary-header {
    margin: 5% 5% 5% 4%;
  }

  .banner-metadata {
    margin-top: 100px;
  }
}

.content-id-get-started {
  .va-content-id {
    top: 0.1em;
    padding-right: 2px;
  }
}
