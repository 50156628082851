.audio-to-video {
  select {
    .big-select();
  }

  .form-field {
    position: relative;
    padding: 10px 10px 0 120px;

    label {
      position: absolute;
      top: 10px;
      left: 10px;
      display: block;
      width: 100px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: right;
      padding: 10px 10px 0 0;
    }

    input[type="text"],
    select {
      display: block;
      width: 100%;
      margin-top: 0;
      padding-left: 12px;
    }

    input[type="checkbox"] {
      display: block;
      height: 37px;
    }
  }

  .buttons-bar {
    position: relative;
    top: -25px;
    padding: 20px;
    background-color: @color-white;

    .btn {
      float: left;
    }

    .error {
      line-height: 3em;
    }
  }
}
