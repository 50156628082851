#canvas-holder {
  width: 100%;
  margin-top: 50px;
  text-align: center;
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: fade(@color-black, 70%);
  color: @color-white;
  padding: 3px;
  border-radius: 3px;
  transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

#chartjs-tooltip.below {
  transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

#chartjs-tooltip.below::before {
  border: solid;
  border-color: @color-darkest-grey transparent;
  border-color: fade(@color-black, 80%) transparent;
  border-width: 0 8px 8px;
  bottom: 1em;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  z-index: 99;
  transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

#chartjs-tooltip.above {
  transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

#chartjs-tooltip.above::before {
  border: solid;
  border-color: @color-darkest-grey transparent;
  border-color: fade(@color-black, 80%) transparent;
  border-width: 8px 8px 0;
  bottom: 1em;
  content: "";
  display: block;
  left: 50%;
  top: 100%;
  position: absolute;
  z-index: 99;
  transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
