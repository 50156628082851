/**
 * RECOMMENDED STYLES
 */
.colorpicker {
  color: @color-white;
  background: @color-dark-grey;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.colorpicker .map {
  display: block;
  position: absolute;
  top: 25px;
  bottom: 56px;
  left: 56px;
  right: 181px;
  position: absolute;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  overflow: hidden;
}

.colorpicker .map.active {
  cursor: none;
}

.colorpicker .map.dark .pointer {
  border-color: @color-white;
}

.colorpicker .map.light .pointer {
  border-color: @color-black;
}

.colorpicker .map .pointer {
  position: absolute;
  width: 10px;
  height: 10px;
  margin-left: -5px;
  margin-top: -5px;
  border-radius: 100%;
  border: 1px solid @color-black;
}

.colorpicker .map .background {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

.colorpicker .map .background::before,
.colorpicker .map .background::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.colorpicker .map .background::after {
  background: linear-gradient(top, transparent 0%, @color-black 100%);
  background: linear-gradient(to bottom, transparent 0%, @color-black 100%);
}

.colorpicker .map .background::before {
  background: linear-gradient(left, white 0%, fade(@color-white, 0%) 100%);
  background: linear-gradient(to right, white 0%, fade(@color-white, 0%) 100%);
}

.colorpicker .slider {
  position: absolute;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}

.colorpicker .slider.vertical {
  top: 0;
  bottom: 0;
  left: 50%;
  width: 10px;
  cursor: ns-resize;
}

.colorpicker .slider.vertical .track {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 6px;
  margin-left: -3px;
}

.colorpicker .slider.horizontal {
  left: 0;
  right: 0;
  top: 50%;
  height: 10px;
  cursor: ew-resize;
}

.colorpicker .slider.horizontal .track {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 6px;
  margin-top: -3px;
}

.colorpicker .slider .track {
  border-radius: 3px;
  background: @color-grey;
}

.colorpicker .slider .pointer {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  background: @color-grey;
  margin-left: -7px;
  margin-bottom: -7px;
  border-radius: 14px;
}

.colorpicker .sat-slider {
  display: block;
  position: absolute;
  top: auto;
  bottom: 33px;
  left: 56px;
  right: 181px;
}

.colorpicker .light-slider {
  display: block;
  position: absolute;
  top: 25px;
  bottom: 56px;
  left: 23px;
  right: auto;
}

.colorpicker .hue-slider {
  display: block;
  position: absolute;
  top: 25px;
  bottom: 56px;
  left: auto;
  right: 158px;
}

.colorpicker .hue-slider .track {
  background: linear-gradient(top, #f00 0%, #f09 10%, #cd00ff 20%, #3200ff 30%, #06f 40%, #00fffd 50%, #0f6 60%, #35ff00 70%, #cdff00 80%, #f90 90%, #f00 100%);
  background: linear-gradient(to bottom, #f00 0%, #f09 10%, #cd00ff 20%, #3200ff 30%, #06f 40%, #00fffd 50%, #0f6 60%, #35ff00 70%, #cdff00 80%, #f90 90%, #f00 100%);
}

.colorpicker .sample {
  display: block;
  position: absolute;
  top: 25px;
  bottom: auto;
  left: auto;
  right: 25px;
  width: 100px;
  height: 100px;
}

.colorpicker .sample .current {
  height: 50%;
}

.colorpicker .sample .origin {
  height: 50%;
}

.colorpicker .details {
  display: block;
  position: absolute;
  top: 140px;
  bottom: 0;
  left: auto;
  right: 25px;
  width: 100px;
  overflow: hidden;
}

.colorpicker .details ul {
  padding: 0;
  margin: 0 0 20px;
}

.colorpicker .details ul li {
  list-style-type: none;
}

.colorpicker .details label {
  display: inline-block;
  color: @color-grey;
  width: 15px;
  margin-right: 5px;
  text-align: right;
}

.colorpicker .details input {
  width: 74px;
  text-transform: uppercase;
  background: none;
  border-radius: 3px;
  border: none;
  outline: none;
  color: @color-grey;
  background: fade(@color-black, 10%);
}

.colorpicker .details input:focus {
  color: @color-dark-grey;
  background: fade(@color-white, 90%);
}
