.monetize {
  .container {
    border: 1px solid @color-borders;
    .border-radius(0px);

    .social-text-sub-header {
      padding: 1% 2% 0;
    }

    > header {
      border-bottom: 1px solid @color-borders;
      background-color: @color-light-grey;
      text-transform: none;
      color: @color-black;

      .form-field {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
      }

      .heading-text {
        padding-top: 2%;

        .social-success-check {
          color: @color-success;
        }

        .fa {
          position: relative;
          top: 1px;
          font-size: 1.2em;
          margin-right: 8px;
        }
      }

      .fa {
        position: relative;
        top: 1px;
        font-size: 1.2em;
        color: @color-black;
        margin-right: 8px;
      }
    }
  }
}
