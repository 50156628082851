.cta-banner {
  @img-width: 1942.0;
  @img-height: 594.0;

  .px-position(@l, @t) {
    left: (@l / @img-width) * 100%;
    top: (@t / @img-height) * 100%;
  }

  .px-dimensions(@w, @h) {
    width: (@w / @img-width) * 100%;
    height: (@h / @img-height) * 100%;
  }

  position: relative;
  padding-bottom: (@img-height / @img-width) * 100%;
  margin-bottom: 15px;

  .cta-wrapper {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: top left;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
  }

  .cta-keyword {
    text-transform: uppercase;
    font-weight: bold;
  }

  .secondary-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 14;

    .cta-main-header,
    .cta-sub-header {
      display: block;
      position: absolute;
      font-size: 100%;
    }

    h1,
    h2 {
      display: block;
      width: 100%;
      white-space: nowrap;
      line-height: 1em;
    }

    h1 {
      color: @color-white;
      .font-narrow();

      text-transform: uppercase;

      .price {
        .font-source-sans();

        display: inline;
        position: relative;
        top: -0.05em;
        font-weight: bold;
        vertical-align: middle;
        font-size: 3em;

        // black drop shadow + white stroke. TODO: stroke mixin?
        text-shadow: 1px 1px 0 white, 1px -1px 0 white, -1px 1px 0 white, -1px -1px 0 white, 1px 1px 11px @color-black;
      }
    }
  }

  .secondary-nav {
    display: block;
    position: absolute;
    bottom: 0;
    width: 92%;
    margin: 0 4% 4%;
    z-index: 15;

    > ul > li {
      &:nth-child(2) > a {
        color: @color-white;
        text-transform: uppercase;
      }

      @media (max-width: @screen-xs-max) {
        &:first-child {
          .btn {
            .btn-lg();
          }
        }
      }

      @media (min-width: @screen-sm) {
        .btn {
          .btn-lg();
        }

        &:nth-child(2) > a {
          line-height: @btn-lg-height;
          font-size: @btn-lg-font-size;
        }
      }

      @media (min-width: @screen-lg) {
        .btn {
          .btn-xl();
        }

        &:nth-child(2) > a {
          line-height: @btn-xl-height;
          font-size: @btn-xl-font-size;
        }
      }
    }
  }

  .cta-screen {
    display: block;
    position: absolute;
    background-size: cover;
    background-position: center center;
  }

  &#monetize-call-to-action-banner {
    .cta-wrapper {
      .set-asset-uri-for-path("/images/setup/monetize_thumbnail_banner.png");

      background-image: url(@asset-uri);
    }

    .cta-keyword {
      color: @color-primary;
    }

    .secondary-header {
      .cta-main-header {
        .px-position(799, 101);
        .px-dimensions(916, 96);
      }

      .cta-sub-header {
        .px-position(799, 243);
        .px-dimensions(575, 31);
      }

      h1 {
        .price {
          color: @color-primary;
        }
      }
    }

    .imac-screen {
      .px-position(231, 67);
      .px-dimensions(539, 303);

      z-index: 1;
    }

    .macbook-screen {
      .px-position(53, 279);
      .px-dimensions(382, 245);

      z-index: 2;
    }

    .ipad-screen {
      .px-position(645, 320);
      .px-dimensions(259, 197);

      z-index: 3;
    }

    .iphone-screen {
      .px-position(560, 458);
      .px-dimensions(177, 104);

      z-index: 4;
    }
  }

  &#distribute-call-to-action-banner {
    .cta-wrapper {
      .set-asset-uri-for-path("/images/setup/distribute_thumbnail_banner.png");

      background-image: url(@asset-uri);
    }

    .cta-keyword {
      color: @color-secondary;
    }

    .secondary-header {
      .cta-main-header {
        .px-position(872, 123);
        .px-dimensions(870, 90);
      }

      .cta-sub-header {
        .px-position(874, 260);
        .px-dimensions(598, 31);
      }

      h1 {
        .price {
          color: @color-secondary;
        }
      }
    }

    .tv-screen {
      .px-position(91, 44);
      .px-dimensions(775, 452);

      z-index: 1;
    }
  }

  @media (max-width: @screen-xs-max) {
    @img-width: 929.0;
    @img-height: 948.0;

    padding-bottom: (@img-height / @img-width) * 100%;
    background-color: @color-dark-grey;

    .px-position(@l, @t) {
      left: (@l / @img-width) * 100%;
      top: (@t / @img-height) * 100%;
    }

    .px-dimensions(@w, @h) {
      width: (@w / @img-width) * 100%;
      height: (@h / @img-height) * 100%;
    }

    .secondary-header {
      h1 {
        color: @color-black;

        .price {
          font-size: 1.2em;
          top: -0.1em;
          text-shadow: none;
        }
      }
    }

    .secondary-nav {
      bottom: auto;
      .px-position(0, 676);

      @media (max-width: @screen-ss-max) {
        > ul > li {
          margin-top: 0;
          text-align: center;
        }
      }

      @media (max-width: @screen-xs-max) {
        > ul > li {
          text-align: center;
        }
      }
    }

    &#monetize-call-to-action-banner {
      .cta-wrapper {
        .set-asset-uri-for-path("/images/setup/monetize_thumbnail_banner_mobile.png");

        background-image: url(@asset-uri);
      }

      .secondary-header {
        .cta-main-header {
          .px-position(70, 23);
          .px-dimensions(787, 60);
        }

        .cta-sub-header {
          .px-position(197, 91);
          .px-dimensions(533, 37);
        }
      }

      .imac-screen {
        .px-position(239, 200);
        .px-dimensions(457, 276);
      }

      .macbook-screen {
        .px-position(104, 396);
        .px-dimensions(330, 217);
      }

      .ipad-screen {
        .px-position(585, 432);
        .px-dimensions(230, 172);
      }

      .iphone-screen {
        .px-position(514, 547);
        .px-dimensions(160, 95);
      }
    }

    &#distribute-call-to-action-banner {
      .cta-wrapper {
        .set-asset-uri-for-path("/images/setup/distribute_thumbnail_banner_mobile.png");

        background-image: url(@asset-uri);
      }

      .secondary-header {
        .cta-main-header {
          .px-position(52, 23);
          .px-dimensions(823, 60);
        }

        .cta-sub-header {
          .px-position(187, 94);
          .px-dimensions(553, 37);
        }
      }

      .tv-screen {
        .px-position(144, 197);
        .px-dimensions(638, 374);
      }
    }
  }
}

.fallback-banner-cta {
  position: relative;
  padding: 64px 32px;
  display: flex;
  align-items: center;

  .banner-container {
    position: relative;
    z-index: 10;
    width: 50%;

    h2 {
      color: @color-white;
      margin: 0 8px 0 0;
      padding: 0;
      font-size: 54px;
      line-height: 1.25em;
      font-weight: 700;
    }

    h4 {
      color: @color-white;
      margin: 0 8px 0 0;
      padding: 10px 0 27px;
      font-size: 18px;
      line-height: 1.5em;
      font-weight: 400;
    }
  }

  .banner-background {
    background: @color-black;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    .banner-background-container {
      width: 60%;
      height: 100%;
      background: @color-black;
      float: right;
      position: relative;

      .banner-gradient-overlay {
        min-width: 100px;
        width: 20%;
        display: block;
        height: 100%;
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
        background-image: linear-gradient(to right, black, transparent);
      }

      .banner-image {
        .set-asset-uri-for-path("/images/setup/banner-cta-success-sync-crunch.png");

        background-image: url(@asset-uri);
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .fallback-banner-cta {
    .banner-container {
      width: 100%;
    }

    .banner-background .banner-background-container .banner-gradient-overlay {
      width: 100%;
      min-width: 100%;
    }
  }
}

@media screen and (max-width: 425px) {
  .fallback-banner-cta {
    .banner-container {
      text-align: center;

      h2 {
        font-size: 34px;
      }
    }

    .banner-background .banner-background-container {
      width: 100%;
      top: auto;
      bottom: 0;
      float: none;

      .banner-gradient-overlay {
        height: 100%;
        background-blend-mode: multiply;
        background-image: linear-gradient(to bottom, black, transparent);
      }
    }
  }
}
