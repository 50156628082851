// - -------------------------------------------------------------------- - //
// - Overlay container.
// - -------------------------------------------------------------------- - //

.overlay-container {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .overlay-content {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .overlay-inner {
      position: relative;
      max-height: 100%;
      overflow: auto;

      > .container,
      > .transition-group > .container {
        margin-bottom: 0;

        // Revert fix for mobile webkit for inner elements.
        cursor: auto;

        &.container-sm {
          max-width: 96%;
        }
      }
    }
  }

  &.success-overlay,
  &.warning-overlay {
    text-align: center;

    .container {
      margin: auto;

      header {
        background-color: @color-white;
        border-bottom: none;
        margin-top: 25px;
        margin-bottom: 25px;

        h1 {
          float: none;
          font-family: source_sans_prolight;
          font-weight: normal;

          .heading-icon,
          .heading-text {
            display: block;
            text-transform: none;
            font-size: 40px;
          }

          .heading-icon {
            padding-bottom: 10px;
          }
        }
      }

      p {
        padding: 0 20px;
        margin-bottom: 25px;
      }
    }
  }

  &.success-overlay {
    .container {
      header {
        h1 {
          color: @color-success;
        }
      }
    }
  }

  &.warning-overlay {
    .container {
      header {
        h1 {
          color: @color-warning;
        }
      }
    }
  }
}

// - -------------------------------------------------------------------- - //
// - Overlay: Backdrop fade in.
// - -------------------------------------------------------------------- - //

.overlay-enter,
.overlay-from-right-enter,
.overlay-from-left-enter,
.overlay-series-from-right-enter,
.overlay-series-from-left-enter {
  .transition(none);

  opacity: 0.01;

  .overlay-content {
    .transition(none);
  }

  &.overlay-enter-active,
  &.overlay-from-right-enter-active,
  &.overlay-from-left-enter-active,
  &.overlay-series-from-right-enter-active,
  &.overlay-series-from-left-enter-active {
    .transition(all, @overlay-transition-duration-enter, @overlay-transition-easing-enter);

    opacity: 1;

    .overlay-content {
      .transition(all, @overlay-transition-duration-enter, @overlay-transition-easing-enter);
    }
  }
}

.overlay-leave,
.overlay-from-right-leave,
.overlay-from-left-leave,
.overlay-series-from-right-leave,
.overlay-series-from-left-leave {
  .transition(none);

  opacity: 1;

  .overlay-content {
    .transition(none);
  }

  &.overlay-leave-active,
  &.overlay-from-right-leave-active,
  &.overlay-from-left-leave-active,
  &.overlay-series-from-right-leave-active,
  &.overlay-series-from-left-leave-active {
    .transition(all, @overlay-transition-duration-leave, @overlay-transition-easing-leave);

    opacity: 0.01;

    .overlay-content {
      .transition(all, @overlay-transition-duration-leave, @overlay-transition-easing-leave);
    }
  }
}

// - -------------------------------------------------------------------- - //
// - Overlay: Popping in.
// - -------------------------------------------------------------------- - //

.overlay-enter {
  .overlay-content {
    .scale(0.5);
  }

  &.overlay-enter-active {
    .overlay-content {
      .scale(1);
    }
  }
}

.overlay-leave {
  .overlay-content {
    .scale(1);
  }

  &.overlay-leave-active {
    .overlay-content {
      .scale(0);
    }
  }
}

// - -------------------------------------------------------------------- - //
// - Overlay: Sliding in from the right.
// - -------------------------------------------------------------------- - //

.overlay-from-right-enter {
  .overlay-content {
    .translate(@overlay-slide-amount, 0);
  }

  &.overlay-from-right-enter-active {
    .overlay-content {
      .translate(0, 0);
    }
  }
}

.overlay-from-right-leave {
  .overlay-content {
    .translate(0, 0);
  }

  &.overlay-from-right-leave-active {
    .overlay-content {
      .translate(-@overlay-slide-amount, 0);
    }
  }
}

// - -------------------------------------------------------------------- - //
// - Overlay: Sliding in from the left.
// - -------------------------------------------------------------------- - //

.overlay-from-left-enter {
  .overlay-content {
    .translate(-@overlay-slide-amount, 0);
  }

  &.overlay-from-left-enter-active {
    .overlay-content {
      .translate(0, 0);
    }
  }
}

.overlay-from-left-leave {
  .overlay-content {
    .translate(0, 0);
  }

  &.overlay-from-left-leave-active {
    .overlay-content {
      .translate(@overlay-slide-amount, 0);
    }
  }
}

// - -------------------------------------------------------------------- - //
// - Overlay Series: Sliding in from the right.
// - -------------------------------------------------------------------- - //

.overlay-series-from-right-enter {
  .translate(@overlay-series-slide-amount, 0);

  &.overlay-series-from-right-enter-active {
    .translate(0, 0);
  }
}

.overlay-series-from-right-leave {
  .translate(0, 0%);

  &.overlay-series-from-right-leave-active {
    .translate(-@overlay-series-slide-amount, 0%);
  }
}

// - -------------------------------------------------------------------- - //
// - Overlay Series: Sliding in from the left.
// - -------------------------------------------------------------------- - //

.overlay-series-from-left-enter {
  .translate(-@overlay-series-slide-amount, 0);

  &.overlay-series-from-left-enter-active {
    .translate(0, 0);
  }
}

.overlay-series-from-left-leave {
  .translate(0, 0);

  &.overlay-series-from-left-leave-active {
    .translate(@overlay-series-slide-amount, 0);
  }
}

// - -------------------------------------------------------------------- - //
// - Overlay no vertical
// - -------------------------------------------------------------------- - //
.overlay-no-vertical {
  width: 100%;
  position: fixed;
  left: 0;
}
