.notices {
  border: 1px solid;
  padding: 0 4%;

  &:last-child {
    margin-bottom: 25px;
  }

  .all-links({
    text-decoration: underline;
  });

  &.primary-notices {
    border-color: @primary-border-color;
    background-color: @primary-background-color;
    color: @primary-text-color;

    .all-links({
      color: @color-secondary;
    });
  }

  &.secondary-notices {
    border-color: @secondary-border-color;
    background-color: @secondary-background-color;
    color: @secondary-text-color;

    .all-links({
      color: @secondary-text-color;
    });
  }

  &.success-notices {
    border-color: @success-border-color;
    background-color: @success-background-color;
    color: @success-text-color;

    .all-links({
      color: @success-text-color;
    });
  }

  &.warning-notices {
    border-color: @warning-border-color;
    background-color: @warning-background-color;
    color: @warning-text-color;

    .all-links({
      color: @warning-text-color;
    });

    .note {
      color: @warning-text-color;
    }
  }

  &.error-notices,
  &.danger-notices {
    border-color: fade(@color-danger, 5%);
    background-color: fade(@color-danger, 5%);
    color: @color-danger;
  }

  &.solid {
    &.primary-notices {
      border-color: @color-primary;
      background-color: @color-primary;
      color: @color-white;

      .all-links({
        color: @color-white;
      });
    }

    &.secondary-notices {
      border-color: @color-secondary;
      background-color: @color-secondary;
      color: @color-white;

      .all-links({
        color: @color-white;
      });
    }

    &.success-notices {
      border-color: @color-success;
      background-color: @color-success;
      color: @color-white;

      .all-links({
        color: @color-white;
      });
    }

    &.warning-notices {
      border-color: @color-warning;
      background-color: @color-warning;
      color: @color-white;

      .all-links({
        color: @color-white;
      });

      .note {
        color: @color-white;
      }
    }

    &.error-notices,
    &.danger-notices {
      border-color: @color-danger;
      background-color: @color-danger;
      color: @color-white;

      .all-links({
        color: @color-white;
      });
    }
  }

  li {
    .clearfix();

    line-height: 1.4em;
    padding: 1.2em 0;
    text-align: left;
    font-family: "source_sans_proregular";

    header,
    .notice-body,
    .notice-cta {
      display: block;
    }

    header {
      h1 {
        font-weight: bold;
        font-size: 1.3em;
        margin-bottom: 5px;
      }
    }

    .notice-body {
      font-size: 1em;
      margin-top: 0.4em;
    }
  }
}

.notices-sm {
  border: 1px solid;
  margin-bottom: 25px;
  padding: 0;

  li {
    .clearfix();

    line-height: 1.4em;
    padding: 1.2em 0;
    text-align: left;
    font-family: "source_sans_proregular";

    header,
    .notice-body,
    .notice-cta {
      display: block;
    }

    header {
      h1 {
        font-weight: bold;
        font-size: 1.3em;
        margin-bottom: 5px;
      }
    }

    .notice-body {
      font-size: 1em;
      margin-top: 0.4em;
    }

    .flex-item-space {
      flex-basis: 37px;
    }

    .notice-footer-text-sm {
      font-style: italic;
      font-size: 0.7em;
      white-space: nowrap;
      display: flex;
      justify-content: center;
    }

    .flex-container {
      display: flex;
      justify-content: center;

      .notice-icon-sm {
        margin: 20px 10px 0 0;
      }

      header {
        white-space: nowrap;
      }

      .notice-body-text-sm {
        font-size: 0.7em;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .notice-body-sm {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.notices {
  .notice-icon {
    @w: 40px;

    width: @w;
    height: @w;
    display: block;
    .border-radius(100%);

    float: left;
    margin: 0.5em;
    margin-right: 1em;
    .box-shadow(1px 1px 1px fade(#333, 70%));

    .fa {
      width: @w;
      height: @w;
      display: block;
      text-align: center;
      line-height: @w;
      font-size: @w / 2;
      color: @color-white;
    }
  }

  &.primary-notices .notice-icon {
    background: @color-primary;
  }

  &.secondary-notices .notice-icon {
    background: @color-secondary;
  }

  &.success-notices .notice-icon {
    background: @color-success;
  }

  &.warning-notices {
    .notice-icon {
      width: 35px;
      height: 34px;
      display: block;
      background: @warning-text-color;
      .border-radius(100%);

      float: left;
      margin: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      .box-shadow(1px 1px 1px fade(#333, 70%));

      .fa {
        width: 36px;
        height: 35px;
        display: block;
        text-align: center;
        line-height: 32px;
        font-size: 20px;
        color: @color-white;
      }
    }
  }

  &.error-notices .notice-icon,
  &.danger-notices .notice-icon {
    background: @color-danger;
  }
}

.notices.solid {
  &.primary-notices .notice-icon {
    background: tint(@color-primary, 25%);

    .fa {
      color: @color-white;
    }
  }

  &.secondary-notices .notice-icon {
    background: tint(@color-secondary, 25%);

    .fa {
      color: @color-white;
    }
  }

  &.success-notices .notice-icon {
    background: tint(@color-success, 25%);

    .fa {
      color: @color-white;
    }
  }

  &.warning-notices .notice-icon {
    background: tint(@color-warning, 25%);

    .fa {
      color: @color-white;
    }
  }

  &.error-notices .notice-icon,
  &.danger-notices .notice-icon {
    background: tint(@color-danger, 25%);

    .fa {
      color: @color-white;
    }
  }
}
