.analytics {
  .chart {
    .ct-tooltip {
      position: absolute;
      pointer-events: none;
      z-index: 9999;
      padding: 10px;
      border: 3px solid @color-primary;
      background-color: @color-white;
      .border-radius(3px);
    }

    &.loading {
      text-align: center;

      .ct-chart {
        opacity: 0;
        .transition(opacity, 200ms, linear);
      }

      .fa-spinner {
        position: absolute;
        font-size: 4em;
        color: @color-primary;
        margin-top: -200px;
      }
    }
  }
}
