@import "files/file-upload-container";
@import "files/artist-overlay";
@import "files/composer";
@import "files/responsive-composer";
@import "files/ugc_details";
@import "files/earnings-details";
@import "files/sync-intervention";

.form-field-text {
  padding: 10px 0;
}
