progress {
  display: inline-block;
  vertical-align: baseline;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: @color-med-grey;
  background-color: fade(@color-med-grey, 75%);
  border-radius: 4px;
  box-shadow: inset 0 1px 2px fade(@color-black, 10%);
  box-shadow: inset 0 1px 2px fade(@color-black, 10%);
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 1em;
  line-height: 20px;
  color: @color-white;
  text-align: center;
  background-color: @color-primary;
  box-shadow: inset 0 -1px 0 fade(@color-black, 15%);
  box-shadow: inset 0 -1px 0 fade(@color-black, 15%);
  transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-striped .progress-bar {
  background-image: linear-gradient(45deg, fade(@color-white, 15%) 25%, transparent 25%, transparent 50%, fade(@color-white, 15%) 50%, fade(@color-white, 15%) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, fade(@color-white, 15%) 25%, transparent 25%, transparent 50%, fade(@color-white, 15%) 50%, fade(@color-white, 15%) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}

.progress.active .progress-bar {
  animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-success .progress-bar {
  background-color: @color-success;
}

.progress-secondary .progress-bar {
  background-color: @color-secondary;
}

.progress-danger .progress-bar {
  background-color: @color-danger;
}
