.audio-to-video {
  .container {
    border: none;
    .border-radius(0px);

    .sortingInfo {
      font-size: 1em;
      text-align: center;
      padding: 10px 20px;
    }

    > header {
      padding: 8px 20px;
      background-color: @color-white;
      border-bottom-width: 2px;
      text-transform: none;
      color: @color-medium-dark-gray;

      .fa {
        position: relative;
        top: 1px;
        font-size: 1.2em;
        color: @color-grey;
        margin-right: 8px;
      }
    }
  }
}
