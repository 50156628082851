.analytics {
  .engagement-button-group {
    width: 100%;

    button {
      height: 88px;
      border: 1px solid @color-borders;
      background-color: @color-background;
      outline: none;
      width: 25%;
      min-width: auto;
      padding: 0;

      &.active {
        border-radius: 5px 0 0;
        background-color: @color-white;
        color: @color-black;
        border-bottom: 0;
        opacity: 1;
      }

      &.inactive {
        color: @color-grey;
      }
    }

    p {
      text-align: center;

      &.label {
        font-size: 17px;
        line-height: 22px;
        font-family: "source_sans_probold", sans-serif;

        @media (max-width: @screen-xs-max) {
          font-size: 14px;
        }

        @media (max-width: @screen-ss-max) {
          font-size: 12px;
        }
      }

      &.value {
        font-size: 28px;
        line-height: 36px;
        font-family: "source_sans_prolight", sans-serif;

        @media (max-width: @screen-xs-max) {
          font-size: 20px;
        }

        @media (max-width: @screen-ss-max) {
          font-size: 14px;
        }
      }
    }
  }
}
