// Set a global variable called @asset-uri which combines the cdn url and the argument path.
.set-asset-uri-for-path(@path) {
  @asset-uri: "@{settings-css-cdn-url}@{path}";
}

.placeholder(@rules) {
  &::input-placeholder {
    @rules();
  }

  &:placeholder {
    @rules();
  }

  &::placeholder {
    @rules();
  }

  &:input-placeholder {
    @rules();
  }
}

.big-select() {
  padding: 10px;
  font-size: 16px;
  border: 1px solid @color-input-borders;
}

// Partially copied from FontAwesome mixins .fa-icon()
.make-icon() {
  display: inline-block;
  font: normal normal normal @fa-font-size-base/1 FontAwesome; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); // ensures no half-pixel rendering in firefox

  // Ensure empty content still behaves.
  &::after {
    content: " ";
    display: inline-block;
  }
}

.make-background-image-icon() {
  .make-icon();

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.font-source-sans() {
  font-family: "source_sans_pro", sans-serif;
}

.font-montserrat() {
  // TODO: Do the fallback fonts make sense?
  // Montserrat is a pretty wide font.
  font-family: Montserrat, "source_sans_pro", sans-serif;
}

.font-din() {
  font-family: "din-2014", "Helvetica Neue", "Helvetica,Arial", sans-serif;
}

.font-narrow() {
  // TODO: Do the fallback fonts make sense?
  font-family: "source_sans_prolight", sans-serif;
  font-weight: lighter;
}

.absolutely-centered(@width: 50%, @height: 92%) {
  position: absolute;
  max-height: @height;
  width: @width;

  @offset-h: calc((100% - @width) / 2);
  @offset-v: calc((100% - @height) / 2);

  left: @offset-h;
  right: @offset-h;
  top: @offset-v;
}

.clearfix() {
  &::before,
  &::after { content: "\0020"; display: block; height: 0; overflow: hidden; }

  &::after { clear: both; }

  & { zoom: 1; }
}

.all-links(@rules) {
  a,
  a:hover,
  a:active,
  a:visited {
    @rules();
  }
}

.self-and-all-links(@rules) {
  &,
  a {
    &,
    &:hover,
    &:active,
    &:visited {
      @rules();
    }
  }
}

.all-headers(@rules) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @rules();
  }
}

.user-select(@value: none) {
  user-select: @value;
  user-select: @value;
  user-select: @value;
}

.border-radius(@radius: 5px) {
  border-radius: @radius;
  border-radius: @radius;
  border-radius: @radius;
}

.box-shadow(@shadow: 0 1px 3px rgba(0,0,0,.25)) {
  box-shadow: @shadow;
  box-shadow: @shadow;
  box-shadow: @shadow;
}

.background-size(@size) {
  background-size: @size;
  background-size: @size;
  background-size: @size;
  background-size: @size;
}

.box-sizing(@boxmodel) {
  box-sizing: @boxmodel;
  box-sizing: @boxmodel;
  box-sizing: @boxmodel;
}

.opacity(@value) {
  @ms-value: @value * 100;

  opacity: @value;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=@{ms-value})";
  filter: alpha(opacity=@ms-value);
}

.grayscale(@percentage: 100%) {
  filter: grayscale(@percentage);
  filter: grayscale(@percentage);
}

.transition-property(...) {
  transition-property: @arguments;
  transition-property: @arguments;
  transition-property: @arguments;
  transition-property: @arguments;
  transition-property: @arguments;
}

.transition-raw (@property) {
  transition: @property;
  transition: @property;
  transition: @property;
  transition: @property;
  transition: @property;
}

// http://designshack.net/articles/css/10-less-css-examples-you-should-steal-for-your-projects/
.transition (@prop: all, @time: 1s, @ease: linear) {
  transition: @prop @time @ease;
  transition: @prop @time @ease;
  transition: @prop @time @ease;
  transition: @prop @time @ease;
  transition: @prop @time @ease;
}
// To transition multiple properties,
// use the mixin below or use this syntax:
// .transition(e("border-color, border-radius,"), .2s, linear);
// .transition-multiple(border-color, border-radius, .2s linear);
//          NOTE THE ADDITIONAL COMMA             _^_

// http://stackoverflow.com/a/13490523
.transition-multiple (@value1, @value2: X, ...) {
  @value: ~`"@{arguments}".replace(/[\[\]]|\,\sX/g, '')`;

  transition: @value;
  transition: @value;
  transition: @value;
  transition: @value;
  transition: @value;
}

.backface-visibility (@visibility) {
  backface-visibility: @visibility;
  backface-visibility: @visibility;
  backface-visibility: @visibility;
  backface-visibility: @visibility;
}

.unselectable() {
  /* For Opera and <= IE9, we need to add unselectable="on" attribute onto each element */

  /* Check this site for more details: http://help.dottoro.com/lhwdpnva.php */
  user-select: none; /* These user-select properties are inheritable, used to prevent text selection */
  user-select: none;
  user-select: none; /* From IE10 only */
  user-select: none; /* Not valid CSS yet, as of July 2012 */
}

.undraggable() {
  .unselectable();

  -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
  user-drag: none;
}

.appearance(@name) {
  appearance: @name;
  appearance: @name;
  appearance: @name;
}

// responsive classes

.hidden-ss() {
  @media (max-width: @screen-ss-max) {
    display: none !important;
  }
}

.hidden-xs() {
  @media (max-width: @screen-xs-max) {
    display: none !important;
  }
}

.hidden-sm() {
  @media (max-width: @screen-sm-max) {
    display: none !important;
  }
}

.hidden-md() {
  @media (max-width: @screen-md-max) {
    display: none !important;
  }
}

.hidden-lg() {
  @media (max-width: @screen-lg-max) {
    display: none !important;
  }
}

.hidden-xl() {
  @media (max-width: @screen-xl-max) {
    display: none !important;
  }
}

.visible-ss() {
  @media (min-width: @screen-xs) {
    display: none !important;
  }
}

.visible-xs() {
  @media (min-width: @screen-sm) {
    display: none !important;
  }
}

.visible-sm() {
  @media (min-width: @screen-md) {
    display: none !important;
  }
}

.visible-md() {
  @media (min-width: @screen-lg) {
    display: none !important;
  }
}

.visible-lg() {
  @media (min-width: @screen-xl) {
    display: none !important;
  }
}

.visible-xl() {
  @media (min-width: @screen-ll) {
    display: none !important;
  }
}

.hidden-ss {
  .hidden-ss();
}

.hidden-xs {
  .hidden-xs();
}

.hidden-sm {
  .hidden-sm();
}

.hidden-md {
  .hidden-md();
}

.hidden-lg {
  .hidden-lg();
}

.hidden-xl {
  .hidden-xl();
}

.visible-ss {
  .visible-ss();
}

.visible-xs {
  .visible-xs();
}

.visible-sm {
  .visible-sm();
}

.visible-md {
  .visible-md();
}

.visible-lg {
  .visible-lg();
}

.visible-xl {
  .visible-xl();
}

// GFM
.gfm-content() {
  p,
  ul,
  ol,
  pre,
  hr,
  br,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  pre,
  p code,
  ul code,
  ol code {
    white-space: pre;
    background: @color-background;
    .border-radius(4px);

    padding: 6px 12px;
    border: 1px solid @color-borders;
  }

  p,
  ul,
  ol {
    font-size: 1em;

    code {
      padding: 2px 6px;
      margin: 0 3px;
    }
  }

  ul,
  ol {
    list-style-position: outside;
    padding-left: 2em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  blockquote {
    border-left: 4px solid @color-grey;
    padding: 0 15px;
    color: @color-medium-dark-gray;
  }
}

.btn-height(@height) {
  height: @height;
  min-height: @height;
  line-height: @height;
}

.btn-sm() {
  .btn-height(@btn-sm-height);

  padding: 0 15px;
  min-width: 100px;
  font-size: @btn-sm-font-size;
}

.btn-lg() {
  .btn-height(@btn-lg-height);

  padding: 0 30px;
  min-width: 125px;
  font-size: @btn-lg-font-size;
}

.btn-xl() {
  .btn-height(@btn-xl-height);

  padding: 0 40px;
  min-width: 150px;
  font-size: @btn-xl-font-size;
}

.btn-small() {
  .btn-sm();
}

.bulleted-list() {
  list-style-position: outside;
  padding-left: 2em;
  list-style-type: disc;
}

.numbered-list() {
  list-style-position: outside;
  padding-left: 2em;
  list-style-type: decimal;
}

// Points equal pixels for 72dpi
.font-size(@value) {
  @remValue: @value / 16;

  font-size: ~"@{value}px";
  font-size: ~"@{remValue}rem";
}

// ============ BOOTSTRAP MIXINS ============
// Transformations
.transform(@transformation) {
  transform: @transformation;
  transform: @transformation; // IE9 only
  transform: @transformation;
  transform: @transformation;
}
.scale(@ratio) {
  transform: scale(@ratio);
  transform: scale(@ratio); // IE9 only
  transform: scale(@ratio);
  transform: scale(@ratio);
}
.scale(@ratioX; @ratioY) {
  transform: scale(@ratioX, @ratioY);
  transform: scale(@ratioX, @ratioY); // IE9 only
  transform: scale(@ratioX, @ratioY);
  transform: scale(@ratioX, @ratioY);
}
.scaleX(@ratio) {
  transform: scaleX(@ratio);
  transform: scaleX(@ratio); // IE9 only
  transform: scaleX(@ratio);
  transform: scaleX(@ratio);
}
.scaleY(@ratio) {
  transform: scaleY(@ratio);
  transform: scaleY(@ratio); // IE9 only
  transform: scaleY(@ratio);
  transform: scaleY(@ratio);
}
.skew(@x; @y) {
  transform: skewX(@x) skewY(@y);
  transform: skewX(@x) skewY(@y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
  transform: skewX(@x) skewY(@y);
  transform: skewX(@x) skewY(@y);
}
.translate(@x; @y) {
  transform: translate(@x, @y);
  transform: translate(@x, @y); // IE9 only
  transform: translate(@x, @y);
  transform: translate(@x, @y);
}
.translate3d(@x; @y; @z) {
  transform: translate3d(@x, @y, @z);
  transform: translate3d(@x, @y, @z);
}
.rotate(@degrees) {
  transform: rotate(@degrees);
  transform: rotate(@degrees); // IE9 only
  transform: rotate(@degrees);
  transform: rotate(@degrees);
}
.rotateX(@degrees) {
  transform: rotateX(@degrees);
  transform: rotateX(@degrees); // IE9 only
  transform: rotateX(@degrees);
  transform: rotateX(@degrees);
}
.rotateY(@degrees) {
  transform: rotateY(@degrees);
  transform: rotateY(@degrees); // IE9 only
  transform: rotateY(@degrees);
  transform: rotateY(@degrees);
}
.perspective(@perspective) {
  perspective: @perspective;
  perspective: @perspective;
  perspective: @perspective;
}
.perspective-origin(@perspective) {
  perspective-origin: @perspective;
  perspective-origin: @perspective;
  perspective-origin: @perspective;
}
.transform-origin(@origin) {
  transform-origin: @origin;
  transform-origin: @origin;
  transform-origin: @origin; // IE9 only
  transform-origin: @origin;
}
.transform-style(@style) {
  transform-style: @style;
  transform-style: @style;
  transform-style: @style; // IE9 only
  transform-style: @style;
}

.social-avatar() {
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;

  .social-avatar-image {
    display: inline-block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    .border-radius(100%);
  }

  .social-avatar-title {
    display: block;
    color: @color-grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.social-avatar {
  .social-avatar();
}

.profile-picker-item() {
  padding: 10px;
  width: 100%;
  cursor: pointer;

  .name {
    position: relative;
    top: (@artist-image-height-width/2) - (@font-size-px/2);
    font-weight: bold;
    width: auto;
    left: 1.5%;
    display: block;
  }

  img {
    width: 70px;
    height: 70px;
    padding: 3px;
    border: 1px #919191 solid;
    border-radius: 5px;
    float: left;
  }

  &:nth-of-type(odd) {
    background: @color-light-grey;
  }
}

.profile-picker() {
  @font-size-px: 21px;
  @artist-image-height-width: 70px;

  border-bottom: 1px solid @color-grey;
  font-family: "source_sans_pro", sans-serif;

  p {
    font-size: @font-size-px;
  }

  h2 {
    display: inline;
    font-size: 1.65em;
    font-weight: normal;
  }

  .align-columns {
    display: inline-block;
    position: relative;
  }
}
