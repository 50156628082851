.responsive-composer-box {
  padding: 0 !important;
  margin: 0;
  right: 0;
  width: 98%;
}

.composing-rights {
  .secondary-header {
    div {
      font-weight: normal;
      display: inline;
    }
  }

  .composers-table-responsive {
    border: 1px solid @color-borders;
    .border-radius(0 0 4px 4px);

    > .btn {
      float: none;
      background-color: transparent;
      padding: 0 14px;
      color: @color-dark-grey;
      text-transform: none;
      text-align: left;
      white-space: nowrap;

      .fa {
        font-size: 26px;
        color: @color-primary;
        margin-right: 14px;
      }
    }

    select {
      height: 38px;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      border: 1px solid @color-input-borders;
    }
  }

  .composers-table-responsive {
    background-color: @color-light-grey;

    .table-responsive {
      .composer-row {
        background-color: @color-white;
        overflow: hidden;

        .composer-header {
          background-color: @color-table-header-background;
          border-top: 1px @color-light-grey solid;

          div {
            padding: 10px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: left;
            font-size: 15px;
            font-family: source_sans_prosemibold;
            color: @color-white;

            .fa {
              float: right;
            }
          }
        }

        .composer-data {
          padding: 10px 20px;

          label {
            display: block;
            width: 100%;
            font-weight: bold;
            text-transform: uppercase;
            text-align: left;
            padding: 10px 0 0;
            font-size: 15px;
            font-family: source_sans_prosemibold;
            position: static;
          }

          select {
            background-color: @color-light-grey;
          }

          .remove-composer {
            text-align: right;
            display: block;
          }
        }
      }

      .composer-collapse-enter {
        .transition(none);

        max-height: 0;
      }

      .composer-collapse-enter-active {
        .transition(all, @composer-collapse-transition-duration-enter, linear);

        max-height: 315px;
      }

      .composer-collapse-leave {
        .transition(none);

        max-height: 315px;
      }

      .composer-collapse-leave-active {
        .transition(all, @composer-collapse-transition-duration-leave, linear);

        max-height: 0;
      }
    }
  }

  .form-bullet {
    position: absolute;
    margin-left: 10px;
  }

  .check-label {
    display: block;
    padding-top: 10px;
    padding-left: 5px;

    input {
      position: relative;
      top: -3px;
      margin-right: 5px;
    }
  }

  .form-field-composers-ownership {
    .row {
      margin: 10px 0;
    }

    .radio-label {
      padding-left: 40px;

      input {
        position: relative;
        top: -3px;
        margin-right: 5px;
      }
    }
  }

  .fa-usd {
    color: @color-success;
  }

  .rights-info {
    input[type="checkbox"],
    input[type="radio"] {
      vertical-align: initial;
    }

    .rights-control {
      border-top: 1px solid @color-borders;
      display: none;
    }

    .form-field {
      margin-top: 0;
      padding: 1%;
    }

    .responsive-form-label {
      position: static;
      width: 100%;
      text-align: left;
    }

    .form-field:last-of-type {
      margin-bottom: 0;
    }

    .bold {
      font-weight: bold;
    }

    .control-all-rights {
      padding: 2%;

      p {
        padding-bottom: 5px;
        left: -10px;
        position: relative;
      }

      .form-field {
        padding: 0.5%;
      }
    }

    .rights-table {
      display: none;
      background-color: @color-background;

      .form-field-rights {
        display: block;
      }
    }

    .form-field-rights {
      display: none;
      background-color: @color-background;
      border-top: 1px solid @color-borders;
    }

    .secondary-nav {
      padding-top: 20px;
    }

    &.web {
      .rights-control {
        display: block;
      }

      .form-field-rights {
        display: block;
        background-color: transparent;
        border-top: none;
      }
    }
  }

  #composers-table-responsive.with-errors {
    border: 1px solid @error-color;
    border-radius: 3px;
    padding: 2px;
  }
}
