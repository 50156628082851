@import (once) "../font-awesome/fontawesome";

@import "../vendor/tooltip";
@import "./base/notices";
@import "./base/tables";

html,
body {
  height: 100%;
  background: @color-light-grey;
  .font-source-sans();
}

// TODO: add themed :focus and ::selection colors.
// :focus {
//   outline-color: tint(@color-secondary, 15%);
// }
// ::selection {
//   color: @color-white;
//   background: tint(@color-secondary, 15%);
// }

*,
*:before,
*:after {
  .box-sizing(border-box);
}

.clear {
  display: block;
  clear: both;
}

.unsupported-browser,
.sr-only,
.noflash {
  display: none;
}


/* Colors ********************************************************/
.danger,
.error {
  .self-and-all-links({
    color: @color-error
  });
}

.success {
  .self-and-all-links({
    color: @color-success
  });
}

.danger {
  .self-and-all-links({
    color: @color-danger
  });
}

.warning {
  .self-and-all-links({
    color: @color-warning
  });
}


/* Buttons ********************************************************/
.btn,
button,
input[type=submit],
input[type=reset],
input[type=button] {
  .appearance(none);
  display: inline-block;
  text-align: center;
  outline-style: none;
  border: none;
  .border-radius(3px);
  text-transform: uppercase;
  background-color: @color-dark-grey;
  color: @color-white;
  padding: 0 @btn-padding-h;
  font-size: 0.9em;
  font-weight: normal;
  min-width: 200px;
  .opacity(1.0);
  .transition(opacity, .2s, linear);

  .btn-height(3em);

  &.btn-sm {
    .btn-sm();
  }

  &.btn-lg {
    .btn-lg();
  }

  &.btn-xl {
    .btn-xl();
  }

  &:focus,
  &.focus {
    outline-style: auto;
    outline-offset: -3px;
  }

  &:hover,
  &.hover {
    outline-style: none;
  }

  &:hover,
  &.hover,
  &:visited,
  &.visited,
  &:active,
  &.active,

  // parent has hover class
  .hover & {
    .opacity(0.7);
    color: @color-white;
  }

  &.btn-primary {
    background-color: @color-primary;
  }

  &.btn-secondary {
    background-color: @color-secondary;
  }

  &.btn-tertiary {
    background-color: @color-tertiary;
  }

  &.btn-danger {
    background-color: @color-danger;
  }

  &.btn-success {
    background-color: @color-success;
  }

  &.btn-value {
    background-color: @color-value;
  }

  &.btn-facebook {
    background-color: @color-facebook;
  }

  &.btn-twitter {
    background-color: @color-twitter;
  }

  &.btn-email {
    background-color: @color-email;
  }

  &.btn-network {
    text-transform: none;
    padding: 12px 10px;
    margin: 0;
    min-width: 120px;
    width: 100%;
    max-width: 160px;
    font-weight: 500;
    line-height: 1em;
    font-size: 14px;
    min-height: auto;

    &.btn-no-link {
      background-color: transparent !important;
      color: @color-black;
    }
  }

  &.btn-link {
    min-width: 100px;
    color: @color-primary;
    background-color: transparent;

    &.disabled {
      color: @color-light-grey;
      background-color: transparent;
      cursor: initial;
    }
  }

  &.btn-file {
    display: block;
    position: relative;
    overflow: hidden;
    max-height: initial;
    cursor: pointer;
    height: 160px;
    line-height: 160px;
    background: @color-light-grey;
    font-size: 1.1em;

    &,
    &:visited {
      color: @color-primary;
      border: 3px dashed @color-primary;
    }

    &:hover,
    &:active,
    &.hover,
    &.active {
      color: @color-secondary;
      border: 3px dashed @color-secondary;
    }

    input[type=file] {
      position: absolute;
      display: block;
      filter: alpha(opacity=0);
      opacity: 0;
      font-size: 200px;
      margin: 0;
      padding: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      max-width: 100%;
      max-height: 100%;
      text-align: right;
      outline: none;
      background: @color-white;
      cursor: inherit;
    }
  }

  &.disabled {
    background-color: @color-grey;
    cursor: initial;

    &:hover {
      .opacity(1);
    }
  }

  &.btn-block {
    display: block;
    width: 100%;
  }
  &.btn-margin {
    margin: 0 16px;
  }
}

#main-content {
  >p {
    margin-bottom: 25px;
  }
}

p {
  &.note {
    font-style: italic;
    font-size: 0.9em;
    color: @color-grey;
  }
}

.highlight {
  background-color: @color-white;
  border: 1px solid @color-borders;
  padding: 20px 4% 0 4%;
  margin-bottom: 25px;
  .border-radius(3px);
  box-shadow: 1px 1px 1px fade(black, 50%);

  &.highlight-warning {
    border-color: @warning-border-color;
    background-color: @warning-background-color;
    padding-bottom: 20px;
  }

  >p {
    display: inline;
    margin-bottom: 20px;
  }

  .highlight-icon {
    display: inline;
    margin-right: 0.5em;
    margin-bottom: 0.1em;
  }
}

p.highlight {
  padding-bottom: 20px;
}

/* Font-Awesome Icons *****************************************************/
.fa {

  &.btn,
  &button,
  &input[type=submit],
  &input[type=reset],
  &input[type=button],
  &.action-btn,
  &.action-link,
  .btn &,
  button &,
  input[type=submit] &,
  input[type=reset] &,
  input[type=button] &,
  .action-btn &,
  .action-link & {
    position: relative;
    top: 0.1ex;
    font-size: 1.2em;
  }

  &.fa-network-link {
    vertical-align: middle;
    top: 0;
    right: 0;
    margin-right: 5px;
    line-height: 1em;
    font-size: 1em;
  }
}

.action-btn,
.action-link {
  &.disabled {
    .opacity(0.3);
    cursor: not-allowed;
  }
}

.fa-stack {
  &.file-stack {
    .fa:last-child {
      top: 0.4ex;
    }
  }
}


/* Navigation *****************************************************/
a {

  &,
  &:visited {
    color: @color-primary;
  }

  &:hover {
    color: @color-secondary;
    cursor: pointer;
  }

  &:active {
    /* color: @error-color; */
  }

  &.non-link {

    &,
    &:hover,
    &:active,
    &:visited {
      color: inherit;
    }
  }
}

.secondary-nav {
  .clearfix();
  overflow: visible;
  display: block;
  .clearfix();

  >li,
  >ul>li {
    float: right;
    margin-left: 25px;
    overflow: visible;

    @media (min-width: @screen-sm) {
      &.secondary-nav-remainder {
        float: none;
      }

      &.secondary-nav-search {
        float: right;
        width: 200px;

        .form-field {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    img {
      max-height: 43px;
    }

    .form-field {
      width: auto;
    }
  }

  a {
    line-height: 3em;
    font-size: 0.9em;
  }

  .btn-icon {
    color: @color-black;
    font-size: 44px;
    line-height: 44px;

    &:hover {
      color: @color-dark-grey;
    }

    &:active {
      color: @color-medium-dark-gray;
    }
  }

  @media (min-width: @screen-sm) {
    &.even-width-buttons {

      >li,
      >ul>li {
        float: right;
        margin: 0;
        padding: 0;

        .btn {
          @margin: 2%;
          width: 100% - (@margin * 2);
          min-width: 100% - (@margin * 2);
          margin-left: @margin;
        }
      }
    }

    &.two-buttons {

      >li,
      >ul>li {
        width: 100.0% / 2;
      }
    }

    &.three-buttons {

      >li,
      >ul>li {
        width: 100.0% / 3;
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    float: none;
    clear: both;
    display: block;
    width: auto;

    >li,
    >ul>li {
      .clearfix();
      width: auto;
      float: none;
      display: block;
      clear: both;
      margin-left: 0;
      margin-top: 15px;
      text-align: right;

      &:first-child {
        margin-top: 0;
      }

      .btn {
        width: auto;
      }
    }
  }

  @media (max-width: @screen-xs-max) {

    &,
    &>li,
    &>ul>li {

      a,
      .btn,
      button,
      input[type=submit],
      input[type=reset],
      input[type=button] {
        margin-left: 0;
        margin-right: 0;
      }

      .btn,
      button,
      input[type=submit],
      input[type=reset],
      input[type=button] {
        float: none;
        display: block;
        width: 100%;

        &.btn-primary {
          .btn-height(4em);
        }
      }
    }
  }
}

/* Containers ****************************************************/
.container {
  .full-container() {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .centered-width(@width) {
    width: @width;
    @margin: (100% - @width) / 2;
    margin-left: @margin;
    margin-right: @margin;
  }

  .border-radius(3px);
  border: 1px solid @color-borders;
  background: @color-white;
  overflow: hidden;
  margin-bottom: @container-margin-bottom;

  &.no-border {
    border: none;
  }

  &.overflow-visible {
    overflow: visible;
  }

  &.container-xs {
    .centered-width(36%);

    @media (max-width: @screen-lg-max) {
      .centered-width(42%);
    }

    @media (max-width: @screen-md-max) {
      .centered-width(48%);
    }

    @media (max-width: @screen-sm-max) {
      .centered-width(56%);
    }

    @media (max-width: @screen-xs-max) {
      .centered-width(86%);
    }

    @media (max-width: @screen-ss-max) {
      .centered-width(94%);
    }
  }

  &.container-sm {
    .centered-width(76%);

    @media (max-width: @screen-xs-max) {
      .centered-width(94%);
    }
  }

  &.container-md {
    .centered-width(86%);

    @media (max-width: @screen-md-max) {
      .centered-width(94%);
    }
  }

  &.container-lg {
    .centered-width(92%);

    @media (max-width: @screen-lg-max) {
      .centered-width(94%);
    }
  }

  &.scrollable {
    overflow: auto;
  }

  &.no-bg {
    background: transparent;
    border: none;
  }

  &.padded {

    >p,
    >h2 {
      padding: 15px 3%;
    }
  }

  &.small-padding {
    padding: 0 18px;
  }

  &.margined {

    >p,
    >h1,
    >h2,
    >h3 {
      margin: 25px 3%;
    }
  }

  >header {
    background-color: @color-background;
    text-transform: uppercase;
    line-height: 3em;
    padding: 0 15px;
    font-size: 0.9em;
    border-bottom: 1px solid @color-borders;
    overflow: auto;

    h1 {
      float: left;

      @media (max-width: @screen-xs-max) {
        float: none;
        display: block;
        width: auto;
      }
    }

    .header-note {
      text-transform: none;
      color: @color-medium-gray;
      font-weight: normal;
    }

    >a {
      text-transform: none;
    }

    li {
      float: right;
      text-transform: none;

      @media (min-width: @screen-sm) {
        margin-left: 15px;
        padding-left: 15px;
        border-left: 1px solid @color-borders;
      }

      @media (max-width: @screen-xs-max) {
        float: none;
        display: block;
        width: auto;
        text-align: right;
        border-top: 1px solid @color-borders;
      }
    }

    &.no-mobile-collapse {
      h1 {
        float: left;
      }

      li {
        display: block;
        float: right;
        text-transform: none;
        margin-left: 15px;
        padding-left: 15px;
        border-top: none;
        border-left: 1px solid @color-borders;
      }
    }

  }

  .empty-state {
    font-size: 1.4em;
    line-height: 1em;
    padding: 3.5% 2%;
    min-height: 5em;
    color: @color-grey;
    text-align: center;
    font-style: italic;
    clear: both;

    @media (min-width: @screen-md) {
      padding-top: 2em;
    }

    p {
      font-size: .75em;
      line-height: 1em;
      padding: .5em 10% 0;
    }
  }

  .secondary-nav {
    margin-right: 25px;
    margin-bottom: 25px;
    margin-left: 25px;
  }

  &.dangerzone {
    margin-top: 100px;

    >header {
      color: @color-danger;
    }

    .secondary-nav {
      margin-top: 25px;

      li {
        float: none;
        clear: both;
        margin: 0;

        .btn,
        button,
        input[type=submit],
        input[type=reset],
        input[type=button] {
          display: block;
          float: none;
          width: auto;
        }
      }
    }
  }

  &.center-headers {
    .all-headers({
      display: block;
      float: none;
      text-align: center;
    });
}
}

ul.bulleted-list {
  >li {
    list-style: disc outside none;
    margin-left: 20px;
  }
}

ul.comparison {
  .clearfix();

  @li-padding-h: 4%;


  &.full-click-comparison {
    >li {
      cursor: pointer;

      &:hover {
        background-color: @color-background;
      }
    }
  }


  >li {
    .clearfix();
    position: relative;
    padding-top: 12px;
    padding-bottom: 80px;
    display: block;
    width: 50%;
    float: left;
    text-align: center;
    min-height: 25em;
    border: none;
    .box-shadow(inset 0px 0px 34px 0 fade(@color-tertiary, 25%));

    ul {
      list-style: circle outside;
      text-align: left;
      text-indent: 0;
      margin: 0 9%;
      margin-top: 20px;
    }

    .icon-container {
      padding: 12px 2%;
      font-size: 100px;
      line-height: 1.3em;
    }

    .hug-bottom {
      position: absolute;
      left: 50%;
      margin-left: -150px;
      width: 300px;
      bottom: 24px;
    }

    @media (min-width: @screen-sm) {
      padding-right: @li-padding-h;
      padding-left: @li-padding-h;

      &:nth-child(2n+1) {
        border-right: 1px solid @color-borders;
      }
    }

    @media (max-width: @screen-xs-max) {
      width: initial;
      float: none;
      padding-right: @li-padding-h;
      padding-left: @li-padding-h;
      min-height: initial;
      padding-bottom: 0;
      border-bottom: 1px solid @color-borders;

      ul {
        margin-bottom: 26px;
      }


      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

      .hug-bottom {
        position: static;
        left: 0;
        margin-left: auto;
        width: auto;
        padding-bottom: 0;
        margin-bottom: 26px;
      }
    }

    h1 {
      font-size: 3em;
      color: @color-primary;
    }

    h2 {
      text-align: left;
    }
  }
}


/* Radio Options ********************************************************/
.radio-options-lg,
.radio-options-md {
  margin-top: 15px;
  margin-left: 15%;
  margin-right: 15%;

  @media (max-width: @screen-xs-max) {
    margin-left: 0;
    margin-right: 0;
  }

  .radio-options-list {
    .clearfix();
  }

  .radio-option {
    @radio-options-margin: 4%;
    @radio-options-count: 2;
    @border-radius: 3px;
    position: relative;
    margin-left: @radio-options-margin;
    width: (100% - (@radio-options-margin * (@radio-options-count + 1))) / 2;
    display: block;
    float: left;

    @media (max-width: @screen-ss-max) {
      float: none;
      width: auto;
      margin-left: @radio-options-margin;
      margin-right: @radio-options-margin;
      margin-bottom: 5px;
    }

    text-align: center;
    height: 15em;
    border: 3px solid transparent;
    .border-radius(@border-radius);
    .transition-multiple(border-color, border-radius, .2s linear);

    label {
      .transition-multiple(border-color, border-radius, .2s linear);
      display: block;
      position: relative;
      padding: 4%;
      height: 100%;
      border: 1px solid @color-borders;
      .box-shadow(1px 1px 2px 0 rgba(184, 184, 184, 0.25));
      .border-radius(@border-radius);

      >h1 {
        display: block;
        font-size: 1.5em;
      }

      >span {
        display: block;
      }
    }

    &.active,
    &:hover {
      .transition-multiple(border-color, border-radius, .2s linear);

      label {
        .transition-multiple(border-color, border-radius, .2s linear);
        .border-radius(@border-radius / 2);
        .box-shadow(1px 1px 2px 0 rgba(184, 184, 184, 0.15));
      }
    }

    &:hover {
      border-color: fade(@color-primary, 25%);

      label {
        border-color: fade(@color-primary, 25%);
      }
    }

    &.active,
    &.active:hover {
      border-color: @color-primary;

      label {
        border-color: @color-primary;
      }

      &:before {
        .fa-icon();
        position: absolute;
        top: 12px;
        right: 12px;
        content: @fa-var-check;
        font-size: 24px;
        color: @color-primary;
      }
    }

    .radio-option-value,
    .form-note {
      position: absolute;
      left: 4%;
      right: 4%;
    }

    .radio-option-value {
      top: 1.3em;
      font-size: 3em;
      white-space: nowrap;

      img {
        max-height: 1.4em;
      }
    }

    .form-note {
      top: 11em;
    }

    .btn {
      position: relative;
      margin-top: 10em;
      max-width: 92%;
      min-width: 10em;
    }

    &.radio-option-success {
      .radio-option-value {
        color: @color-success;
      }
    }

    &.radio-option-tertiary {
      .radio-option-value {
        color: @color-tertiary;
      }
    }
  }

  input[type=radio],
  input[type=checkbox] {
    display: none;
  }
}

/* Headers ********************************************************/
h1 {
  font-size: 1.2em;
}

h2 {
  font-size: 1.1em;
}

h3,
p {
  font-size: 0.9em;
}

.secondary-header {
  .full-width-headers() {
    float: none;
    clear: both;
    display: block;
    width: auto;
  }

  .clearfix();
  overflow: visible;
  margin-bottom: 15px;

  h1,
  h2,
  h3,
  p {
    float: left;
    clear: left;

    @media (max-width: @screen-xs-max) {
      .full-width-headers();
    }
  }

  h1 {
    font-size: 2.5em;

    @media (max-width: @screen-xs-max) {
      font-size: 1.75em;
    }
  }

  h2,
  h3 {
    font-weight: normal;
  }

  p {
    font-size: 1em;
  }

  &.banner-header {
    margin-bottom: 25px;

    h1,
    h2,
    h3,
    p {
      .full-width-headers();
      text-align: center;
    }
  }
}

.container {
  .secondary-header {
    margin: 15px 4% 0 4%;

    h1,
    h2,
    h3,
    p {
      margin-bottom: 15px;
    }

    h1 {
      font-size: 1.6em;
    }

    h2 {
      font-size: 1.4em;
    }

    h1,
    h2,
    h3 {
      font-weight: normal;

      strong {
        font-weight: bold;

        @media (max-width: @screen-md-max) {
          display: block;
        }
      }
    }
  }

  .banner-header {
    margin: 21px 4% 15px 4%;

    h1 {
      display: block;
      float: none;
      text-align: center;
      font-size: 1.6em;
    }
  }
}

.status-box {
  text-transform: uppercase;

  &.capitalize {
    text-transform: capitalize;
  }

  &.complete,
  &.submitted {
    color: @color-success;
  }

  &.pending,
  &.user-complete {
    color: @color-secondary;
  }

  &.failed,
  &.error,
  &.action-required {
    color: @error-color;
  }
}

.invitation-header {
  margin-left: 12%;
  margin-right: 12%;

  @media (max-width: @screen-xs-max) {
    margin-left: 3%;
    margin-right: 3%;
  }
}

/* Lists **********************************************************/
ul,
ol {
  list-style: none;

  &.form-field {
    list-style: decimal inside;
    text-indent: 1em;
  }
}

ol.list {
  .numbered-list();
}

ul.list {
  .bulleted-list();
}

dl {
  font-size: 0.8em;
  overflow: auto;
  // margin-bottom: 25px;
}

dt,
dd {
  padding: 10px 0;
  float: left;
  min-height: 65px;
  line-height: 20px;
}

dt {
  text-transform: uppercase;
  width: 40%;
  font-weight: bold;
  text-align: right;
  padding-right: 1%;
  padding-left: 1%;
}

dd {
  width: 60%;
}

.two-column-attributes {
  dt {
    width: 20%;
  }

  dd {
    width: 30%;
  }

  @media (max-width: @screen-xs-max) {
    dt {
      width: 40%;
    }

    dd {
      width: 60%;
    }
  }

  dd,
  dt {
    border-bottom: 1px solid @color-borders;
  }

  &.container {
    dl {
      margin-bottom: 0;
    }
  }
}

.single-column-attributes {
  dt {
    text-align: left;;
    width: 40%;
  }

  dd {
    text-align: right;
    width: 60%;
  }

  @media (max-width: @screen-xs-max) {
    dt {
      width: 40%;
    }

    dd {
      width: 60%;
    }
  }

  dd,
  dt {
    align-content: center;
    border-bottom: 1px solid @color-borders;
    padding-left: 0;
    padding-right: 0;
    &:last-of-type {
      border-bottom: none;
    }
  }

  margin-bottom: 0;
  margin-left: 16px;
  margin-right: 16px;
}

.deletion-requests {
  color: @color-medium-dark-gray;
  font-size: 12px;
  font-style: italic;
  line-height: 2.0em;
  text-align: right;
}

.wider-dt {
  @media (max-width: @screen-xs-max) {
    dt {
      width: 60%;
    }

    dd {
      width: 40%;
    }
  }
}

.checkbox-picker {
  margin-bottom: 25px;

  li {
    border-bottom: 1px solid @color-borders;
    padding: 20px 30px;
  }

  &.border-first {
    li:first-child {
      border-top: 1px solid @color-borders;
    }
  }

  input[type="checkbox"] {
    vertical-align: middle;
    margin-right: 5px;
  }

  .note {
    color: @color-medium-gray;
    font-size: 0.8em;
    margin: 15px 35px;
  }
}

/* Forms ********************************************************/
form {
  .form-general-error {
    display: none;
  }

  &.with-errors {
    .form-general-error {
      display: block;
      color: @error-color;
    }
  }

  .ajax-loading {
    display: none;
  }

  &.submitting {
    .ajax-loading {
      display: inline-block;
    }

    .form-button-content {
      display: none;
    }

    .form-submit-button {
      .opacity(0.7);
      color: @color-white;
      cursor: default;
    }
  }
}

.single-column-form {
  .split-column {
    display: flex;

    .form-field {
      width: 46%;
    }
  }

  .form-field,
  .form-general-error {
    width: 92%;
    margin: 21px 4% 0 4%;
    float: none;

    .widget-container & {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .form-field {
    &:last-of-type {
      margin-bottom: 26px;
    }
  }
}

.form-field,
.form-general-error {
  margin: 5px 4%;

  &.giant {
    margin-top: 35px;
    margin-bottom: 35px;

    .input-error {
      font-size: 1em;
    }
  }

  &.form-field-roomy {
    margin-top: 25px;
    margin-bottom: 35px;
  }

  &.form-field-lg {
    margin-top: 25px;

    label {
      font-size: 1.4em;
    }

    input {
      padding: 2ex 1em;
    }

    &.floating-label {
      label.floating {
        font-size: @input-font-size-base;
        padding: 2ex 1em;
      }
    }
  }

  &.no-margin {
    margin: 0;
  }
}

.form-field {
  .optional-fields-container &.form-field-optional {
    display: none;
  }

  &.with-errors {
    .form-input {
      border: 1px solid @error-color;

      &[type="checkbox"] {
        outline: 1px solid @error-color;
      }
    }
  }

  &.archived {
    label:after {
      content: " (archived)";
    }

    &,
    input {
      color: @color-grey;
    }
  }

  &.radio-form-field {
    .radio-label {
      margin: .3em 0 0 2%;
      display: inline-block;

      input[type=radio] {
        margin-right: 1em;
        margin-bottom: .3em;
      }
    }
  }

  &.floating-label {
    position: relative;

    label.floating {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: @input-font-size-base;
      background-color: @color-tertiary;
      color: @color-white;
      border: 1px solid @color-tertiary;
      padding: 0.7em 0.5em;
      width: auto;
      text-align: right;
      .border-radius(0 3px 3px 0);

      &.floating-primary {
        background-color: @color-primary;
        border-color: @color-primary;
      }

      &.floating-secondary {
        background-color: @color-secondary;
        border-color: @color-secondary;
      }
    }
  }

  &.form-field-with-drop-down {
    .form-input-wrapper {
      position: relative;
      margin-top: 6px;

      .form-input {
        margin-top: 0;
        padding-right: 18px;
      }

      .drop-down-wrapper {
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: auto;
        width: 18px;
        height: 100%;
        cursor: pointer;

        .drop-down-toggler-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -7px;
          margin-left: -5px;
        }
      }
    }
  }

  &.email-invitation-label {
    .form-label {
      display: inline;
    }
  }

  .input-error {
    color: @error-color;
    font-size: 0.8em;
  }
}

.form-label {
  display: inline-block;
  font-size: 0.9em;

  .large-labels & {
    font-size: 1.3em;
  }
}

.form-input {
  .border-radius(3px);
  border: 1px solid @color-input-borders;
  padding: 0.7em 0.5em;
  width: 100%;
  margin-top: 6px;
  font-size: @input-font-size-base;

  &[disabled] {
    background: @color-grey;
    color: @color-medium-gray;
  }

  textarea& {
    min-height: 124px;
  }

  select& {
    line-height: 0.9em;
  }

  &.form-large-select {
    height: 20em;
  }

  &[type="checkbox"],
  &[type="radio"] {
    display: inline-block;
    width: initial;
    vertical-align: middle;
    margin: 0 5px 0 0;
  }
}

.form-note {
  font-size: 0.8em;
  line-height: 1.5em;
  color: @color-medium-gray;
  font-style: italic;
}

.form-image {
  max-height: 250px;
  text-align: center;

  width: 92%;
  margin: 15px 4%;

  img {
    max-height: 250px;
  }
}

progress {
  display: block;
}

.rc-tooltip {
  pointer-events: none;
}

.tos {
  max-height: 240px;
  overflow-y: scroll;
  background: @color-white;
  padding: 18px 29px;
  .box-shadow(inset 0 0 6px 0 fade(@color-black, 10%));
  border: 1px solid @color-borders;
  width: 70%;
  margin: 20px 15%;
  font-size: 0.9em;

  @media (max-width: @screen-xs-max) {
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
  }

  p,
  li {
    margin: 5px 0;
    font-size: 1em;
  }

  ol {
    list-style-position: inside;
    list-style-type: decimal;

    ol {
      list-style-type: lower-latin;
      text-indent: 20px;

      ol {
        list-style-type: lower-roman;
        text-indent: 40px;

        ol {
          list-style-type: decimal;
          text-indent: 60px;
        }
      }
    }
  }

}

.tos-check {
  text-align: center;
  font-size: 1.4em;
  width: 100%;
  float: none;
  margin: 0 0 30px 0;

  label {
    display: inline-block;
    margin-left: 12px;
  }

  input[type="checkbox"] {
    display: inline;
    position: relative;
    top: -0.1em;
  }
}

.tos-email {
  background: @color-white;
  padding: 18px 29px;
  .box-shadow(inset 0 0 6px 0 fade(@color-black, 10%));
  border: 1px solid @color-borders;
  width: 70%;
  margin: 20px 15%;
  font-size: 0.9em;

  @media (max-width: @screen-xs-max) {
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
  }

  p,
  li {
    margin: 5px 0;
    font-size: 1em;
  }

  ol {
    list-style-position: inside;
    list-style-type: decimal;

    ol {
      list-style-type: lower-latin;
      text-indent: 20px;
    }
  }

}

.text-center {
  text-align: center;
}

.agreement-content {
  background: @color-white;
  padding: 18px 29px;
  .box-shadow(inset 0 0 6px 0 fade(@color-black, 10%));
  border: 1px solid @color-borders;
  width: 70%;
  margin: 20px 15%;
  font-size: 0.9em;

  @media (max-width: @screen-xs-max) {
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
  }

  p,
  li {
    margin: 5px 0;
    font-size: 1em;
  }

  ol {
    list-style-position: inside;
    list-style-type: decimal;

    ol {
      list-style-type: lower-latin;
      text-indent: 20px;
    }
  }
}

a.refund-link {
  text-decoration: none;
  color: @color-black;
}

.red-dot {
  color: @color-red;
}

.green-dot {
  color: @color-success;
}

.yellow-dot {
  color: @color-yellow;
}

.statement-legend {
  padding-left: 2%;
  margin-top: -10px;

  p {
    padding-bottom: 5px
  }

  span {
    margin-right: 10px;
  }
}

@-moz-document url-prefix() {
  select {
    -moz-appearance: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAOtJREFUeJzt0bFNQwEMRdGXsZiLFsgbgMWYCgoamkhRlB+L+BzJve2bAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABwuNP0Ahe8JHmdXuKOvpN8JvmaXuQ/aX4f9wzzdt/X7NHMxxN/WDMfUfxhzXxM8Yc181HFH9bMxxV/WDMf+dK8H3c2fzXzscUf1sxHF39YI/56jfjrNeKv14i/XnN8/I9HHcNtGvHXa8RfrxF/vUb89Rrx12vEX6+5Pv55ZkWO1oi/XiP+eo346zXir9eIDwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPNgPwESkVWmaAb8AAAAASUVORK5CYII=');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
  }
}

/* Modals *********************************************************/
#modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: fade(@color-black, 80%);
  display: none;

  &.faint,
  &.tutorial {
    background-color: transparent;
    background-color: fade(@color-black, 20%);
    .transition(background-color, .2s, linear);
  }
}

.modal {
  display: none;
  background: @color-white;
  overflow: auto;
  padding: 0;
  .border-radius(2px);
  .box-shadow(2px 2px 5px 0 fade(@color-black, 60%));
  .absolutely-centered(100%, 100%);

  @media (min-width: @screen-xs) {
    .absolutely-centered(92%, 92%);
  }

  @media (min-width: @screen-md) {
    .absolutely-centered(85%, 92%);
  }

  @media (min-width: @screen-lg) {
    .absolutely-centered(75%, 92%);
  }

  #modal-wrapper.tutorial & {
    position: fixed;
    padding: 0;
    top: auto !important;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    max-height: 100%;
    .transition(all, .2s, linear);
    .border-radius(0);
    .box-shadow(0 -2px 8px 0 fade(@color-black, 60%));

    .container {
      min-height: 100%;
      border: none;

      >header {
        display: none;
      }

      p {
        margin: 3px 4% 20px;
      }
    }
  }

  video {
    width: 100%;
  }

  audio {
    width: 100%;
  }

  header {
    border-bottom: 1px solid @color-borders;
    margin-bottom: 20px;
    margin-top: 0;

    h1 {
      font-size: 1.9em;
      padding: 20px 20px;
    }
  }

  p {
    margin: 1em 8% 2em 8%;
    font-size: 1.1em;
  }

  .form-field {
    margin: 4px 1%;
    width: 48%;
  }

  .form-input {
    padding: 4px;
  }

  .close-btn {
    color: @color-grey;
    float: right;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;

    &:hover {
      .opacity(0.5);
    }
  }

  .container {
    &:last-child {
      margin-bottom: 0;
    }

    .modal-screen {
      display: none;
    }

    .modal-body {
      padding: 15px 4%;

      .all-headers({
        margin: 8px 0;
      });

    p {
      margin: 4px 0;
      font-size: inherit;
    }

    ul {
      list-style: disc outside;

      ul {
        list-style-type: circle;
      }
    }

    ol {
      list-style: decimal outside;
    }

    ul,
    ol {
      li {
        margin-left: 2em;
      }
    }

    .note {
      font-style: italic;
      font-size: 0.9em;
    }
  }
}

.secondary-header {
  h1 {
    @media (max-width: @screen-xs-max) {
      margin-bottom: 10px;
    }
  }
}

.secondary-nav {
  margin: 0 4% 25px 4%;

  @media (max-width: @screen-xs-max) {
    margin-bottom: 15px;
  }
}
}

/* Pagination *************************************************************/
.pagination {
  font-weight: bold;

  p {
    float: left;
    margin: 10px;
    font-size: 0.8em;
    color: @color-grey;
    line-height: 20px;
  }

  >ul {
    .clearfix();
  }

  li {
    float: right;
    margin: 10px 10px 0 0;
  }

  .page {
    display: inline-block;
    text-align: center;
    background: @color-light-grey;
    font-size: 13px;
    border: 1px solid @color-grey;
    padding: 2px 8px;
    .border-radius(4px);
  }

  a.page:hover {
    border-color: @color-secondary;
  }

  span.page {
    color: @color-primary;
    border-color: @color-primary;
  }

  .space {
    color: @color-light-grey;
  }
}

/* Uploads *************************************************************/
.upload-start,
.upload-progress,
.upload-success {
  display: none;
}

/* Transaction *************************************************************/
#transactions-list {
  background: none;
  border: none;

  .transaction {
    overflow: auto;
    border: 1px solid @color-borders;
    background: @color-white;
    margin: 20px 0;
    .border-radius(5px);
  }

  .transaction-info {
    float: left;
    width: 34%;
    margin: 25px 3%;
    border-right: 2px solid @color-borders;

    @media (max-width: @screen-xs-max) {
      float: none;
      width: auto;
      border-right: none;
    }

    header {
      margin-bottom: 15px;
    }
  }

  .transaction-details {
    float: left;
    width: 58%;
    margin: 25px 0;

    @media (max-width: @screen-xs-max) {
      float: none;
      width: auto;
      margin: 0 3%;
    }

    li {
      .clearfix();
      border-bottom: 1px solid @color-borders;
      margin-bottom: 10px;
      padding-bottom: 10px;
      font-weight: bold;

      &:last-child {
        border-bottom: none;
      }
    }

    .price {
      float: right;
      text-align: left;
      width: auto;
      margin-left: 10px;
      font-weight: normal;
    }
  }

  dl {
    margin: 0;
  }

  dt,
  dd {
    padding: 0;
    height: inherit;
    width: inherit;
    font-size: 1.1em;
    line-height: 1.3em;
    min-height: 2em;

    @media (max-width: @screen-xs-max) {
      min-height: 2em;
      line-height: 1.5em;
    }
  }

  dt {
    text-transform: none;
    font-weight: normal;
    clear: left;
    margin-right: 4px;
  }

  dd {
    font-weight: bold;
  }
}

/* Loading state ************************************************************/

.loading {
  header {
    background-color: @color-grey !important;
    color: @color-light-grey;
  }

  input,
  select {
    background-color: @color-grey !important;
  }

  .btn {
    background-color: @color-grey !important;
    color: @color-light-grey;
  }
}

/* Table Lists ************************/

.list-primary {
  header>div {
    background-color: @color-black;
    color: @color-white;
    padding-left: 12px;
    text-align: left;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 0.8em;
  }

  header>div,
  .list-row>div {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.9em;
    padding: 10px 0 10px 2%;
  }

  .list-row {
    background-color: @color-white;
    border-bottom: 1px solid @color-borders;
  }

  .selected>div {
    background-color: @color-light-grey;
  }
}

/* Discounts ************************************************************/

.discounted {
  font-weight: bold;
  color: @color-danger;
}

s {
  color: @color-grey;
}

/* Withdrawals **********************************************************/

.debit {
  color: @color-danger;
}

.deposit {
  color: @color-success;
}

.total-amount {
  font-weight: bold;
  font-size: 1.1em;
  margin: 10px 10px 5px;
  clear: both;
}

/* Roles **********************************************************/

.role-label {
  font-size: 16px;
  padding-bottom: 10px;
}

.role-note {
  padding-bottom: 8px;
}


/* Disable animation for test *****************************************/

.disable-animations *,
.disable-animations *:after,
.disable-animations *:before {
  transition-property: none !important;
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transform: none !important;
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  animation: none !important;
  -o-animation: none !important;
  -moz-animation: none !important;
  -ms-animation: none !important;
  -webkit-animation: none !important;
}

.clipboardjs-is-hover {
  cursor: pointer;
}
