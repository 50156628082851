.analytics {
  .chart-settings {
    padding: 20px;

    label {
      padding: 0 10px;
    }

    select {
      width: 180px;
      height: 40px;
      padding: 10px;
      font-size: 16px;
      border: 1px solid @color-input-borders;
    }

    select + label {
      padding-left: 20px;
    }
  }
}
