.analytics {
  .drop-downs {
    .drop-down-box {
      margin-bottom: 30px;
    }

    .date-drop-down {
      float: right;
      margin-top: 20px;

      .btn-primary {
        width: 100%;
        min-width: auto;
      }

      .mb-secondary {
        @media (max-width: @screen-xs-max) {
          width: 30px;
        }
      }

      @media (max-width: @screen-ss-max) {
        margin-bottom: 30px;
        margin-top: 0;
      }
    }
  }
}
