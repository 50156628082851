.scroll-view {
  position: relative;
  z-index: 1;

  @shadow-top:    inset 0  2px 2px -1px fade(@color-black, 10%);
  @shadow-bottom: inset 0 -2px 2px -1px fade(@color-black, 10%);

  .scroll-view-inner {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
  }

  .shadow-top,
  .shadow-bottom {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    height: 5px;
    z-index: 2;
    pointer-events: none;
  }

  &.show-shadow-top .shadow-top {
    display: block;
    top: 0;
    box-shadow: @shadow-top;
  }

  &.show-shadow-bottom .shadow-bottom {
    display: block;
    bottom: 0;
    box-shadow: @shadow-bottom;
  }
}
