// How many columns?
@scaffold-col-count: 12;
@scaffold-col-margin: 2%;
@scaffold-col-widths:
  8.3333%,
  16.6666%,
  24.9999%,
  33.3333%,
  41.6666%,
  50.0%,
  58.3333%,
  66.6666%,
  74.9999%,
  83.3333%,
  91.6666%,
  100.0%;

// List of all screen size names.
@scaffold-screen-names: ss, xs, sm, md, lg, xl, ll;
// List of all screen widths for media queries;
@scaffold-screen-widths:
  @screen-ss-min,
  @screen-xs-min,
  @screen-sm-min,
  @screen-md-min,
  @screen-lg-min,
  @screen-xl-min,
  @screen-ll-min;

// Apply rules all cols.
.all-col(@rules) {
  & {
    .for(@scaffold-screen-names); .-each(@screen-name) {
      .for(@scaffold-col-count); .-each(@col-num) {
        .col-@{screen-name}-@{col-num} {
          @rules();
        }
      }
    }
  }
}

// Set the width to the width of X cols.
.col(@col-num) {
  @col-width: extract(@scaffold-col-widths, @col-num);

  width: @col-width - @scaffold-col-margin;
}

// Push a col to the right by X cols.
.col-push(@col-num) {
  position: relative;
  @col-width: extract(@scaffold-col-widths, @col-num);

  left: @col-width;
}

// Pull a col to the left by X cols.
.col-pull(@col-num) {
  position: relative;
  @col-width: extract(@scaffold-col-widths, @col-num);

  left: @col-width * -1;
}

.row {
  .clearfix();

  width: 100% + @scaffold-col-margin;
}

// Set base props for all cols.
.all-col({
  float: left;
  width: 100% - @scaffold-col-margin;
  margin-right: @scaffold-col-margin;
});

// Define a col, including push and pull.
.scaffold-col(@screen-name, @col-num) {
  // example: .col-sm-6
  .col-@{screen-name}-@{col-num} {
    .col(@col-num);
  }

  // example: .col-sm-push-6
  .col-@{screen-name}-push-@{col-num} {
    .col-push(@col-num);
  }

  // example: .col-sm-pull-6
  .col-@{screen-name}-pull-@{col-num} {
    .col-pull(@col-num);
  }
}

// Define each col media-query for a screen name with a min-width.
.scaffold-cols(@screen-name, @screen-width) {
  @media (min-width: @screen-width) {
    & {
      .-loop(@col-num: 1) when (@col-num <= @scaffold-col-count) {
        .scaffold-col(@screen-name, @col-num);

        .-loop(@col-num + 1);
      }
      .-loop();
    }
  }
}

// Initialize cols for each screen size.
& {
  .-loop(@i: 1) when (@i <= length(@scaffold-screen-names)) {
    @screen-name: extract(@scaffold-screen-names, @i);
    @screen-width: extract(@scaffold-screen-widths, @i);
    .scaffold-cols(@screen-name, @screen-width);

    .-loop(@i + 1);
  }
  .-loop();
}
