@import "./dashboard/news";
@import "./dashboard/widgets";

.scrollbox {
  outline: none;
  overflow: auto;
  max-height: 20em;
}

// news
.news-list-widget {
  margin-bottom: 0;
}

.news-list {
  display: none;

  &.active {
    display: block;
  }

  border: none;
  border-top-width: 0;

  &:first-child {
    border-top-width: 1px;
  }

  > li {
    border-bottom: 1px solid fade(@color-black, 10%);
    float: left;
    list-style: none outside none;
    overflow: hidden;
    padding: 20px 4%;
    position: relative;
    .transition(all, 0.3s, ease 0s);

    width: 100%;

    > span,
    > i {
      font-size: 0.8em;
    }

    > span {
      color: @color-medium-gray;
      float: left;
      letter-spacing: 0.1px;
      margin-right: 1em;
    }

    > i {
      color: @color-medium-dark-gray;
      display: block;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.2px;
    }

    > p {
      color: @color-black;
      display: block;
      font-size: 1em;
      letter-spacing: 0.3px;
      line-height: 22px;
      margin: 0;
    }

    > .fa {
      margin-right: 2%;
      font-size: 2.5em;
    }

    > .newslist-hover {
      content: "";
      position: absolute;
      right: -106px;
      top: 0;
      .transition(right, 0.4s, ease 0s);

      background-color: @color-blue-gray;
      border: 1px solid tint(@color-blue-gray, 25%);

      > a {
        border-right: 1px solid fade(@color-black, 20%);
        color: @color-white;
        float: left;
        font-size: 16px;
        height: 35px;
        line-height: 32px;
        text-align: center;
        width: 35px;
        .transition(all, 0.4s, ease 0s);
      }

      .fa {
        position: relative;
        top: 0.1em;
      }
    }

    background-color: fade(shade(@color-background, 25%), 8%);

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: fade(shade(@color-background, 25%), 15%);

      > .newslist-hover {
        right: 0;
      }
    }

    &.sticky {
      background-color: fade(shade(@color-primary, 25%), 8%);

      &:hover {
        background-color: fade(shade(@color-primary, 25%), 15%);
      }
    }
  }
}

.notifications {
  .notification {
    &.unread {
      font-weight: bold;
    }
  }

  .fa {
    &.fa-commenting,
    &.fa-inbox,
    &.fa-cloud-upload,
    &.fa-video-camera,
    &.fa-users,
    &.fa-thumbs-o-up {
      color: @color-success;
    }

    &.fa-bolt {
      color: @color-yellow;
    }

    &.fa-comment {
      color: @color-secondary;
    }

    &.fa-bullhorn,
    &.fa-user-plus {
      color: @color-primary;
    }

    &.fa-warning,
    &.fa-exclamation-triangle,
    &.fa-user-times,
    &.fa-ban {
      color: @color-danger;
    }
  }
}

.dashboard-flex-row {
  margin: 0 -15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (max-width: 1028px) {
    flex-wrap: wrap;
  }

  .flex-item {
    display: block;
    padding: 0 10px;
    width: 100%;

    @media screen and (max-width: 1500px) {
      padding: 0 5px;
    }

    @media screen and (max-width: 1028px) {
      width: 50%;
    }
  }
}
