.social-reconnect-wrapper {
  ul {
    padding-bottom: 12px;

    li.title {
      padding-left: 0;
      padding-bottom: 7px;

      h2 {
        text-transform: uppercase;
        display: inline;
      }

      i {
        padding-right: 2px;
        display: inline;
      }

      p {
        display: inline;
      }
    }

    li.list-item {
      padding: 1px 0 10px 20px;
    }
  }
}

a.generic-error-link {
  color: inherit;
  font-weight: bold;
  text-decoration: underline;
}
