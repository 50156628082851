#artist-attach-form {
  .form-field {
    margin-bottom: 26px;
  }
}

.secondary-header.banner-header h1 {
  font-size: 2.3em;
  font-weight: normal;
}

.artists-form-field {
  &.floating-label {
    position: relative;

    button.floating {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: @input-font-size-base;
      background-color: @color-primary;
      color: @color-white;
      padding: 0.75em 1em;
      width: auto;
      line-height: inherit;
      height: inherit;
      min-height: 0;
      min-width: 0;
      .border-radius(0 3px 3px 0);
    }
  }

  .form-field {
    margin: 0;
    padding: 0;
  }
}

.artist-page-header {
  #create-artist-link {
    border: 1px solid @color-primary;
    min-height: 29px;
    height: 29px;
    line-height: 29px;
    padding: 0 12px;
    font-size: 0.8em;
    font-family: "source_sans_prosemibold";
    float: right;
    border-radius: 3px;
  }
}

.choose-artist {
  .profile-picker();

  border-bottom: 1px solid @color-grey;
  font-family: "source_sans_pro", sans-serif;

  .artist-page-header {
    padding: 20px;
  }

  .release-artist-picker {
    .profile-picker-item();

    padding: 10px 20px;

    .fa-chevron-right {
      display: block;
      float: right;
      color: @color-dark-grey;
      font-size: 20px;
      margin-right: 10px;
    }
  }

  .pagination p {
    font-size: 0.8em;
  }
}

.choose-facebook-page {
  .profile-picker();

  @font-size-px: 21px;
  @artist-image-height-width: 70px;

  border-bottom: 1px solid @color-grey;
  font-family: "source_sans_pro", sans-serif;

  #select-all-text,
  #select-all-checkbox {
    float: right;
    font-weight: normal;
    font-size: 0.9em;
  }

  .facebook-page-header {
    padding: 20px 20px 15px;
  }

  input[type="checkbox"] {
    position: relative;
    vertical-align: middle;
    border-radius: 5px;
  }

  .name {
    pointer-events: none;
  }

  .sync-enabled {
    .sync-label {
      display: inline;
    }
  }

  .sync-label {
    display: block;
    font-style: italic;
    font-weight: 100;
    font-size: 14px;
    display: none;
  }

  .facebook-page-picker {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;

    .profile-picker-item();

    .form-field {
      position: relative;
      display: inline;
    }

    .checkbox-right {
      font-size: 20px;
    }

    .name {
      width: 100%;
      top: 0;
      align-items: center;
    }

    .facebook-page-name {
      font-size: 18px;
    }

    .social-icon-container {
      margin-top: 5px;

      span {
        margin-right: 5px;
        font-size: 20px;
        line-height: 1em;
      }
    }
  }

  .facebook-page-info {
    flex: 1;
    align-items: center;
  }
}

.progress-cell {
  display: inline-block;
  margin-bottom: 0;
  width: 90%;
}

.progress-tooltip {
  vertical-align: super;
}

.create-artist-footer {
  margin: 20px;
}

#create-artist-form {
  display: none;

  .form-field {
    width: auto;
    margin: 20px;
  }

  .secondary-nav {
    margin: 20px;
  }

  &.new-release-artist-form {
    clear: both;
  }
}

.overlay-shader {
  background: fade(@color-black, 70%) none repeat scroll 0% 0%;
  display: none;

  .overlay-inner {
    top: 10%;

    .container-xs {
      header.mcn-header {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .button-width-adjust {
        text-transform: uppercase;
        border-radius: 3px;
        width: 220px;
      }

      .link {
        color: @color-primary;
        font-style: normal;
      }

      .container-content {
        .no-bottom {
          margin-bottom: 0;
        }

        .add-bottom {
          margin-bottom: 15px;
        }

        .flex-box {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;

          .icons-box {
            width: 5%;
          }

          .icon-list-item {
            margin-bottom: 10px;
            color: @color-success;
          }

          .list-item {
            margin-bottom: 10px;
            text-align: left;
          }
        }
      }
    }
  }
}

.create-artist-progress-tracker {
  margin: 10px 0 30px;
  padding: 15px 0 40px;
  .undraggable();

  cursor: default;
  display: flex;
  width: 100%;

  li {
    border-top: 3px solid @color-grey;
    color: @color-grey;
    display: inline-block;
    text-align: center;
    line-height: 3em;
    float: left;
    position: relative;
    white-space: nowrap;
    width: 100%;

    &.sync-intervention {
      width: 25%;
    }

    &.done {
      border-color: @color-primary;
      color: @color-primary;

      .counter {
        background: @color-primary;
      }
    }

    &.active,
    &.active:hover {
      border-color: @color-secondary;
      color: @color-secondary;

      .counter {
        background: @color-secondary;
      }
    }
  }

  a {
    cursor: default;
    width: 100%;
  }

  a[href] li {
    cursor: pointer;

    &:hover {
      color: @color-secondary;

      .counter {
        background: @color-secondary;
      }
    }
  }

  .counter-wrapper {
    position: absolute;
    display: block;
    height: 30px;
    line-height: 30px;
    top: -1em;
    left: 0;
    width: 100%;
    text-align: center;
    .border-radius(3px);
  }

  .counter {
    display: inline-block;
    width: 30px;
    height: 30px;
    .border-radius(15px);

    background: @color-grey;
    color: @color-white;
  }

  .step-label {
    display: block;
  }

  .step-label-short {
    display: none;
  }

  @media (max-width: @screen-xs-max) {
    .step-label {
      display: none;
    }

    .step-label-short {
      display: block;
    }
  }

  @media (max-width: @screen-xs) {
    padding-bottom: 20px;

    .step-label {
      display: none;
    }

    .step-label-short {
      display: none;
    }
  }
}

.new-artist {
  padding: 0 15px;

  header {
    background-color: transparent;
    text-transform: none;
    line-height: 3em;
    font-size: 0.9em;
    border-bottom: 1px solid @color-borders;
    overflow: auto;
    padding: 30px 0 15px;

    h1 {
      color: @color-text-header;
      font-size: 24px;
      line-height: 31px;
      font-weight: normal;
    }
  }

  .social-connect-button {
    .link-account,
    .link-account-additional-networks {
      height: 3em;
      border-radius: 0;
      margin-bottom: 20px;
      font-size: 0.9em;
      justify-content: flex-start;
      padding-left: 0;

      .fab,
      .va {
        font-size: 25px;
        top: 6px;
        right: 0;
      }
    }

    .social-icon {
      width: 50px;
      border-right: 1px solid;
      line-height: 3em;
    }

    .social-text {
      padding-left: 15px;
      text-transform: none;
    }
  }

  .single-column-form .form-field {
    margin: 21px 0.5% 0;
    width: auto;
  }

  .secondary-nav {
    margin-top: 25px;
    margin-right: 0.5%;
  }

  .additional-social-buttons {
    padding: 20px 0 10px;

    h1 {
      padding-bottom: 10px;
      margin-bottom: 20px;
      font-weight: normal;
      border-bottom: 1px solid @color-borders;
    }
  }

  .connected-accounts {
    margin-bottom: 30px;

    > h1 {
      padding-bottom: 10px;
      margin-bottom: 20px;
      font-weight: normal;
      border-bottom: 1px solid @color-borders;
    }

    .connected-accounts-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media (max-width: 469px) {
        justify-content: center;
      }
    }

    .social-avatar-image {
      width: 160px;
      height: 160px;
    }

    .social-avatar-container {
      position: relative;
      margin-bottom: 20px;

      .social-avatar {
        padding: 0 10px;
      }
    }

    .network-icon {
      position: absolute;
      bottom: 30px;
      right: 15px;
      height: 35px;
    }
  }

  .progress-cell {
    width: 100%;
    position: relative;
    text-align: center;
  }

  .progress-text {
    position: absolute;
    color: @color-black;
    font-style: italic;
    font-size: 10px;
    line-height: 20px;
    width: 100%;
  }
}

#retry-facebook-authorization {
  padding: 30px;

  .facebook-error-heading {
    color: @color-secondary;
    padding-bottom: 10px;
    font-size: 22px;

    @media (max-width: @screen-xs) {
      font-size: 20px;
    }
  }

  .facebook-error-info {
    padding-bottom: 20px;
  }

  .facebook-sub-info {
    color: @color-black;
    font-size: 16px;
    padding-bottom: 10px;

    @media (max-width: @screen-xs) {
      font-size: 14px;
    }
  }

  img {
    margin-bottom: 30px;
  }

  .btn-facebook-reauth {
    max-width: 280px;
    margin: 0 auto;
    background-color: @color-secondary;
  }
}
