@import "setup/confirmation";
@import "setup/call_to_action_banner";
@import "files/composer";

#services-picker {
  overflow: auto;
  margin: 30px 0;
  background: @color-background;
  clear: both;

  li {
    float: left;
    width: 50%;
    padding: 4% 5%;

    &.active {
      background: @color-grey;
    }
  }

  h1 {
    text-align: center;
    font-size: 4em;
  }

  p {
    margin: 30px 0;
    min-height: 240px;
    font-size: 1.2em;
  }

  .btn {
    width: 100%;
  }
}

#setup-attach-media {
  #use-existing-container,
  #name-new-media-container,
  #existing-medias-container,
  #upload-methods-container {
    display: none;
  }
}

#setup-upload {
  margin-bottom: 30px;
}

#upload-type-list {
  li {
    overflow: auto;
    margin: 20px 0;
    .clearfix();
  }

  .form-field {
    margin: 0;
    width: 91%;
  }

  .radio-wrapper {
    line-height: 80px;
    display: inline-block;
    width: 6%;
    text-align: center;
    label { display: none; }

    .fa {
      font-size: 1.5em;
    }
  }

  .form-field,
  .radio-wrapper {
    min-height: 80px;
    float: left;
  }

  .upload-progress {
    width: 91%;
    margin: 2px 0 2px 6%;
    clear: both;
  }

  #browse-hard-drive {
    font-size: 1.3em;
    border: 1px solid @color-borders;
    min-height: initial;
    padding: 23px 20px;
    color: @color-black;
    .border-radius(5px);

    .note {
      color: @color-grey;
      font-size: 0.7em;

      @media (max-width: @screen-xs-max) {
        display: block;
      }
    }

    &:hover {
      color: @color-secondary;
      border-color: @color-secondary;

      .note {
        color: @color-secondary;
      }
    }
  }

  #upload_type_internal {
    display: none;
  }
}

.metadata-container {
  overflow: auto;
  margin-bottom: 0;
  border-bottom-width: 0;

  .metadata-row {
    width: 100%;
  }

  .subheader {
    padding: 10px 10px 0;
  }

  .video-container {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 0;

    @media (max-width: @screen-sm) {
      padding: 20px 15px;
    }
  }

  &.expanded:last-of-type {
    border-bottom-width: 1px;
  }

  .download-button {
    display: block;
    width: 100%;
    background-color: @color-primary;
    color: @color-white;
    text-align: center;
    font-size: 16px;
    padding: 17px 0;
  }

  .upload-button {
    display: block;
    width: 100%;
    background-color: @color-black;
    color: @color-white;
    text-align: center;
    font-size: 16px;
    padding: 17px 0;
  }

  .thumbnail-section {
    padding: 15px;
  }

  .metadata-list {
    border-left: solid 1px @color-borders;
    border-right: solid 1px @color-borders;
    margin: 15px 0;

    li {
      padding: 15px;
      border-top: solid 1px @color-borders;
      border-bottom: solid 1px @color-borders;

      h1 {
        font-size: 14px;
        display: inline-block;
        font-weight: bold;
      }

      p {
        font-size: 14px;
        float: right;
      }
    }

    li:nth-child(even) {
      background-color: @color-background;
    }
  }

  form {
    .clearfix();

    clear: both;

    .text-input {
      max-width: 400px;
    }
  }

  .expanded-content {
    .clearfix();

    display: none;
  }

  .contracted-content {
    .clearfix();

    display: block;
  }

  .optional-content {
    display: none;
  }

  .metadata-summary {
    .form-field {
    }

    > p {
      margin: 25px 4%;
    }
  }

  .edit-button {
    float: right;
    font-size: 17px;
  }

  .input-errors {
    color: @color-red;
  }

  .earnings-row {
    padding: 15px 0 15px 15px;
  }

  .empty-earnings {
    text-align: center;
    margin: 15px;
  }

  .earnings-item {
    border: 1px solid @color-borders;

    .earnings-value {
      text-align: center;
      margin-top: 20px;
      font-size: 24px;
      font-weight: bold;
    }

    .earnings-name {
      font-size: 20px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .policy-list {
    margin: 10px;

    li {
      padding: 15px;
      border: 1px solid @color-background;
      width: 100%;

      &.dark {
        background-color: @color-background;
      }

      .policy-type {
        font-weight: bold;
        display: inline-block;
      }

      .policy-value {
        display: inline-block;
        text-align: right;
        float: right;
        text-transform: capitalize;
      }
    }
  }

  .all-rights-container {
    margin: 15px 15px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid @color-background;

    h2 {
      margin-bottom: 15px;
    }

    p {
      padding-left: 15px;
    }
  }

  .right-container {
    border-bottom: solid 1px @color-background;
    margin: 15px 15px 0;
    padding: 0 0 15px 15px;

    h2 {
      margin-bottom: 15px;
    }

    p {
      padding-left: 15px;
      display: inline-block;
    }
  }

  .composer-list {
    margin: 15px;

    h2 {
      padding: 0 0 15px 15px;
    }

    .composer-item {
      &.dark {
        background-color: @color-background;
      }

      margin: 0 30px;
      padding: 15px;
      border: solid 1px @color-borders;

      .composer-name {
        font-weight: bold;
        display: inline-block;
      }

      .composer-value {
        float: right;
      }
    }
  }

  .policy-metadata {
    padding: 12px 3%;

    h2 {
      border-bottom: 1px solid @color-med-grey;
    }
  }

  .bold {
    font-weight: bold;
  }

  .policy-request {
    text-align: center;
  }

  .policy-request-form {
    zoom: 1;
  }

  &.show-optional {
    .form-field-optional {
      display: block;
    }
  }

  &.expanded {
    .expanded-content {
      display: block;
      // margin-top: 20px;
      margin-bottom: 25px;

      .secondary-nav {
        padding: 0;
        margin-top: 25px;
        margin-bottom: 0;
        margin-right: 4%;
      }
    }

    .contracted-content {
      display: none;
    }

    .optional-content {
      display: block;
    }

    .always-visible {
      margin-bottom: 0;
    }

    #artist-selection {
      border-bottom: 1px solid @color-med-grey;
    }
  }

  .btn {
    float: right;
  }

  .extra-label {
    display: none;
  }

  .rights-info,
  .policy-metadata {
    h2 {
      border-bottom: 1px solid @color-med-grey;
      padding: 12px 3%;
    }
  }
}

#setup-networks {
  .checkbox-picker { /* Monetize only */
    padding: 12px 3%;

    input[type="checkbox"] {
      display: none;
    }

    li {
      padding: 20px 2%;
      .clearfix();

      label {
        h1 {
          font-weight: normal;
          font-size: 1.2em;
          line-height: 1.2em;
          .name {}

          .price {
            font-weight: bold;
            float: right;
            margin-left: 15px;

            @media (max-width: @screen-ss-max) {
              display: block;
              float: none;
              margin-left: 0;
            }
          }
        }

        .image-wrapper {
          position: relative;
          margin: 12px;
          float: left;
          border: 3px solid transparent;

          &,
          .border-wrapper {
            .border-radius(6px);
            .transition(border-color, 0.2s, linear);
          }

          .border-wrapper {
            border: 1px solid @color-borders;
          }

          img {
            display: block;
            max-height: 100px;
            margin: 12px;
            .undraggable();
          }

          .check-icon,
          .uncheck-icon {
            .transition(color, 0.2s, linear);

            display: block;
            position: absolute;
            bottom: 4px;
            right: 6px;
            font-size: 24px;
          }

          .check-icon {
            display: none;
            color: @color-primary;
          }

          .uncheck-icon {
            color: @color-borders;
          }
        }
      } // label

      &:hover {
        label {
          .image-wrapper {
            border-color: fade(@color-primary, 25%);

            .border-wrapper {
              border-color: transparent;
            }

            .uncheck-icon {
              color: fade(@color-primary, 25%);
            }
          }
        }
      }

      &.active,
      &.active:hover {
        label {
          .image-wrapper {
            border-color: @color-primary;

            &,
            .border-wrapper {
              .transition(none);
            }

            .border-wrapper {
              border-color: transparent;
            }
          }

          .check-icon {
            display: block;
          }

          .uncheck-icon {
            display: none;
          }
        }
      }
    }
  }
}

#additional-services {
  .checkbox-picker {
    label {
      .title {
        font-size: 1.2em;
        display: inline;
      }

      .price {
        font-weight: bold;
        margin-left: 15px;
      }

      .note {
        margin: 10px 3% 5px;
      }
    }
  }

  .empty-state {
    font-size: 1.4em;
    line-height: 1em;
    padding: 3.5% 2%;
    min-height: 5em;
    color: @color-grey;
    text-align: center;
    font-style: italic;
    clear: both;

    @media (min-width: @screen-md) {
      padding-top: 2em;
    }

    p {
      font-size: 0.75em;
      line-height: 1em;
      padding: 0.5em 10% 0;
    }
  }
}

.banner-examples-overlay {
  img {
    display: block;
    margin-top: 35px;
    margin-bottom: 35px;
  }
}
