.audio-to-video {
  .drop-file-area {
    position: relative;
    padding: 50px 20px;
    border-radius: 10px;
    border: 2px dashed @color-primary;
    text-align: center;
    color: @color-grey;
    font-size: 30px;

    .drop-area {
      .transition(all, 200ms, linear);

      input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      span {
        position: relative;
        top: 30px;
      }

      p {
        font-size: 0.7em;
        margin-top: 30px;
      }

      .fa {
        position: absolute;
        top: 20px;
        left: 50%;
        margin-left: -30px;
        font-size: 2em;
      }
    }
  }
}
