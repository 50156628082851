.withdraw-artists-socials {
  display: table;
  margin-bottom: 25px;
  border-left: 1px solid @color-borders;
  border-right: 1px solid @color-borders;
}

.withdraw-artists-socials-wrapper {
  margin-left: 3%;
  margin-right: 3%;
}

.warning-icon {
  color: @color-warning;
}

.success-icon {
  color: @color-success;
}

#withdraw-warning-social {
  td {
    height: 60px;
    padding-left: 5%;
  }

  .info {
    padding: 0 3% 10px;
  }

  .secondary-nav {
    padding-top: 10px;
  }

  .notices {
    border: none;
    padding: 0 3%;
    margin: 0;

    li {
      padding: 0;
    }
  }

  .warning-notices {
    background-color: @color-danger;
  }

  .note {
    text-indent: 20px;
  }

  .content {
    padding: 0 4%;
    background-color: @color-light-grey;
  }

  // TODO: This could be reusable class.
  .artist-image {
    margin-left: 5%;
    margin-top: 25px;
    vertical-align: middle;
    width: 100px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 10%;
    text-align: center;

    .name {
      position: relative;
      top: 40px;
      font-weight: bold;
    }

    .title {
      position: relative;
      top: -25px;
    }
  }

  .flex-box {
    display: flex;
    justify-content: space-around;
    position: relative;
    margin-top: 60px;
    height: 150px;
  }

  .account-info .artist-info {
    padding: 0 10%;
  }

  .social-avatar-title {
    font-size: 0.8em;
  }

  .wrapper {
    padding-left: 4%;
    padding-top: 2%;
    padding-right: 5%;
  }

  .bold {
    font-weight: bold;
    padding: 1%;
    text-transform: uppercase;
  }

  .social-avatar-title {
    font-weight: bold;
    font-size: 1em;
    color: @color-black;
  }

  .account-info {
    margin-right: 5%;

    .network-image-social {
      height: 25px;
      width: 25px;
      background: @color-white;
      border-radius: 100%;
      z-index: 2;
      position: absolute;
      top: 40%;
      left: 79%;
    }

    .title {
      text-align: center;
    }
  }

  .action-links-column {
    padding-right: 5%;
    position: relative;

    .network-image-social {
      height: 25px;
      width: 25px;
      background: @color-white;
      border-radius: 100%;
      z-index: 2;
      position: relative;
      margin-top: -20px;
      left: 10%;
    }

    a {
      width: 6em;
      height: 30px;
      line-height: 20px;
      margin: 0;
    }

    p {
      font-weight: bold;
      position: absolute;
      left: 40px;
      top: 15px;
    }

    .social-image {
      position: absolute;
      display: block;
      right: 6%;
      top: 5px;
      padding-right: 10%;
    }

    .social-complete {
      padding-right: 20%;
    }
  }

  .fa-link {
    color: @color-grey;
    padding-top: 10px;
    margin-left: 10%;
  }

  .social-avatar-image-sm {
    display: inline-block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 36px;
    height: 36px;
    .border-radius(100%);
  }

  .secondary-index {
    border-bottom: 1px solid @color-borders;
  }

  .connect-social-account {
    background-color: @color-primary;
  }

  @media (max-width: @screen-xs-max) {
    .title,
    .name {
      font-size: 0.9em;
    }
  }
}

.secondary-index {
  margin: 25px;
  font-weight: bold;
}

.disabled-content {
  opacity: 0.5;
  pointer-events: none;
}

#withdraw-overlay {
  top: 50%;
  transform: translate(0, -30%);

  p {
    margin: 15px;
  }

  .name {
    margin-bottom: 5px;
  }
}
