body.not-found {
  @content-height: 395px;
  @static-height-max: (@content-height + 190px);
  @static-height-min: (@static-height-max + 1px);

  #main-content {
    color: @color-white;
    text-align: center;
    margin-top: 50px;

    @media (min-height: @static-height-min) {
      position: absolute;
      top: 50%;
      margin-top: -(@content-height / 2);
      margin-bottom: 0;
    }
  }

  .container {
    border: none;
    background: none;
  }

  .content-wrapper {
    .set-asset-uri-for-path("/images/error_page_backgrounds/404.jpg");

    background-image: url(@asset-uri);
    // for repeating background
    // background-repeat: repeat;
    // background-size: 110px 110px;
  }

  #icon-header {
    color: @color-white;
    font-size: 180px;
    line-height: 180px;

    @media (max-height: @static-height-max) {
      display: none;
    }
  }

  .secondary-header {
    margin-top: 0;
    margin-bottom: 15px;

    h1,
    p {
      text-align: center;
      float: none;
    }

    h1 {
      font-size: 36px;
      line-height: 38px;
      font-weight: normal;

      strong {
        font-size: 38px;
        display: inline;
        font-weight: bold;
      }
    }
  }

  .secondary-nav {
    > ul > li {
      margin-left: 0;
      display: block;
      float: none;
    }

    .btn .fa {
      font-size: 1em;
      position: static;
    }
  }
}
