/*!
 * Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
@import "_variables.less";

:root, :host {
  --@{fa-css-prefix}-style-family-classic: '@{fa-style-family}';
  --@{fa-css-prefix}-font-light: normal 300 1em/1 '@{fa-style-family}';
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: @fa-font-display;
  src: url('@{fa-font-path}/fa-light-300.woff2') format('woff2'),
    url('@{fa-font-path}/fa-light-300.ttf') format('truetype');
}

.fal,
.@{fa-css-prefix}-light {
  font-weight: 300;
}
