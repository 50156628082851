@import (reference) "../shared/scaffold.less";
@import (reference) "../shared/mixins.less";
@import (reference) "../shared/variables.less";

@color-light-grey: #F6F6F6;
@disabled-color-hover: @color-light-grey;
@active-color-hover: tint(@color-primary, 70%);
@border-base: 2px solid @color-borders;

// Border-collapse rule hacks.
// http://stackoverflow.com/a/4096554/2696867

.multiselect {
  .ms-header {
    background: @color-black;
    color: @color-white;
  }

  .ms-col {
    .font-size(12);

    box-sizing: border-box;
    padding: 10px 15px;
    text-transform: uppercase;
    display: inline-block;
  }

  .ms-options {
    display: table;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }

  .ms-option {
    .transition(all, 200ms, linear);

    display: table-row;
    cursor: pointer;
    width: 100%;

    .ms-cell {
      .transition(all, 200ms, linear);

      border-top: @border-base;
      border-bottom: @border-base;

      &:first-of-type {
        border-left: @border-base;
      }

      &:last-of-type {
        border-right: @border-base;
      }
    }

    &:hover {
      .ms-cell {
        border-top-color: @active-color-hover;
        border-bottom-color: @active-color-hover;

        &:first-of-type {
          border-left-color: @active-color-hover;
        }

        &:last-of-type {
          background: @active-color-hover;
          border-right-color: @active-color-hover;
        }
      }

      &.disabled {
        .ms-cell {
          border-top-color: @disabled-color-hover;
          border-bottom-color: @disabled-color-hover;

          &:first-of-type {
            border-left-color: @disabled-color-hover;
          }

          &:last-of-type {
            background: @disabled-color-hover;
            border-right-color: @disabled-color-hover;
          }
        }
      }
    }

    &.selected {
      .transition(all, 60ms, linear);

      .ms-cell {
        .transition(all, 60ms, linear);

        border-top-color: @color-primary;
        border-bottom-color: @color-primary;

        &:first-of-type {
          border-left-color: @color-primary;
        }

        &:last-of-type {
          background: @color-primary;
          border-right-color: @color-primary;
          color: @color-white;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover { background: @color-white; }
    }
  }

  .ms-name {
    width: 20%;
    text-align: center;

    h2 {
      padding-bottom: 20px;
      padding-top: 20px;
    }

    > img {
      max-height: 100px;
    }
  }

  .ms-desc { width: 60%; }
  .ms-value { width: 20%; }

  .ms-cell {
    padding: 15px;
    display: table-cell;
    vertical-align: middle;

    &:first-of-type {
      border-left: @border-base;
    }
  }

  .ms-desc {
    p {
      margin-bottom: 0.5em;
    }
  }

  .ms-desc-title {
    font-weight: bold;
    .font-size(18);
  }

  .ms-value {
    .font-size(25);

    color: @color-black;
    font-weight: bold;
    padding-left: 20px;
    text-align: center;

    &-checked {
      float: left;
      margin-top: 5px;
    }

    .price {
      position: relative;
      display: inline-block;
    }
  }
}

@media (max-width: @screen-ss-max) {
  .multiselect {
    .ms-cell {
      padding: 0;
    }
  }
}
