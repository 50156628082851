.audio-to-video {
  .colorpicker {
    background-color: @color-white;

    .details,
    .sample {
      display: none;
    }

    .map {
      top: 0;
      right: 56px;
    }

    .hue-slider {
      top: 0;
      right: 35px;
    }

    .light-slider {
      top: 0;
    }

    .sat-slider {
      right: 56px;
    }

    .pointer {
      border: 1px solid @color-grey;
    }
  }

  .color-input {
    position: relative;

    .eye-dropper {
      position: absolute;
      top: 10px;
      right: 40px;
      color: @color-grey;
      cursor: pointer;

      &:hover {
        color: @color-grey;
      }

      &.enabled {
        color: @color-black;
      }
    }

    > input {
      display: block;
      width: 100%;
      padding: 10px;
    }

    > span {
      position: absolute;
      display: block;
      width: 26px;
      height: 26px;
      top: 6px;
      right: 6px;
      border: 1px solid @color-grey;
    }

    .colorpicker-wrapper {
      position: absolute;
      width: 100%;
      bottom: 100%;
      background-color: @color-white;
      border: 1px solid @color-grey;
      .box-shadow(1px 1px 1px fade(@color-black, 40%));

      .colorpicker {
        width: 100%;
        height: 200px;
        margin-top: 20px;
      }
    }
  }
}
