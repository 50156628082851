.analytics {
  .button-group {
    button {
      width: 50%;
      min-width: initial;
      height: 39px;
      min-height: 39px;
      line-height: 39px;
      border: 1px solid @color-grey;
      border-left-width: 0;
      background-color: @color-white;
      color: @color-black;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      .border-radius(0);

      &.selected {
        color: @color-white;
        background-color: @color-black;
        border-color: @color-black;
      }

      &:first-child {
        border-left-width: 1px;
        .border-radius(3px 0 0 3px);
      }

      &:last-child {
        .border-radius(0 3px 3px 0);
      }
    }
  }
}
