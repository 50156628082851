.release-details {
  margin: 0 0 30px;
  background: @color-white;
  padding: 0 10px 10px;
  font-size: 1.1em;
  .box-shadow(2px 2px 6px 0 fade(@color-black, 30%));

  .release-details-header {
    padding: 5px 0 5px 10px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: @color-light-grey;
    margin: 0 -10px 10px;
    border-bottom: 1px solid @color-borders;
  }

  .broadcast-list {
    margin: 0;
    width: 50%;
    float: left;

    @media (max-width: @screen-sm-max) {
      width: 100%;
    }

    .active {
      background-color: @color-light-grey;
      border-bottom: 2px solid @color-grey-shadow;
      padding: 20px 20px 19px;

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        background-color: @color-secondary;
        height: inherit;
      }
    }

    .broadcast-list-item:not(.active) {
      border-right: 1px solid @color-borders;
      background-color: @color-med-grey;
      border-bottom: 1px solid @color-borders;
      padding: 20px;
    }

    .broadcast-list-item {
      border-top: 1px solid @color-borders;
      position: relative;
      cursor: pointer;

      .broadcast-social {
        float: left;
        width: 70px;
        height: 70px;
        margin-right: 10px;
        position: relative;

        .social-icon {
          background: @color-white;
          border: 2px solid @color-white;
          border-radius: 100%;
          width: 45%;
          z-index: 2;
          position: absolute;
          top: 57%;
          left: 68%;
        }

        .social-avatar {
          padding: 0;
          width: inherit;
          height: inherit;

          div {
            width: inherit;
            height: inherit;
          }
        }
      }

      .item-info {
        float: left;
        padding: 10px 0 0 20px;

        .fa {
          padding-right: 5px;
        }

        p:first-child {
          font-weight: bold;
          padding-bottom: 5px;
        }
      }

      .angle-right {
        float: right;
        font-size: 2.2em;
        color: @color-primary;
        padding-right: 10px;
        padding-top: 11px;
      }
    }
  }

  .broadcast-details {
    padding: 20px;
    width: 50%;
    float: left;
    margin: 0;
    background-color: @color-light-grey;
    border-top: 1px solid @color-borders;
    border-right: 1px solid @color-borders;
    border-bottom: 2px solid @color-grey-shadow;

    @media (max-width: @screen-sm-max) {
      width: 100%;
      padding: 0;
    }

    .broadcast-details-header {
      color: @color-primary;
      border-bottom: solid 1px @color-borders;
      padding: 20px;
      cursor: pointer;
    }

    .broadcast-details-card {
      background-color: @color-white;
      padding: 10px 30px;
      height: 100%;

      .gfm-content();

      p {
        margin-top: 0;
      }

      .fa-twitter {
        color: @color-twitter;
      }

      .fa.info {
        font-size: inherit;
        margin: 5px 0 0 5px;
      }

      .broadcast-description {
        position: relative;

        div {
          padding: 5px 0 10px 40px;
          word-wrap: break-word;
        }

        a {
          position: absolute;
          top: 0;
          right: 0;

          .fa {
            position: relative;
          }
        }

        .fa {
          position: absolute;
        }

        .broadcast-description-row {
          border-bottom: 1px solid @color-borders;
          font-weight: normal;
          padding: 12px 0;

          span {
            float: right;
          }
        }
      }

      li {
        padding: 10px;
        font-weight: bold;
      }

      div {
        padding: 10px;
      }

      li,
      div {
        position: relative;

        span,
        time {
          padding-left: 40px;
          vertical-align: sub;
        }

        .fa,
        .fab,
        .va {
          font-size: 1.6em;
          position: absolute;
          padding-left: 0;
          padding-right: 10px;
        }

        .question {
          color: @color-primary;
          padding: 6px 0 0 10px;
          font-size: 1em;
        }

        a .fa {
          position: absolute;
          right: 10px;
          top: 20px;
          color: @color-primary;
          font-size: 1.1em;
        }
      }
    }
  }
}

.invoice {
  margin: 25px 0;
  background: @color-white;
  padding: 50px 9%;
  .box-shadow(2px 2px 6px 0 fade(@color-black, 30%));

  li {
    overflow: auto;
    padding: 20px 0;
    font-size: 1.2em;
    border-bottom: 1px solid @color-borders;
  }
}

.invoice-title {
  font-weight: bold;
}

.invoice-note {
  color: @color-medium-gray;
  font-size: 0.8em;
}

.invoice-info {
  float: left;
  width: 85%;
}

.invoice-price {
  float: left;
  text-align: right;
  width: 15%;
}

.invoice-price,
.invoice-note,
.invoice-info {
  @media (max-width: @screen-xs-max) {
    display: block;
    width: 100%;
  }
}

.invoice-subtotal {
  border-top: 1px solid @color-grey;
}

.invoice-subtotal,
.invoice-total,
li.invoice-yearly-total {
  p {
    text-align: right;
    font-size: 0.7em;
    clear: both;
  }

  .invoice-info {
    font-weight: bold;
    text-align: right;
  }

  .invoice-info,
  .invoice-price {
    font-size: 1.2em;
    clear: none;
  }
}

li.invoice-yearly-total {
  border-bottom: none;
  padding-bottom: 0;
}

#confirmation-form {
  #new-payment-method,
  #use-balance-options,
  #braintree-form-container,
  #confirmation-tos {
    display: none; // visibility handled in JS

    #confirmation-name {
      margin: 0 30%;

      @media (max-width: @screen-xs-max) {
        margin: 0 6%;
      }
    }

    .first-name {
      margin: 0 0 15px;
      width: 49%;
      float: left;

      @media (max-width: @screen-xs-max) {
        width: 100%;
      }
    }

    .last-name {
      margin: 0 0 15px 2%;
      width: 49%;
      float: left;

      @media (max-width: @screen-xs-max) {
        width: 100%;
        margin: 0 0 15px;
      }
    }
  }

  .cla-already-signed {
    display: none;
  }

  .panel,
  .credit_card_visible,
  .paypal_account_visible {
    .clearfix();
  }

  .confirmation-warning {
    margin-left: 15%;

    @media (max-width: @screen-md-max) {
      margin-left: 9%;
    }

    @media (max-width: @screen-xs-max) {
      margin-left: 6%;
    }
  }

  > .secondary-nav {
    margin-top: 50px;
  }

  #braintree-paypal-button {
    margin: auto;
  }

  .form-field {
    float: none;
    width: 40%;
    margin: 15px 30%;

    @media (max-width: @screen-sm-max) {
      width: 56%;
      margin-left: 22%;
      margin-right: 22%;
    }

    @media (max-width: @screen-sm-max) {
      width: 70%;
      margin-left: 15%;
      margin-right: 15%;
    }

    @media (max-width: @screen-xs-max) {
      width: 92%;
      margin-left: 4%;
      margin-right: 4%;
    }
  }

  .form-field.confirmation-check {
    font-weight: bold;
    text-align: left;
    float: none;
    width: 100%;
    margin: 15px 16%;

    @media (max-width: @screen-lg-max) {
      width: 80%;
      margin: 0 16%;
    }

    @media (max-width: @screen-sm-max) {
      width: 80%;
      margin: 0 16%;
    }

    @media (max-width: @screen-md-max) {
      width: 74%;
      margin: 3% 9%;
    }

    @media (max-width: @screen-xs-max) {
      width: 90%;
      margin: 4% 6%;
    }

    label {
      display: inline;
    }
  }

  .form-label {
    font-weight: bold;
  }

  .confirmation-warning {
    h1 {
      padding: 10px 0;
    }

    .bold {
      font-weight: bold;
    }

    p {
      padding-bottom: 5px;
      padding-left: 15px;
    }
  }

  .form-note {
    margin-bottom: 0;
  }

  .error-notices {
  }

  .with-placeholder label {
    display: none;
  }

  .braintree-error {
    text-align: center;
    padding-top: 50px;
  }
}

#existing-paypal-address-form {
  margin: 0 30%;

  @media (max-width: @screen-sm-max) {
    margin: 0 30%;
  }

  @media (max-width: @screen-xs-max) {
    margin: 0 16%;
  }

  @media (max-width: @screen-ss-max) {
    margin: 0 4%;
  }

  .street-address,
  .extended-address {
    margin: 0 0 15px;
    width: 100%;
    clear: both;
  }

  .country-code {
    margin: 0 0 15px;
    width: 49%;
    float: left;
  }

  .city {
    margin: 0 0 15px;
    width: 49%;
    float: left;
  }

  .state,
  .province {
    margin: 0 0 15px 2%;
    width: 49%;
    float: left;
  }

  .postal-code {
    margin: 0 0 15px 2%;
    width: 49%;
    float: right;
  }

  .secondary-header {
    h1,
    h2 {
      text-align: center;
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

#new-payment-method {
  display: none;

  #create-credit-card-form,
  #create-paypal-account-form {
    margin: 0 30%;

    @media (max-width: @screen-sm-max) {
      margin: 0 30%;
    }

    @media (max-width: @screen-xs-max) {
      margin: 0 16%;
    }

    @media (max-width: @screen-ss-max) {
      margin: 0 4%;
    }

    .first-name {
      margin: 0 0 15px;
      width: 49%;
      float: left;
    }

    .last-name {
      margin: 0 0 15px 2%;
      width: 49%;
      float: left;
    }

    .credit-card-number,
    .street-address,
    .extended-address {
      margin: 0 0 15px;
      width: 100%;
      clear: both;
    }

    .expiration-month {
      margin: 0 0 15px;
      width: 26%;
      float: left;
    }

    .expiration-year {
      margin: 0 0 15px 2%;
      width: 44%;
      float: left;
    }

    .cvv {
      margin: 0 0 15px 2%;
      width: 26%;
      float: left;
    }

    .country-code {
      margin: 0 0 15px;
      width: 49%;
      float: left;
    }

    .city {
      margin: 0 0 15px;
      width: 49%;
      float: left;
    }

    .state,
    .province {
      margin: 0 0 15px 2%;
      width: 49%;
      float: left;
    }

    .postal-code {
      margin: 0 0 15px 2%;
      width: 49%;
      float: right;
    }
  }

  .secondary-header {
    h1,
    h2 {
      text-align: center;
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

#purchased-ctas {
  padding: 60px 10% 0;

  > li {
    border-bottom: 1px solid @color-borders;
    margin-bottom: 40px;

    .secondary-nav {
      font-weight: bold;
    }

    nav {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .btn {
      margin: 0 0 25px 5px;
      font-weight: normal;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
