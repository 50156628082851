#video-preview-modal {
  @border-radius: 3px;

  padding: 0;
  border: none;
  background: transparent;
  border-radius: @border-radius;

  .container {
    border: none;
    margin: 0;
    padding: 0;
    background: transparent;
    border-radius: @border-radius;

    video {
      display: block;
      border: 0;
      border-radius: @border-radius;
    }
  }

  .close-btn {
    float: none;
    right: 3px;
    top: 3px;
    z-index: 9999;
    color: @color-white;
  }
}
