.merge-tags-wrapper {
  .select2-container .select2-choice {
    cursor: pointer;
    z-index: 200;
    border: none;

    > .select2-chosen {
      height: auto;
      padding: 0.5em;
    }

    .select2-arrow b {
      background-position: 0 2px;
    }
  }
}
