.analytics {
  .filter-description {
    p {
      padding: 15px;

      .fa {
        margin-right: 5px;
      }
    }
  }

  .filter-settings {
    .select2-container {
      display: block;
      width: 100%;

      ul {
        min-height: 57px;
        .box-shadow(none);

        .select2-search-field {
          span {
            position: absolute;
            display: none;
            overflow: visible;
            margin: 7px 0 0 10px;
            color: @color-grey;
            font-style: italic;
          }

          &.placeholder {
            span {
              display: block;
            }
          }
        }

        .select2-search-choice {
          background-image: none;
          background-color: fade(@color-success, 20%);
          border: none;
          padding: 10px 30px 10px 10px;
          .box-shadow(none);

          .select2-search-choice-close {
            top: 10px;
            right: 10px;
            left: initial;
            background-image: none !important;
            background-color: @color-success;
            color: @color-white;
            .border-radius(6px);

            &::before {
              position: relative;
              top: 5px;
              left: 3px;
              display: block;
              content: "";
              border: 1px solid @color-white;
              width: 6px;
              height: 2px;
            }
          }
        }
      }
    }
  }
}

.select2-hidden-accessible {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}
