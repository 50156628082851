#artist-overlay,
#social-video-overlay {
  display: none;
  background-color: fade(@color-black, 70%);

  .container {
    border: none;
    .border-radius(0px);

    header.video-preview-header {
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: transparent;

      .fa {
        position: relative;
        top: 1px;
        font-size: 1.2em;
      }
    }

    header.video-edit-header {
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h1 {
        text-transform: uppercase;
      }

      .fa {
        position: relative;
        top: 1px;
        font-size: 1.2em;
      }
    }

    .form-field {
      position: relative;
      padding: 10px 10px 0 175px;

      @media (max-width: @screen-xs-max) {
        padding: 10px 10px 0;
      }

      label {
        position: absolute;
        top: 10px;
        left: 10px;
        display: block;
        width: 155px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: right;
        padding: 10px 10px 0 0;

        @media (max-width: @screen-xs-max) {
          position: relative;
          width: 100%;
          text-align: left;
          padding: 10px 0;
          top: 0;
          left: 0;
        }
      }

      input[type="text"],
      select {
        display: block;
        width: 100%;
        margin-top: 0;
        padding-left: 12px;
      }

      select {
        padding: 10px;
        font-size: 16px;
        height: 40px;
        border: 1px solid @color-input-borders;
      }
    }

    .container-content {
      padding-top: 10px;
    }

    .secondary-nav {
      margin-top: 20px;
      padding-right: 10px;

      @media (max-width: @screen-xs-max) {
        padding-right: 0;
      }
    }
  }

  .error {
    display: block;
    padding-top: 10px;

    @media (max-width: @screen-ss-max) {
      padding-top: 0;
    }
  }
}

@media (max-width: @screen-xs-max) {
  .artist-overlay {
    .secondary-nav {
      li {
        text-align: center;
      }
    }
  }
}
