.audio-to-video {
  .batch-upload {
    clear: both;

    .batch-upload-area {
      background-color: @color-white;
      padding: 20px;

      &:only-child {
        padding-top: 0;

        .drop-area {
          padding: 80px 20px;

          span {
            top: 20px;
          }

          .fa {
            top: 80px;
          }
        }
      }
    }

    .batch-upload-list {
      header > div {
        width: 50%;
      }

      .list-body {
        max-height: 350px;
        overflow: auto;
      }

      .list-row > div {
        position: relative;
        width: 50%;
        padding: 20px;
        cursor: pointer;
        .transition(all, 200ms, linear);

        &:first-child {
          padding: 21px 20px;

          .fa {
            position: absolute;
            top: 22px;
            right: 0;
            font-size: 16px;

            &.sortable-icon {
              position: relative;
              top: initial;
              right: initial;
              padding-right: 10px;
            }

            &.selectable-slider {
              right: 30px;
            }
          }
        }
      }

      .progress {
        margin: 0;
      }

      .error {
        padding: 1px;
      }
    }
  }

  .step-two,
  .step-three {
    .batch-upload {
      .drop-file-area {
        padding: 10px;
        font-size: 16px;
        text-align: left;

        .drop-area {
          span {
            top: initial;
            left: 40px;
            margin-left: 0;
          }

          .fa {
            top: 8px;
            left: 15px;
            font-size: 1.6em;
          }
        }
      }

      .batch-upload-list {
        .list-row > div {
          padding: 10px 20px;

          &:first-child {
            padding: 11px 20px;

            .fa {
              top: 12px;
            }
          }
        }
      }
    }
  }
}
