.ugc-secondary-header {
  .secondary-header();

  margin-bottom: 0;
}

.ugc-main-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ugc-main-header {
  &.conflict-header {
    flex-wrap: nowrap;

    @media (max-width: @screen-md-max) {
      flex-wrap: wrap;
    }
  }
}

.ugc-title-header {
  display: flex;
  flex-direction: column;
}

.ugc-select-desktop {
  margin-top: 5px;
  width: 250px;
}

.ugc-select-mobile {
  margin-left: 0;
  margin-top: 5px;
  width: 100%;
}

.ugc-select-container {
  display: flex;
  justify-content: space-between;
}

.ugc-select-text {
  align-self: center;
  padding-right: 15px;
}

.ugc-visible-xs-header {
  .visible-xs();

  width: 100%;
}

.ugc-height-15 {
  height: 15px;
}

.ugc-height-5 {
  height: 5px;
}

.ugc-hidden-xs {
  .hidden-xs();

  height: 5px;
}

.ugc-visible-xs {
  .visible-xs();

  height: 15px;
}

.ugc-artist-header {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ugc-bold-font {
  font-family: source_sans_probold;
}

.ugc-artist-artist-desktop {
  .hidden-xs();

  font-family: source_sans_pro;
  margin-top: 0;
}

.ugc-artist-artist-mobile {
  .visible-xs();

  font-family: source_sans_pro;
  margin-top: 0;
}

.ugc-video-title-desktop {
  .hidden-xs();

  font-family: source_sans_pro;
  margin-top: 0;
}

.ugc-video-title-mobile {
  .visible-xs();

  font-family: source_sans_pro;
  margin-top: 0;
}

.ugc-summary-header {
  font-family: source_sans_probold;
  text-transform: uppercase;
}

.ugc-summary-body {
  font-size: 2em;
  font-family: source_sans_prolight;
}

.ugc-table {
  justify-content: center;
  text-align: center;

  #highlighted-claim {
    background-color: @warning-background-color;
  }
}

ul.comparison {
  .ugc-summary-column {
    padding-bottom: 10px;
    min-height: 0;
  }
}

.ugc-report-video {
  text-align: center;
}

.single-column-form {
  .form-field {
    &.ugc-claim-form-field {
      margin-left: 0;
      width: 100%;
    }
  }
}

.ugc-table-col-video-title {
  width: 40%;
}

.ugc-table-col-channel-display-name {
  width: 15%;
}

.ugc-table-col-content-type {
  width: 10%;
}

.ugc-table-col-time-created {
  width: 10%;
}

.ugc-table-col-video-views {
  width: 10%;
}

.ugc-conflict-table-col-territories {
  width: 20%;
}

.ugc-conflict-tbody {
  tr {
    td {
      padding: 10px;
    }
  }
}

#conflict-info-popup {
  .container-xs {
    .overlay-inner {
      top: 20%;
    }

    header.conflict-header {
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .conflict-text-container {
      padding: 20px;
    }

    .conflict-text-block-spacing {
      margin-bottom: 20px;
    }

    .conflict-text-block-header-spacing {
      margin-bottom: 10px;
    }
  }
}

.conflict-empty-state-header {
  font-size: 1.4em;
  padding: 3% 2% 10px;
  color: @color-medium-dark-gray;
  text-align: center;
  font-style: italic;
  clear: both;
}

.conflict-empty-state-subtext {
  font-size: 1.2em;
  line-height: 1.2em;
  padding: 0% 30% 3%;
  color: @color-medium-dark-gray;
  text-align: center;
  font-style: italic;
  clear: both;

  @media (max-width: @screen-xl-max) {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (max-width: @screen-ss-max) {
  .conflict-empty-state-header {
    padding: 3%;
    font-size: 0.9em;
  }

  .conflict-empty-state-subtext {
    padding: 0% 3% 3%;
    font-size: 0.8em;
  }
}

p.ugc-empty-state-header {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 26px;
  line-height: 34px;
  font-weight: 300;
  color: @color-medium-dark-gray;
  text-align: center;
  margin-bottom: 10px !important;
  margin-top: 30px;
}

.ugc-empty-state-subtext {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: @color-black;
  text-align: center;
}
