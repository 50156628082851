.audio-to-video {
  .global-settings {
    @settings-height: 350px;
    @settings-nav-height: 55px;

    .global-preview-outer {
      height: @settings-height;
    }

    .background-settings {
      position: relative;
      display: block;

      > nav {
        width: 100%;
        z-index: 10;
      }

      .background-settings-color {
        .photoshop-picker-wrapper > * {
          margin: 15px auto;
        }
      }

      .background-settings-upload {
        @background-settings-padding: 20px;
        @background-upload-file-height: 60px;

        position: relative;
        padding: @background-settings-padding;

        .background-upload-file {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          padding: 20px;
          z-index: 200;

          > div {
            display: block;
            float: left;
            width: 50%;

            .fa {
              position: relative;
              top: 2px;
              left: -15px;
              float: right;
              font-size: 16px;
              cursor: pointer;
            }
          }
        }

        .drop-file-area {
          position: relative;
          height: @settings-height - @settings-nav-height - (@background-settings-padding * 2);
          font-size: 20px;

          .drop-area {
            span {
              top: 70px;
            }
          }
        }

        &.has-background-upload-file {
          .drop-file-area {
            top: @background-upload-file-height;
            height: @settings-height - @settings-nav-height - (@background-settings-padding * 2) - @background-upload-file-height;
          }
        }
      }

      .background-settings-choose {
        .scroll-view-inner {
          max-height: @settings-height - @settings-nav-height;
        }

        .background-templates {
          margin: 8px;

          .background-template {
            float: left;
            width: 160px;
            height: 90px;
            margin: 10px;
            border: 3px solid transparent;
            background-color: transparent;
            cursor: pointer;

            &:hover,
            &.selected {
              border-color: @color-primary;
            }

            .background-template-image {
              position: relative;
              width: 100%;
              height: 100%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              background-image: linear-gradient(bottom, #474747 0%, @color-black 100%);
              background-image: linear-gradient(bottom, #474747 0%, @color-black 100%);
              background-image: linear-gradient(bottom, #474747 0%, @color-black 100%);
              background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #474747), color-stop(1, @color-black));
              background-image: linear-gradient(bottom, #474747 0%, @color-black 100%);
              background-image: linear-gradient(to top, #474747 0%, @color-black 100%);
            }

            &.selected {
              .background-template-image {
                .fa {
                  position: absolute;
                  top: 0;
                  right: 0;
                  font-size: 24px;
                  text-shadow: 0 1px 1px fade(@color-black, 70%);
                  color: @color-primary;
                }
              }
            }
          }
        }
      }
    }

    .global-preview-outer {
      position: relative;
    }
  }
}
