@select-2-border-radius: 3px;

.select2-container,
.select2-container-multi {
  margin-top: 6px;
  &.form-input {
    padding: 0;
  }

  &.select2-container-disabled {
    background-color: @color-grey;
  }

  .select2-choices {
    box-shadow: none;

    .select2-search-choice {
      background: none;
      background-color: tint(@color-borders, 35%);
      border-radius: 1px;
    }
  }

  &,
  &.select2-drop-above {
    .select2-choice {
      .border-radius(@select-2-border-radius);

      border: 1px solid @color-input-borders;
      padding: 0;
      height: auto;
      font-size: @input-font-size-base;

      > .select2-chosen {
        line-height: 1em;
        height: auto;
        padding: 0.7em 0.5em;
        color: @color-black;
      }

      .select2-arrow {
        border: none;
        background: @color-light-grey;

        b {
          background-position: 0 6px;
        }
      }
    }
  }
}

.select2-container {
  &.select2-dropdown-open {
    .select2-choice {
      .border-radius(@select-2-border-radius @select-2-border-radius 0 0);
    }

    &.select2-drop-above {
      .select2-choice {
        .border-radius(0 0 @select-2-border-radius @select-2-border-radius);
      }
    }
  }

  &,
  &.select2-drop-above {
    .select2-choice {
      background: @color-light-grey;
    }
  }

  .select2-choices {
    box-shadow: none;
  }
}

.select2-container-multi {
  .select2-choices {
    border: none !important;
    background: white !important;
    box-shadow: none;
    padding: 9px;
  }
}

.select2-drop {
  &,
  &.select2-drop-above {
    &.select2-drop-active {
      border-color: @color-input-borders;
    }
  }
}

.form-input .select2-choice {
  border: none;
}
