.audio-to-video,
.audio-to-video-picker {
  .monetize-videos,
  .publish-videos {
    > p {
      padding-bottom: 20px;
      font-size: 16px;
    }

    .buttons-bar {
      top: 0;
      background-color: transparent;
      text-align: right;

      .btn {
        float: none;
      }

      a {
        color: @color-grey;
      }
    }
  }
}
