.monetize,
.audio-to-video {
  .youtube-preview {
    text-align: center;

    .youtube-preview-image {
      padding-top: 10px;
    }
  }

  .facebook-preview {
    text-align: center;

    .facebook-preview-image {
      padding-top: 10px;
    }
  }
}
