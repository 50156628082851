.agency-subheader-container {
  position: relative;
  width: 100%;
  background: @color-med-grey;
  border-top: 1px solid @color-borders;
  border-right: 1px solid @color-borders;
  border-left: 1px solid @color-borders;

  .agency-artist-subheader {
    font-size: 21px;
    margin: 10px;
    display: inline-block;
  }

  .organization {
    position: absolute;
    right: 5px;
    bottom: 3px;
    text-align: right;

    .form-field-with-icon {
      display: inline-block;
      width: initial;
      float: none;
      margin: 0;

      .form-label {
        display: none;
      }

      .form-input {
        margin: 0;
        border-radius: 3px 0 0 3px;
      }
    }

    .btn-icon {
      width: 2.6em;
      min-width: initial;
      margin: 0 0 0 -3px;
      height: 2.6em;
      padding: 0;
      color: @color-white;
      font-size: 0.8em;
      background: @color-grey;
      border-radius: 0 3px 3px 0;
    }

    @media (max-width: @screen-sm-max) {
      position: relative;
      text-align: left;
      right: initial;
      bottom: initial;
      margin-left: 5px;
    }
  }
}
