.analytics {
  .date-range-field {
    position: relative;
    float: right;

    .date-range-display {
      width: 220px;
      padding: 10px;
      border: 1px solid @color-grey;
      background-color: @color-white;
      font-size: 12px;
      cursor: pointer;

      .fa {
        position: relative;
        top: 2px;
        float: right;
      }

      .fa-chevron-down {
        left: 3px;
        font-size: 10px;
      }
    }

    @media (max-width: @screen-ss-max) {
      display: block;
      width: 100%;

      .date-range-display {
        width: auto;
      }
    }

    .date-range-popup {
      display: none;
    }

    &.active {
      .date-range-popup {
        position: absolute;
        display: block;
        width: 640px;
        right: 0;
        top: 100%;
        z-index: 10000;
        background-color: @color-white;
        .box-shadow(0 0px 5px 0 fade(@color-black, 20%));

        .date-range-calendars {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
        }

        @media (max-width: @screen-ss-max) {
          width: 100%;

          .date-range-calendars {
            flex-wrap: wrap;
          }
        }

        label {
          display: block;
          float: left;
          width: 30%;
          padding: 5px;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
        }

        input {
          display: block;
          float: left;
          width: 70%;
          padding: 3px;
          text-align: center;
          font-size: 12px;
        }

        .date-range-picker {
          // display: block;
          // float: left;
          padding: 10px;

          &.date-range-start {
            padding-right: 5px;
          }

          &.date-range-finish {
            padding-left: 5px;
          }

          .date-picker {
            position: relative;
            margin-top: 8px;

            .dp-inner {
              border: none;
            }

            .dp-cell {
              padding: 3px 0;
              font-size: 11px;
            }

            .dp-header {
              height: 30px;

              table {
                background-color: @color-black;
              }

              .dp-cell {
                border: none;
                background-color: @color-black;
                color: @color-white;
                font-weight: none;
              }

              .dp-nav-cell {
                font-size: 16px;
                padding-top: 0;
              }
            }

            .dp-body {
              table {
                height: 136px;
                background-color: @color-white;
              }

              .dp-month-view {
                background-color: @color-grey;
              }

              .dp-week-day-name {
                background-color: @color-grey;
                border: 1px solid @color-grey;
                border-right-width: 1px;
                color: @color-white;
                padding: 2px 0;
              }

              .dp-day {
                background-color: @color-white;
                border: 1px solid @color-grey;
              }

              .dp-month,
              .dp-year {
                background-color: @color-white;
                border: 1px solid @color-grey;
                padding: 0;
              }

              .dp-prev,
              .dp-next {
                color: @color-grey;
              }

              .dp-value {
                background-color: @color-secondary;
                color: @color-white;
                font-weight: normal;
              }
            }

            .dp-footer {
              position: relative;
              top: -7px;
              border: 1px solid @color-grey;
              background-color: @color-white;
            }

            .dp-footer-today,
            .dp-footer-selected {
              display: inline-block;
              width: 50%;
              text-align: center;
              font-size: 11px;
              padding: 3px 0;
            }
          }
        }
      }
    }
  }
}
