.input-group-addon {
  width: 40px;
  height: 8px;
  background-color: @color-white;
  border: 0;
}

.glyphicon-calendar {
  color: @color-black;
}

.input-group.date {
  /* Do not display the text box where user can enter a date */
  .form-control {
    display: none !important;
  }
}

.bootstrap-datetimepicker-widget {
  left: -217px !important;
}

.scheduled-date,
.publish-now {
  position: absolute;
  left: 36px;
  top: 7px;
  font-size: 10px;
  font-weight: bold;
}

.table-condensed {
  vertical-align: middle;
}
