.disclaimer.file-upload-limit {
  .font-size(12);

  font-weight: bold;
  float: none;
  display: block;
  margin: 0 auto;
  width: auto;
  text-align: center;
  max-width: 440px;

  .overlay-note & {
    margin-bottom: 25px;
  }

  .import-container & {
    margin-bottom: 25px;
  }
}
