.iphone,
.ipad,
.ipod {
  select,
  textarea,
  input[type="text"],
  input[type="radio"],
  input[type="checkbox"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"] {
    &,
    &:focus,
    &:active {
      font-size: 16px !important;
    }
  }
}
