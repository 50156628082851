#social-sync-settings {
  h1.title {
    font-size: 2.2em;
  }

  h1.artist-name {
    font-weight: 200;
    font-family: "Source Sans Pro", sans-serif;
  }

  .title-container {
    margin-bottom: 20px;
  }

  .social-avatar-title {
    display: none;
  }

  .social-avatar-image {
    width: 70px;
    height: 70px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;
  }
  .switch input { display: none; }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @color-grey;
    transition: 0.4s;
    transition: 0.4s;
  }

  .slider::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: @color-white;
    transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: @color-primary;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px @color-primary;
  }

  input:checked + .slider::before {
    transform: translateX(26px);
    transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round::before {
    border-radius: 50%;
  }

  .social-sync-copy {
    font-size: 1em !important;
    font-weight: bold;
    padding: 15px;
  }

  .toggle-sync-container {
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .toggle-sync-label {
    margin-right: 20px;
  }

  #sync-rights-container {
    padding: 15px;
  }

  #sync-policies-container {
    padding: 0 15px;
  }

  .divider {
    margin: 0 auto 20px;
    border-color: @color-grey;
  }

  .sync-rights-header {
    text-transform: uppercase;
    padding-bottom: 10px;
  }

  .sync-rights-options {
    padding: 15px;
  }

  input[name="has_full_rights"] {
    margin-right: 10px;
  }

  .sync-radio {
    display: block;
    margin-bottom: 10px;
  }

  .policy-dropdowns,
  .category-dropdowns {
    border: none;
  }

  .category-dropdowns {
    margin-bottom: 0;
  }

  .policy-select,
  .category-select {
    padding: 10px;
    font-size: 16px;
    height: 40px;
    border: 1px solid @color-grey;
    width: 100%;
  }

  .policy-labels {
    line-height: 40px;
  }

  .hide {
    display: none;
  }

  .policy-request {
    text-align: center;
  }

  .social-sync-secondary-header {
    font-weight: normal;
  }

  .social-info-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .social-sync-avatar {
    display: inline-block;
  }

  .social-sync-info {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: inline-block;
    margin-right: 15px;
    text-align: right;
  }

  .social-sync-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: @screen-xs-max) {
  .title-container {
    text-align: left;
    margin-bottom: 10px;
  }

  .social-info-column {
    display: flex;
  }

  .social-info-container {
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .social-sync-info {
    text-align: left;
    margin-left: 15px;
  }

  .social-sync-name {
    text-align: left;
  }
}
