@import "variables";

@font-face {
  font-family: "va";
  src: url("@{icon-font-path}/va.eot?v=@{va-version}");
  src:
    url("@{icon-font-path}/va.eot?#iefix&v=@{va-version}") format("embedded-opentype"),
    url("@{icon-font-path}/va.woff?v=@{va-version}") format("woff"),
    url("@{icon-font-path}/va.ttf?v=@{va-version}") format("truetype"),
    url("@{icon-font-path}/va.svg?v=@{va-version}#va") format("svg");
  font-weight: normal;
  font-style: normal;
}

.va {
  position: relative;
  font-size: inherit;

  &.va-network-link {
    vertical-align: middle;
    top: 0;
    right: 0;
    margin-right: 5px;
    line-height: 1em;
    font-size: 1em;
  }
}

.va::before {
  display: inline-block;
  font-family: "va";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.va-content-id::before { content: "\0042"; }
.va-music-asset::before { content: "\0043"; }
.va-vevo::before { content: "\0044"; }
