.bs {
  @import "shared/custom_reset.less";
  // Import raw bootstrap.css instead of LESS to make all the bootstrap ~"" string
  // literals prefix correctly.
  @import (less) "../../node_modules/bootstrap/dist/css/bootstrap.css";
  @import (less) "../../node_modules/react-bootstrap-datetimepicker/css/bootstrap-datetimepicker.min.css";
  @import "shared/datetimepicker.less";
}

@import "shared/extensions";
@import "shared/reset";
@import "shared/variables";
@import "vendor/gradients";
@import "vendor/bootstrap/progress";
@import "vendor/animations/animatecss";
@import "vendor/flexbox";
@import "shared/mixins";
@import "fonts/source-sans-pro";
@import "fonts/montserrat";
@import "fonts/din";
@import "font-awesome/fontawesome";
@import "vydia-awesome/vydia-awesome";
@import "vendor/select2";
@import "shared/scaffold";
@import "shared/base";
@import "shared/layout";
@import "shared/overlay";
@import "shared/overlay-success";
@import "shared/transition_groups";
@import "shared/required";
@import "shared/vendor_overrides/main";
@import "shared/disclaimers";
@import "shared/material-ui-overrides";
@import "shared/d2-intermediate";
@import "application/error_pages";
@import "application/dashboard";
@import "application/engagement";
@import "application/releases";
@import "application/home";
@import "application/setup";
@import "application/account";
@import "application/services";
@import "application/files";
@import "application/a2v";
@import "application/chart";
@import "application/chartjs";
@import "application/monetize";
@import "application/modals";
@import "application/multi-button";
@import "application/release-extras";
@import "application/artist_edit";
@import "application/artists";
@import "application/withdraw_warning";
@import "application/social_sync";
@import "application/users";
@import "application/reconnect_socials";
@import "application/storage";
@import "application/intermediate";
@import "application/gfm";
@import "modules/main";
@import "agents/apple";
@import "vendor/dtpicker";
@import "vendor/colorpicker";
