thead {
  background-color: @color-white;
  color: @color-black;
}

.btn.picker-switch {
  background-color: @color-white;
  color: @color-black;
  vertical-align: middle;
}

.datepicker {
  width: 240px;
  border: 0;
  box-shadow: none;
  box-shadow: none;
  box-shadow: none;
}

.btn {
  position: static !important;
  height: auto;
}

&.youtube-date {
  position: relative;
  left: 0;
  top: -15px;
  height: 15px;
}

&.facebook-date {
  position: relative;
  left: 0;
  top: -15px;
  height: 15px;
  margin: 0;
  padding: 0 !important;
}

.bootstrap-datetimepicker-widget {
  top: 30px !important;
}

.table-condensed {
  border-collapse: separate !important;
}

.table-condensed tr {
  background-color: @color-white;
}
