.audio-to-video {
  .audio-warning,
  .background-warning,
  .advanced-rights-warning {
    .container {
      position: relative;

      header {
        h1 {
          font-family: "source_sans_prolight";
          font-size: 26px;
          color: @color-black;
        }
      }

      .container-content {
        padding: 20px 4%;

        > .fa-times {
          position: absolute;
          font-size: 16px;
          top: 20px;
          right: 20px;
          cursor: pointer;
        }

        h2,
        li {
          margin-bottom: 1ex;
        }

        p {
          padding: 10px 0;
          font-size: 16px;
        }

        .image-preview {
          position: static;
          width: 50%;
          margin: 10px auto 0;
        }

        .secondary-nav {
          margin: 25px 0 0;

          a {
            text-transform: uppercase;
          }
        }

        > ul {
          &,
          & > li {
            list-style-type: square;
            list-style-position: inside;
            font-size: 16px;
            margin-left: 1em;
            text-indent: -1em;
          }
        }
      }
    }
  }
}
