.audio-to-video {
  .building-media {
    .container-content {
      padding: 25px;

      li {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      button {
        a {
          color: @color-white;
        }
      }
    }
  }
}
