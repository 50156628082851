.audio-to-video {
  .artist-picker {
    .clearfix();

    height: 80px;
    padding: 20px;

    label {
      display: block;
      float: left;
      padding: 5px 0;
      font-size: 20px;
    }

    select {
      display: block;
      float: right;
      width: 268px;
      height: 40px;
    }

    @media (max-width: @screen-xs-max) {
      height: auto;

      label,
      select {
        display: block;
        width: 100%;
        float: none;
      }
    }
  }

  .artist-overlay {
    .container {
      margin: auto;

      .form-field {
        padding-left: 175px;

        label {
          width: 155px;
        }

        select {
          height: 40px;
        }
      }

      .container-content {
        padding-top: 10px;
      }

      .secondary-nav {
        margin-top: 20px;
        padding-right: 10px;

        p {
          line-height: 3em;
        }
      }
    }
  }
}
