.social-connect-button {
  .link-account-additional-networks {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.additional-social-buttons {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

@media (max-width: @screen-ss-max) {
  #connected-accounts-table-body {
    .connect-social-account {
      min-width: 0;
    }
  }
}

.no-sync {
  color: @color-tertiary;
}

.edit-artist-empty-state {
  font-size: 1.4em;
  padding: 3% 2% 10px;
  color: @color-grey;
  text-align: center;
  font-style: italic;
  clear: both;
}

.edit-artist-empty-state-subtext {
  font-size: 1.2em;
  line-height: 1.2em;
  padding: 0% 20% 3%;
  color: @color-grey;
  text-align: center;
  font-style: italic;
  clear: both;
}

@media (max-width: @screen-ss-max) {
  .edit-artist-empty-state {
    padding: 3%;
    font-size: 0.9em;
  }

  .edit-artist-empty-state-subtext {
    padding: 0% 3% 3%;
    font-size: 0.8em;
  }
}
