.required-notice {
  padding: 0 15px;
  line-height: 3em;
  font-size: 0.9em;
}

.form-field,
.required-notice {
  .required {
    position: relative;
    left: -3px;
    color: @color-error;
    font-size: 12px;
    font-family: source_sans_proregular;
  }
}
