#sync-intervention-list {
  .sync-complete-container {
    text-align: center;
    padding: 40px 0;

    h2 {
      font-size: 1.3em;
      color: @color-grey;
      font-weight: normal;
      font-style: italic;
    }

    a {
      font-size: 1.2em;
      font-style: italic;
    }
  }

  td {
    padding: 5px 10px;

    &.actions {
      text-align: right;
      padding-right: 10px;
      white-space: nowrap;

      .sync-intervention-remove {
        color: @color-grey;
        font-style: italic;
        padding-right: 15px;
      }

      .sync-intervention-link {
        display: inline;
        padding: 5px 10px;
      }
    }
  }
}
