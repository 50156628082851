/*
  Name: HTML5 Reset
  Author URI: http://html5reset.org/
*/

html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

article,
aside,
figure,
footer,
header,
hgroup,
nav,
section { display: block; }

/* Responsive images and other embedded objects
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
   If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img,
object,
embed { max-width: 100%; }

ul { list-style: none; }

blockquote,
q { quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after { content: ""; content: none; }

a { margin: 0; padding: 0; background: transparent; text-decoration: none; }

del { text-decoration: line-through; }

abbr[title],
dfn[title] { border-bottom: 1px dotted @color-black; cursor: help; }

/* tables still need cellspacing="0" in the markup */
table,
.table { border-collapse: collapse; border-spacing: 0; }
th,
.th { font-weight: bold; vertical-align: bottom; }
td,
.td { font-weight: normal; vertical-align: top; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid @color-grey; margin: 1em 0; padding: 0; }

pre {
  white-space: pre; /* CSS2 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
}
input[type="radio"] { vertical-align: text-bottom; }
input[type="checkbox"] { vertical-align: bottom; *vertical-align: baseline; }
.ie6 input { vertical-align: text-bottom; }

/* Accessible focus treatment */
a:hover,
a:active { outline: none; }

strong,
th,
.th { font-weight: bold; }

td,
.td,
td img,
.td img { vertical-align: top; }

/* Make sure sup and sub don't screw with your line-heights
  gist.github.com/413930 */
sub,
sup { line-height: 0; position: relative; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }

/* standardize any monospaced elements */
pre,
code,
kbd,
samp {
  font-family: Monaco, "DejaVu Sans Mono", "Courier New", monospace, sans-serif;
  font-size: 0.9em;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type="button"],
input[type="submit"],
button { cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button,
input,
select,
textarea { margin: 0; }

/* make buttons play nice in IE */
button { width: auto; overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img { interpolation-mode: bicubic; }

/* let's clear some floats */
.clearfix::before,
.clearfix::after { content: "\0020"; display: block; height: 0; overflow: hidden; }
.clearfix::after { clear: both; }
.clearfix { zoom: 1; }

/* and hide some stuff */
.hidden { display: none; }
