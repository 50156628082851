.flip-container {
  .perspective(800px);

  .transition-group {
    display: block;

    // IMPORTANT:
    // `position: absolute` children treat this as `position: relative`
    // in some browsers, including Chrome 45.0.
    .transform-style(preserve-3d);
  }
}

// - -------------------------------------------------------------------- - //
// flip-horizontal
// - -------------------------------------------------------------------- - //
.flip-horizontal-enter {
  .transition(all, @flip-transition-duration-enter, @bezier-overshot-compat) !important;
  .transform(scale(0.6) rotateY(90deg));

  &.flip-horizontal-enter-active {
    .transform(scale(1) rotateY(0deg));
  }
}

.flip-horizontal-leave {
  .transition(all, @flip-transition-duration-leave, ease-in) !important;
  .transform(scale(1) rotateY(0deg));

  &.flip-horizontal-leave-active {
    .transform(scale(0.6) rotateY(90deg));
  }
}

// - -------------------------------------------------------------------- - //
// icon-slide-right
// - -------------------------------------------------------------------- - //
.icon-slide-right-enter {
  .transition-raw(all @icon-slide-transition-duration-enter @bezier-overshot-compat) !important;
  .translate(300%, 0);

  z-index: 999;

  &.icon-slide-right-enter-active {
    .translate(0, 0);
  }
}

.icon-slide-right-leave {
  .transition-raw(all @icon-slide-transition-duration-leave ease-in) !important;
  .translate(0, 0);

  z-index: 970;

  &.icon-slide-right-leave-active {
    .translate(300%, 0);
  }
}
