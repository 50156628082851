.analytics {
  .chart {
    .ct-chart {
      height: 300px;
      opacity: 1;
      .transition(opacity, 200ms, linear);

      .ct-label.ct-vertical {
        padding-top: 27px;
      }

      .ct-grid {
        stroke: @color-grey;
        stroke-dasharray: 0;
      }

      .ct-area {
        pointer-events: none;
        fill-opacity: 0.3;
      }

      @series: a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
      @colors: #27b8ed, #d70206, #f05b4f, #f4c63d, #d17905, #453d3f, #59922b, #0544d3,
                #6b0392, #f05b4f, #dda458, #eacf7d, #86797d, #b2c326, #6188e2, #a748ca,
                saturate(#27b8ed,20%), saturate(#d70206,20%), saturate(#f05b4f,20%),
                saturate(#f4c63d,20%), saturate(#d17905,20%), saturate(#453d3f,20%),
                saturate(#59922b,20%), saturate(#0544d3,20%), saturate(#6b0392,20%),
                saturate(#dda458,20%);

      .loopSeries(@i: length(@series)) when (@i > 0) {
        @serie: extract(@series, @i);
        @color: extract(@colors, @i);

        .ct-series.ct-series-@{serie} {
          .ct-line {
            stroke: @color;
          }

          .ct-area {
            fill: @color;
          }

          .ct-point {
            stroke: @color;
          }
        }
        .loopSeries((@i - 1));
      }
      .loopSeries;
    }
  }

  .monetize-analytics {
    .chart {
      .ct-chart {
        height: 300px;
        opacity: 1;
        .transition(opacity, 200ms, linear);

        .ct-label.ct-vertical {
          padding-top: 27px;
        }

        .ct-grid {
          stroke: @color-grey;
          stroke-dasharray: 0;
        }

        .ct-area {
          pointer-events: none;
          fill-opacity: 0.3;
        }

        .ct-series-b {
          .ct-line {
            stroke: @color-primary;
          }

          .ct-area {
            fill: @color-primary;
          }

          .ct-point {
            stroke: @color-primary;
          }
        }

        .ct-series-a {
          .ct-line {
            stroke: @color-grey;
            stroke-dasharray: 5px;
            shape-rendering: crispEdges;
          }

          .ct-area {
            fill: @color-med-grey;
          }

          .ct-point {
            stroke: @color-grey;
          }
        }
      }
    }
  }
}
