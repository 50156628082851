.comparison-container {
  border: none;
  background-color: transparent;
  text-align: center;

  header {
    text-transform: none;
    background-color: transparent;
    text-align: center;
    border: none;
    padding: 0;
    padding-bottom: 10px;

    @media (max-width: @screen-xs-max) {
      padding-bottom: 20px;
    }

    h1 {
      float: none;
      font-size: 28px;
      font-weight: normal;
      font-family: "source_sans_proregular";

      @media (max-width: @screen-xs-max) {
        font-size: 1.3em;
        line-height: 1.2;
      }
    }

    li {
      position: relative;
      top: -10px;
      float: none;
      border: none;
      margin: 0;
      padding: 0;
      font-size: 15px;
      font-family: "source_sans_proregular";
    }
  }

  &#choose-category-container {
    ul.comparison {
      > li {
        height: 460px;

        @media (max-width: @screen-xs-max) {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .hug-bottom {
        @media (max-width: @screen-xs-max) {
          margin-bottom: 0;
        }
      }
    }
  }

  ul.comparison {
    margin: auto;
    max-width: 1200px;

    &.three-items > li {
      width: 32%;
    }

    &.two-items > li {
      width: 48%;
      margin: 0 1%;
    }

    &.one-item > li {
      width: 80%;
      margin: 0 10%;
    }

    > li {
      position: relative;
      top: -2px;
      height: 490px;
      box-shadow: 0 0 1px 1px @color-grey;
      background-color: @color-white;
      padding: 0;
      border: none;

      h1 {
        position: relative;
        color: @color-white;
        padding: 20px 0;
        font-size: 30px;
        font-weight: normal;
        font-family: "source_sans_probold";
        text-transform: uppercase;

        strong {
          display: block;
          width: 86%;
          margin: 0 7%;
          font-size: 22px;
          text-transform: none;
          font-family: "source_sans_proregular";
          font-weight: normal;

          @media (max-width: @screen-xs-max) {
            font-size: 0.6em;
          }
        }

        span {
          display: block;
          font-size: 18px;
          text-transform: none;
          font-family: "source_sans_proregular";
        }

        .network-logos {
          height: 40px;
          width: 100%;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }

      .fa-chevron-right {
        display: none;
      }

      ul {
        margin-top: 40px;
      }

      li {
        list-style-type: none;
        border-bottom: 1px solid @color-borders;
        border-top: 1px solid @color-borders;
        padding: 10px 0;
        text-align: center;
        font-size: 13px;
        font-family: "source_sans_proregular";

        &:first-child {
          border-top-width: 0;
          padding-top: 0;
        }

        &:last-child {
          border-bottom-width: 0;
          padding-bottom: 0;
        }
      }

      .btn {
        font-size: 15px;
        font-family: "source_sans_prolight";

        .fa {
          margin-right: 5px;
        }
      }

      &#choose-monetize-service {
        .network-logos {
          text-transform: none;
        }
      }

      &#choose-distribute-service {
        .network-logos {
          .set-asset-uri-for-path("/images/Distribute_logos_release.png");

          background-image: url(@asset-uri);
        }
      }

      &#choose-create-media {
        .network-logos {
          .set-asset-uri-for-path("/images/ArtworkVideos_logos_release.png");

          background-image: url(@asset-uri);
        }
      }

      &#choose-music-category {
        .network-logos {
          .set-asset-uri-for-path("/images/MusicCategory_logos_release.png");

          background-image: url(@asset-uri);
        }
      }

      &#choose-web-category {
        .network-logos {
          .set-asset-uri-for-path("/images/WebCategory_logos_release.png");

          background-image: url(@asset-uri);
        }
      }

      &.comparison-primary {
        left: 2px;

        h1 {
          background-color: @color-primary;
        }

        .btn {
          background-color: @color-primary;
        }
      }

      &.comparison-secondary {
        margin: 0 0 0 2%;

        h1 {
          background-color: @color-secondary;
        }

        .btn {
          background-color: @color-secondary;
        }
      }

      &.comparison-tertiary {
        right: 2px;
        margin: 0 0 0 2%;

        h1 {
          background-color: @a2v-background-color;
        }

        ul {
          margin-top: 40px;
        }

        .btn {
          background-color: @a2v-background-color;
        }
      }

      &.comparison-music {
        @music-category-color: rgb(61, 113, 180);

        h1 {
          background-color: @music-category-color;
        }

        .btn {
          background-color: @music-category-color;
        }
      }

      &.comparison-web {
        @web-category-color: rgb(66, 183, 110);

        h1 {
          background-color: @web-category-color;
        }

        .btn {
          background-color: @web-category-color;
        }
      }

      &.comparison-audio {
        @audio-category-color: @color-secondary;

        h1 {
          background-color: @audio-category-color;
        }

        .btn {
          background-color: @audio-category-color;
        }
      }

      @media (max-width: @screen-xs-max) {
        display: block;
        width: 100% !important;
        height: 166px;

        .fa-chevron-right {
          display: block;
          position: absolute;
          right: 20px;
          top: 50%;
          color: @color-white;
          font-size: 20px;
          margin-top: -8px;
        }

        ul,
        .btn {
          display: none;
        }

        &.comparison-secondary {
          margin: 0;
        }

        &.comparison-primary,
        &.comparison-secondary,
        &.comparison-tertiary,
        &.comparison-music,
        &.comparison-web {
          left: 0;
          right: 0;
          margin-bottom: 25px;
        }
      }
    }
  }
}

#choose-subcategory-container {
  .profile-picker();

  .subcategory-item {
    .profile-picker-item();

    display: block;
    padding: 18px;
  }

  .fa-chevron-right {
    display: block;
    float: right;
    color: @color-dark-grey;
    @centered-height: 20px;
    @font-size: 16px;

    font-size: @font-size;
    margin-top: (@centered-height - @font-size) / 2;
    margin-right: 0;
  }
}
