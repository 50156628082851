.on-homepage(@rules) {
  body.home & {
    @rules();
  }
}

#main-header {
  background: @color-white;
  text-transform: uppercase;
  margin-bottom: 30px;
  min-height: @header-height;
  .clearfix();
  .box-shadow(0 0px 5px 0 fade(@color-black, 20%));

  .on-homepage({
    min-height: @header-height-large;
  });

  @media (min-width: @screen-md-min) {
    white-space: nowrap;
  }

  #nav-expander,
  #nav-action {
    display: none;
  }

  h1 {
    display: none;
  }

  .logo-container {
    float: left;
    height: @header-height;

    .on-homepage({
      height: @header-height-large;
    });

    .logo {
      margin-left: 14px;
      max-height: 100%;
      height: @header-height;

      .on-homepage({
        height: @header-height-large;
      });
    }
    .environment-text {
      position: absolute;
      top: 40px;
      left: 50px;
    }
  }

  .header-note {
    text-transform: lowercase;
    font-size: 0.7em;
    color: @color-grey;
    display: inline-block;
    margin-top: 24px;
    float: left;
  }

  nav {
    text-align: right;
    padding: 0 15px;

    ul {
      .js-react-component {
        min-width: 4.6em;

        @media (max-width: 845px) {
          min-width: 4.3em;
        }
      }
    }

    @media (max-width: @screen-sm-max) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  li {
    display: inline-block;

    > a {
      color: @color-grey;
      display: inline-block;
      font-size: 0.8em;
      font-weight: bold;
      font-family: "source_sans_prosemibold";
      padding: 2px 15px;
      height: @header-height;
      line-height: @header-height;

      @media (max-width: @screen-md-max) {
        padding: 2px 20px;
      }

      @media (max-width: 1060px) {
        padding: 2px 10px;
      }

      @media (max-width: 845px) {
        padding: 2px;
        font-size: 0.7em;
      }

      .on-homepage({
        height: @header-height-large;
        line-height: @header-height-large;
      });

      &:not(.btn) {
        &.active,
        &.active:hover {
          color: @color-primary;
        }

        &:hover,
        &.hover {
          color: @color-primary;
          filter: brightness(0.85);
        }
      }
    }

    > .btn {
      min-height: 29px;
      height: 29px;
      line-height: 29px;
      margin-right: 12px;
      min-width: initial;
      padding: 0 12px;
      border-bottom: none;
      text-transform: none;

      &.form-btn {
        margin-right: 12px;

        @media (max-width: @screen-sm-max) {
          margin-right: 4px;
        }
      }

      &,
      &:hover,
      &.hover,
      &:active,
      &:visited,
      &.active,
      &.active:hover {
        color: @color-white;
      }
    }

    .form-field {
      text-transform: none;
      text-align: left;
      width: 180px;
      margin: 10px 12px 10px 0;

      @media (max-width: @screen-sm-max) {
        width: 160px;
      }

      .form-input {
        padding: 0.4em;
        display: block;
      }
    }
  }

  .dropdown {
    position: relative;

    &:hover:not(.active),
    &.hover:not(.active) {
      a {
        border-bottom-color: transparent;
      }

      ul {
        max-height: 600px;
        z-index: 1002;
      }
    }

    .analytics-dropdown-list {
      min-width: 325px;
    }

    ul {
      right: 0;
      position: absolute;
      display: block;
      padding: 0;
      width: auto;
      z-index: 1001;
      background: @color-white;
      .box-shadow(0 0px 5px 0 fade(@color-black, 20%));

      max-height: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
      .transition(all, 0.5s, linear);
    }

    li {
      float: none;
      width: 100%;
      display: block;
      text-align: center;

      > a {
        line-height: 2.5em;
        height: 2.5em;
        border: none;
        margin: 2px 0;
        padding: 0 10px;
        width: 100%;
        font-size: 0.8em;
        text-align: center;
        color: @color-medium-dark-gray;

        &:not(.btn) {
          &.active,
          &.active:hover {
            color: @color-primary;
          }

          &:hover,
          &.hover {
            color: @color-primary;
            filter: brightness(0.85);
          }
        }
      }
    }
  }
}

#main-content {
  .clearfix();

  clear: both;
  width: 92%;
  padding-top: 32px;
  margin-left: 4%;
  margin-right: 4%;
}

/* Sticky footer hack */
.content-wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto;
  margin-bottom: -@footer-height; /* sticky footer height */
  background-size: cover;
  background-position: center;

  .push {
    height: @footer-height; /* sticky footer height */
  }

  > section {
    margin-bottom: 25px;
  }
}

@media (max-width: @screen-xs-max) {
  #main-header-push {
    .on-homepage({
      margin-top: @header-height-large;
    });
  }

  #main-header {
    height: auto;
    min-height: @header-height;

    .on-homepage({
      min-height: @header-height-large;
    });

    #nav-expander,
    #nav-action {
      display: block;
      position: absolute;
      height: @header-height;
      line-height: @header-height;

      .on-homepage({
        height: @header-height-large;
        line-height: @header-height-large;
      });

      i {
        padding: 6px 6px 4px;
        border-radius: 3px;
        cursor: pointer;
      }
    }

    #nav-expander {
      left: 21px;

      i {
        border: 1px solid @color-grey;
        color: @color-grey;

        &:hover,
        &.hover {
          border: 1px solid tint(@color-grey, 30%);
          color: tint(@color-grey, 30%);
        }
      }
    }

    #nav-action {
      right: 21px;

      i {
        background-color: @color-primary;
        border: 1px solid @color-primary;
        color: @color-white;

        &:hover,
        &.hover {
          border: 1px solid tint(@color-primary, 30%);
          background-color: tint(@color-primary, 30%);
          color: @color-white;
        }
      }
    }

    .logo-container {
      display: block;
      text-align: center;
      float: none;

      .logo {
        display: inline-block;
        margin: 0;
        float: none;

        @media (max-width: 350px) {
          height: 80%;
          margin-top: 6px;
        }
      }
    }

    nav {
      clear: both;
      text-align: center;

      li {
        float: none;

        a {
          border: none;
        }
      }

      // hidden
      display: none;
    }

    &.expanded {
      nav {
        display: block;
      }
    }

    > nav > ul > li a {
      font-size: 0.7em;
      font-weight: normal;
      padding: 0 15px;
      height: @header-height;
      line-height: @header-height;

      .on-homepage({
        height: @header-height-large;
        line-height: @header-height-large;
      });
    }

    .dropdown li {
      display: block;

      a {
        padding: 0;
        font-size: 0.7em;
      }
    }

    .header-note {
      float: none;
    }
  }

  .content-wrapper {
    margin: 0 auto -@footer-height-large;

    .push {
      height: @footer-height-large;
    }
  }
}

@media (max-width: @screen-ss-max) {
  #main-header {
    > nav > ul > li a {
      padding: 0 10px;
    }
  }
}

.search-filters {
  .clearfix();

  overflow: visible;

  .form-field,
  .btn {
    float: left;
  }

  .form-field {
    width: 26%;
    margin: 5px 2% 5px 0;
  }

  .btn {
    .btn-small();

    width: 16%;
    min-width: initial;
    margin: 32px 2% 15px 0;
    height: 2.6em;
    line-height: 2.6em;
  }

  .advance-search {
    width: 16%;
    min-width: initial;
    margin: 30px 0 15px;
    height: 2.6em;
    line-height: 2.6em;
    display: inline-block;
  }

  &.checkbox-filters {
    .btn {
      margin-top: 0;
    }
  }

  &.four-columns {
    .form-field {
      width: 23%;
    }
  }

  &.five-columns {
    .form-field {
      width: 19%;
    }
  }

  &.six-columns {
    .form-field {
      width: 14.8%;
    }
  }

  &.seven-columns {
    .form-field {
      width: 11.3%;
    }
  }

  &.eight-columns {
    .form-field {
      width: 10%;
    }
  }

  &.nine-columns {
    .form-field {
      width: 8.4%;
    }
  }

  &.ten-columns {
    .form-field {
      width: 7.4%;
    }
  }

  &.eleven-columns {
    .form-field {
      width: 7.3%;
    }
  }

  .full-width() {
    float: left;
    display: block;
    width: 100%;
    margin-right: 0;

    &.btn {
      margin-top: 15px;
    }
  }

  @media (max-width: @screen-sm-max) {
    &,
    &.four-columns,
    &.five-columns,
    &.six-columns,
    &.seven-columns,
    &.eight-columns,
    &.nine-columns,
    &.ten-columns,
    &.eleven-columns {
      .form-field,
      .btn {
        width: 49%;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }

    // When odd number of columns, make the last one full-width;
    &.five-columns,
    &.seven-columns,
    &.nine-columns,
    &.eleven-columns {
      .btn:last-of-type {
        .full-width();
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    &,
    &.four-columns,
    &.five-columns,
    &.six-columns,
    &.seven-columns,
    &.eight-columns,
    &.nine-columns,
    &.ten-columns,
    &.eleven-columns {
      .form-field,
      .btn {
        .full-width();
      }
    }
  }
}

/* Agency Invitation and List Form ***********/
h1 {
  &.agency-header {
    @media (max-width: @screen-xs-max) {
      margin-bottom: 20px;
    }
  }
}

.form-subheader {
  margin-left: 2%;
  margin-top: 25px;

  @media (max-width: @screen-xs-max) {
    margin-left: 4%;
  }
}

.invitation-form {
  .form-field {
    margin-top: 10px;
    margin-bottom: 0;
  }

  &.accept-form {
    padding-top: 20px;
  }

  .form-field {
    &.email-invitation-label {
      @media (min-width: @screen-md-min) {
        margin-left: 2%;
      }
    }
  }
}

.secondary-nav {
  &.send-invitation {
    margin-top: 25px;

    @media (max-width: @screen-xs-max) {
      margin-right: 4%;
      margin-left: 4%;
    }
  }
}

.agency-table {
  th,
  td {
    width: 20%;

    @media (max-width: @screen-md-max) {
      width: 27%;
    }

    @media (max-width: @screen-sm-max) {
      width: 33%;
    }
  }
}

.btn {
  &.btn-agency {
    @media (max-width: @screen-md-max) {
      min-width: 170px;
    }

    @media (max-width: @screen-xs-max) {
      min-width: 100px;
    }
  }
}
