.release-extras {
  .price {
    position: relative;
    text-align: left;
    margin-left: 5px;
    top: -1px;

    .original-price {
      right: 0;
      display: block;
      text-decoration: line-through;
      font-size: 0.8em;
      color: fade(@info-text-color, 60%);
    }

    .discounted-price {
      color: @color-danger;
      font-size: 0.9em;
    }
  }
}
