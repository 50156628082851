.multi-button {
  position: relative;
  overflow: visible;

  .btn {
    text-transform: none;
    text-align: left;

    > .fa {
      position: relative;
      top: 1px;
      left: -8px;
    }
  }

  .mb-secondary {
    position: absolute;
    overflow: visible;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    height: 100%;
    border-left: 1px solid @color-white;
    width: 40px;

    > ul {
      right: 0;
      left: auto;
      position: absolute;
      overflow: hidden;
      z-index: 1000;
      .transition(all, 0.5s, linear);

      max-height: 0;
    }

    &:hover {
      > ul {
        max-height: 600px;
      }
    }

    .mb-toggler {
      height: 100%;
      width: 100%;
      display: table;

      .fa {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        color: @color-white;
      }
    }
  }
}

.multi-button .mb-secondary,
.mb-popover {
  > .fa {
    color: @color-white;
    padding: 15px;
    border-left: 1px solid @color-white;
  }

  > .fab {
    color: @color-white;
    padding: 15px;
    border-left: 1px solid @color-white;
  }

  > ul {
    display: block;
    text-align: left;
    padding: 0;
    min-width: 100%;
    background-color: @color-white;
    .box-shadow(1px 1px 2px 0 fade(@color-black, 60%));
    .border-radius(0 0 3px 3px);

    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;

    > li {
      display: block;
      float: none;
      min-width: 100%;
      height: 3em;
      clear: both;
      margin-left: 0;
      border-bottom: 1px solid @color-borders;
      border-top: none;

      .btn {
        text-align: left;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        overflow: hidden;
        color: @color-black;
        background-color: @color-white;
        padding-top: 2px;
        text-transform: none;
        .border-radius(0);

        .fa {
          min-width: 1.3em;
          text-align: center;
          margin-right: 0.6em;

          &.icon-content-id {
            .make-background-image-icon();
            .set-asset-uri-for-path("/images/content-id-disabled.png");

            background-image: url(@asset-uri);
            width: 19px;
            height: 17px;
          }
        }

        .fab {
          min-width: 1.3em;
          text-align: center;
          margin-right: 0.6em;

          &.icon-content-id {
            .make-background-image-icon();
            .set-asset-uri-for-path("/images/content-id-disabled.png");

            background-image: url(@asset-uri);
            width: 19px;
            height: 17px;
          }
        }

        &:hover {
          color: @color-white;
          background-color: @color-primary;

          &.mb-color-primary {
            background-color: @color-primary;
          }

          &.mb-color-secondary {
            background-color: @color-secondary;
          }

          &.mb-color-tertiary {
            background-color: @color-tertiary;
          }
        }

        &.disabled {
          cursor: not-allowed;
          color: @color-grey;

          &:hover {
            background-color: @color-white;
          }
        }
      }
    }
  }
}
