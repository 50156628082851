.analytics {
  .container {
    border: none;
    .border-radius(0px);

    > header {
      padding: 8px 20px;
      background-color: @color-white;
      border-bottom-width: 2px;
      text-transform: none;
      color: @color-medium-dark-gray;

      .fa {
        position: relative;
        top: 1px;
        font-size: 1.2em;
        color: @color-grey;
        margin-right: 8px;
      }
    }

    &.filter {
      position: relative;
    }
  }

  .analytics-legend {
    padding-left: 0;
    margin: -15px 0 10px;

    p {
      .blue-dot {
        margin-right: 10px;
        color: @color-primary;
      }

      .gray-dot {
        margin-right: 10px;
        color: @color-grey;
      }
    }
  }
}
