#main-header-push {
  margin-top: @header-height;
}

#main-header {
  bottom: auto;
  display: block;
  height: auto;
  left: 0;
  margin-bottom: 0;
  min-height: @header-height;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1299;

  nav {
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: @screen-sm-max) {
      display: none;
    }
  }

  .logo-container {
    .logo {
      margin-left: 30px;
    }

    @media (max-width: @screen-sm-max) {
      text-align: center;
      float: none;

      .logo {
        margin-left: 0;
      }
    }
  }

  li > .btn {
    margin-right: 15px;
  }

  li:last-child > .btn {
    margin-right: 0;
    margin-left: 15px;
  }
}
