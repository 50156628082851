.storage-caption {
  color: @color-dark-grey;
  font-family: "source_sans_prolight";
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
  padding-bottom: 30px;
}

.storage-continue {
  height: 50px;
  width: 293px;
  float: right;

  @media (max-width: @screen-sm-max) {
    margin-bottom: 15px;
    display: block;
    width: 100%;
  }
}

.storage-footer {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0;

  @media (max-width: @screen-sm-max) {
    justify-content: center;
    flex-wrap: wrap-reverse;
  }

  @media (max-width: @screen-xs-max) {
    padding: 0;
  }
}

.storage-description {
  padding: 0 0 20px;
}

.storage-subheader {
  font-size: 2em;
}

.sidebar-wrapper {
  @media (max-width: @screen-sm-max) {
    padding-top: 50px;
  }
}

.enterprise {
  display: block;
  margin-bottom: 45px;

  @media (max-width: @screen-sm-max) {
    margin-bottom: 30px;
    text-align: center;
  }

  a {
    color: @color-secondary;

    &:visited {
      color: @color-secondary;
    }
  }
}

@media (max-width: @screen-sm-max) {
  .enterprise-link {
    display: block;
    color: @color-secondary;

    &:visited {
      color: @color-secondary;
    }
  }
}
