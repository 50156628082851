.success-overlay {
  text-align: center;

  .container {
    margin: auto;
    padding: 30px 0;

    header {
      background-color: @color-white;
      border-bottom: none;

      h1 {
        float: none;
        color: @color-success;
        font-family: source_sans_prolight;
        font-weight: normal;

        .fa,
        span:last-child {
          display: block;
          text-transform: none;
          font-size: 40px;
          padding-bottom: 10px;
        }
      }
    }

    .btn {
      text-transform: none;
    }

    h2,
    p {
      padding: 5px 20px;
      color: @color-medium-dark-gray;
      font-family: "source_sans_proregular";
    }

    a:not(.btn) {
      color: @color-medium-dark-gray;
      font-style: italic;
    }
  }
}
