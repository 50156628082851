table,
.table {
  width: 100%;
}

.table {
  display: table;

  .thead {
    display: table-header-group;
  }

  .tbody {
    display: table-row-group;
  }

  .tr {
    display: table-row;

    .td,
    .th {
      display: table-cell;
    }
  }
}

thead,
.thead {
  background-color: @color-table-header-background;
  line-height: 2.7em;
  color: @color-white;
  text-transform: inherit;
  font-size: 0.8em;

  .table-primary & {
    background-color: shade(@color-primary, 75%);
  }

  .table-secondary & {
    background-color: shade(@color-secondary, 75%);
  }

  @media (max-width: @screen-sm-max) {
    font-size: 0.7em;
  }

  th,
  .th {
    padding-left: @table-column-padding-h;
    text-align: left;
    white-space: nowrap;

    &.padded {
      padding-left: @table-column-padding-h * 2;
      padding-right: @table-column-padding-h * 2;
    }
  }

  a.pagination-link {
    color: @color-white;
  }
}

tbody,
.tbody {

  tr,
  .tr {
    border-bottom: 1px solid @color-borders;

    &:nth-child(2n) {
      background: @color-background;
    }

    &.vip-row {
      background: fade(@color-success, 10%);
    }

  }

  td,
  .td {
    vertical-align: middle;
    font-size: 0.9em;
    padding: 10px 4px 10px @table-column-padding-h;

    @media (max-width: @screen-sm-max) {
      font-size: 0.8em;
      padding-left: 3%;
    }

    &.notification-icon-column {
      width: 5%;
    }

    &.checkbox-column {
      text-align: center;
      border-right: 1px solid @color-borders;
      white-space: nowrap;
    }

    .italic {
      font-style: italic;
    }

    .form-field {
      margin: 0;

      .form-input {
        margin: 0;
      }
    }

    &.progress-column {
      padding-right: @table-column-padding-h;
      padding-left: @table-column-padding-h;

      @media (max-width: @screen-sm-max) {
        padding-right: 0;
        padding-left: 0;
      }

      .progress {
        margin-bottom: 0;
        min-width: 120px;

        @media (max-width: @screen-sm-max) {
          min-width: 90px;
        }

        @media (max-width: @screen-xs-max) {
          min-width: initial;

          &,
          .progress-bar {
            width: auto !important;
            background: none;
            border: none;
            .box-shadow(none);
          }

          .sr-only {
            display: block;
            color: @color-black;
          }
        }
      }
    }
  }

  .action-link,
  .action-btn {
    margin: 2px @table-column-padding-h 2px 0;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      .opacity(0.7);
    }
  }

  .action-link {
    color: @color-medium-gray;

    &.primary {
      color: @color-primary;
    }

    &.secondary {
      color: @color-secondary;
    }

    &.danger {
      color: @color-danger;
    }

    &.success {
      color: @color-success;
    }

    &.warning {
      color: @color-warning;
    }
  }

  .action-btn {
    display: inline-block;
    margin: 2px @table-column-padding-h 2px 0;
    padding: 4px 6px;
    min-width: 2em;
    background-color: @color-tertiary;
    white-space: nowrap;
    text-align: center;
    .border-radius(3px);

    &.action-btn-lg {
      padding: 6px 8px;
    }

    &,
    &:hover,
    &:visited,
    &:active {
      color: @color-white;
    }

    span {
      @media (max-width: @screen-sm-max) {
        display: none;
      }
    }

    &.always-show-text {
      span {
        display: inline-block;
      }
    }

    &.primary {
      background-color: @color-primary;
    }

    &.secondary {
      background-color: @color-secondary;
    }

    &.warning {
      background-color: @color-warning;
    }

    &.danger {
      background-color: @color-danger;
    }

    &.error {
      background-color: @color-error;
    }

    &.success {
      background-color: @color-success;
    }
  }
}

thead,
.thead,
tbody,
.tbody {

  td,
  th,
  .td,
  .th {
    &.action-links-column {
      text-align: right;
      white-space: nowrap;
      padding-right: 4px;
      padding-left: 4px;
    }
  }
}

td,
th,
.td,
.th {
  &.centered {
    text-align: center;
    padding-left: 0;
    margin: 0;

    input {
      vertical-align: middle;
    }
  }
}

.dual-table {

  tr td,
  tr th,
  .tr .td,
  .tr .th {
    border-right: 1px solid @color-borders;

    &:last-child {
      border-right: none;
    }
  }

  td,
  .td {
    width: 50%;
    line-height: normal;
  }

  thead,
  .thead {
    color: @color-grey;
    background: none;

    tr,
    .tr {
      border-bottom: 1px solid @color-borders;
    }
  }

  td,
  .td {
    background: @color-white;
  }

  .service-col {
    width: 50%;
  }

  .connected-col {
    width: 25%;
  }
}

.table-buttons {
  border-top: 1px solid @color-borders;

  .secondary-nav {
    margin-top: 25px;
  }
}

// Notification Date Column
.notifications,
#draft-releases,
#submitted-releases,
#action-required-releases {
  .action-links-column {
    padding-left: 0;
    padding-right: 3%;
  }
}

// Notification Name Column
.notification {
  padding-right: 3%;

  @media (min-width: @screen-sm-min) {
    padding-left: 1%;
  }

  @media (min-width: @screen-lg-min) {
    padding-left: 0;
  }
}

@media (max-width: @screen-xs-max) {
  #draft-releases {
    th:nth-child(3) {
      padding-right: 2%;
    }
  }
}

table.dashboard-table {
  width: 100%;
  border-collapse: collapse;
  border: 0;

  tr {
    margin: 0;
    padding: 0;
    border: 0;
    background: @color-white;
    border-bottom: 1px solid @color-borders;

    &.odd {
      background: @color-med-grey;
    }

    th {
      margin: 0;
      border: 0;
      background-color: @color-danger;
      font-weight: 700;
      padding: 0 10px;
      text-align: left;

      &:first-child {
        width: 50px;
        text-align: center;
      }

      &:last-child {
        text-align: right;
      }

      &:nth-child(2) {
        width: 40%;
      }

      &:nth-child(3) {
        width: 25%;
      }

      &:nth-child(4) {
        width: 10%;
      }
    }

    td {
      margin: 0;
      padding: 10px;
      border: 0;
      text-align: left;

      &:first-child {
        width: 50px;
        text-align: center;
      }

      &:last-child {
        text-align: right;
      }

      &:nth-child(2) {
        width: 40%;
      }

      &:nth-child(3) {
        width: 25%;
      }

      &:nth-child(4) {
        width: 10%;
      }

      &.sub-table-td-container {
        padding: 0;
      }
    }
  }

  table.networks-sub-table,
  table.policies-sub-table {
    tr {
      border: 1px solid @color-med-grey;

      td {
        background: @color-light-grey;
      }

      th {
        background: @color-grey;
        color: @color-dark-grey;
        border: 1px solid @color-grey;
      }
    }
  }
}

table.permissions-table {
  margin-top: 18px;
}

thead.permissions-header {

  tr,
  .tr {
    background-color: @color-black;
  }

  th,
  .th {
    padding-left: 28px;
  }
}

tbody.permissions-body {

  tr,
  .tr {
    border-bottom: none;
  }

  td,
  .td {
    padding: 8px;
  }
}

/* for style in agreements views */
.status-capitalize {
  text-transform: capitalize;
}

.status-terminated-incomplete {
  color: @color-primary;
  font-weight: 600;
}
