.composing-rights {
  right: 2%;

  .secondary-header {
    div {
      font-weight: normal;
      display: inline;
    }
  }

  .composers-ownership-table {
    table {
      thead {
        background-color: @color-table-header-background;

        td {
          padding: 2px 7px;
        }
      }

      tbody {
        background-color: @color-white;
      }

      tbody,
      tfoot {
        td {
          padding: 7px;

          .form-input {
            margin-top: 0;

            .placeholder({
              font-style: italic
            });
          }
        }
      }
    }
  }

  .composers-ownership-table {
    margin-left: 20px;

    strong {
      font-size: 14px;
    }

    table {
      margin: 10px 0;

      tbody {
        td {
          border: 1px solid @color-borders;
        }
      }

      thead {
        td {
          &:last-child {
            text-align: center;
          }
        }
      }

      tbody,
      tfoot {
        td {
          &:last-child {
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            width: 100px;
          }

          .form-input {
            width: 50px;
            text-align: center;
          }
        }
      }

      tfoot {
        td {
          background-color: transparent;
          text-transform: uppercase;
          font-weight: bold;
          vertical-align: middle;
          border: 1px solid @color-borders;

          &:first-child {
            text-align: right;
            padding-right: 14px;
          }

          &:last-child {
            color: @color-black;
          }
        }
      }
    }
  }
}
