.absolute-popover-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: none;
}

.absolute-popover-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  width: 100%;
  overflow: visible;
  z-index: 2;

  > .absolute-popover-content {
    display: none;

    &.open {
      display: block;
    }
  }
}
