#release-message-form-container,
#agreement-form-container {
  form {
    margin: 0;
  }

  .action-links {
    margin: 15px 4%;
  }

  textarea {
    min-height: 150px;
  }

  #preview {
    display: none;

    #preview-content {
      .messages-list > ul > li {
        .gfm-content();

        min-height: 180px;
      }
    }
  }

  .attached-note {
    display: none;
  }

  #upload-progress-note {
    margin-top: -28px;
    margin-bottom: 0;
  }

  #message-attachments {
    position: fixed;
    top: -1000px;
  }

  .shortcodes {
    dl {
      margin-bottom: 15px;
    }

    dt,
    dd {
      text-transform: none;
      min-height: initial;
      line-height: initial;
      height: auto;
      padding-top: 0;
      padding-bottom: 0;

      @media (max-width: @screen-xs-max) {
        display: block;
        width: auto;
        float: none;
        text-align: center;
      }
    }
  }

  .form-field {
    body.drag-over & {
      textarea {
        outline: none;
        border: 5px dashed fade(@color-secondary, 50%);
      }

      &.drag-over {
        textarea {
          border-color: @color-secondary;
        }
      }
    }
  }
}

.messages-list {
  margin-bottom: @container-margin-bottom;

  > ul > li {
    padding: 20px 3%;
    overflow: auto;
    margin-bottom: 18px;
    border: 1px solid @color-borders;
    border-radius: 18px;
    list-style: none;
  }

  .admin-message {
    text-align: left;
    margin-right: 20%;
    margin-left: 4%;
    background-color: @admin-message-color;

    &.admin-only {
      .message-content {
        border-right: 3px solid @color-error;
      }
    }

    &.user-visible {
      .message-content {
        border-right: 3px solid @color-success;
      }
    }

    @media (max-width: @screen-xs-max) {
      margin-right: 10%;
    }
  }

  .user-message {
    text-align: left;
    margin-left: 20%;
    margin-right: 4%;
    background-color: @user-message-color;

    @media (max-width: @screen-xs-max) {
      margin-left: 10%;
    }
  }

  .unread {
    border-left: 3px solid @color-primary;
    border-right: 3px solid @color-primary;
  }

  .message-info {
    width: auto;
    color: @color-grey;
    font-size: 0.9em;
    .clearfix();
  }

  .message-content {
    width: auto;
    .gfm-content();

    img {
      max-height: 250px;
    }
  }

  .message-attachments {
    border-top: 1px dashed @color-borders;
    padding-top: 5px;
    color: @color-grey;
    font-size: 0.9em;
  }
}

.message-uploads {
  padding-top: 5px;
  color: @color-grey;
  font-size: 0.9em;
}

#draft-releases {
  table {
    th {
      margin: 0;
      padding: 0 10px;

      &:first-child {
        width: 50px;
        text-align: center;
      }

      &:last-child {
        text-align: right;
      }
    }

    td {
      margin: 0;
      padding: 10px;
      border: 0;
      text-align: left;

      &:first-child {
        width: 50px;
        text-align: center;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .title-artist {
    line-height: 2em;
  }

  .btn {
    &.edit {
      background-color: @color-primary;
    }

    &.upload {
      background-color: @color-secondary;
    }
  }

  .networks-column {
    min-width: 178px;

    @media (max-width: 375px) {
      min-width: 130px;
    }
  }

  .upload-column {
    white-space: nowrap;
  }
}

#submitted-releases,
#action-required-releases {
  thead tr {
    background-color: @color-secondary;
  }

  .release-row-action-required {
    background-color: fade(@color-warning, 20%);
  }

  .status-box {
    line-height: 2em;
    text-transform: uppercase;

    &.processing { color: @color-grey; }

    &.pending,
    &.user-complete {
      .self-and-all-links({
        color: @color-secondary;
      });
      .fa::before { content: @fa-var-sync; }
    }

    &.action-required {
      .self-and-all-links({
        color: @color-error;
      });
      .fa::before { content: @fa-var-exclamation-triangle; }
    }

    &.distributed {
      .self-and-all-links({
        color: @color-secondary;
      });
      .fa::before { content: @fa-var-sync; }
    }

    &.scheduled {
      // TODO: ask Mary for new color since this is not a link.
      // .self-and-all-links({
      //   color: @color-primary
      // });;
      .fa::before { content: @fa-var-calendar; }
    }

    &.complete,
    &.published,
    &.submitted {
      .self-and-all-links({
        color: @color-success;
      });
      .fa::before { content: @fa-var-check; }
    }
  }

  .toggle-details {
    .fa {
      position: relative;
      top: -1px;
      font-size: 11px;
    }
  }

  .details-row {
    @background-color: @color-light-grey;

    display: none;
    border-bottom: 1px solid @color-borders;

    td {
      background-color: @background-color;
    }

    &.first-row {
      td {
        border-top: 1px solid shade(@color-borders, 15%);
      }
    }
  }
}

.release-row-networks-column,
.broadcast-row-networks-column {
  @img-height: 34px;
  @img-margin: 6px;

  line-height: @img-height + @img-margin;

  img {
    float: left;
    max-width: @img-height;
    margin: @img-margin/2 @img-margin @img-margin/2 0;
    .grayscale();
  }
}

#genre-selector {
  width: 30%;
  float: left;

  label { display: none; }

  select {
    width: 90%;
    margin: 5%;
  }
}

#release-status {
  h2 {
    float: left;
    width: 50%;
    line-height: 75px;
    padding-left: 20px;
  }

  > .form-field {
    width: 50%;
  }

  @media (max-width: @screen-xs-max) {
    h2,
    .form-field {
      float: none;
      display: block;
      width: auto;
    }
  }

  .checkbox-status {
  }

  nav {
    clear: both;
  }
}

#metadata-form-container {
  padding-bottom: 20px;

  header {
    margin-bottom: 20px;
  }
}

#detail-statistics,
#detail-policy-statistics {
  tr td:first-child {
    font-weight: bold;
  }

  tr th {
    padding-left: 15px;
  }

  td {
    width: 40%;
    padding-left: 15px;

    label {
      @media (max-width: @screen-xs-max) {
        float: none;
        display: block;
      }
    }
  }

  td.status {
    width: 20%;
    white-space: nowrap;

    .broadcast-status-icon {
      padding-right: 0.3em;
    }
  }

  .broadcast-option {
    float: right;
    margin-right: 2%;
    min-width: initial;

    @media (max-width: @screen-xs-max) {
      float: none;
      display: block;
      margin: 14px 2% 8px;
    }
  }
}

#album-releases {
  tr td:first-child {
    font-weight: bold;
  }

  tr th {
    padding-left: 15px;
  }

  td {
    width: 25%;
    padding-left: 15px;

    label {
      @media (max-width: @screen-xs-max) {
        float: none;
        display: block;
      }
    }
  }
}

#detail-workcodes {
  tr th {
    padding-left: 15px;
  }

  td {
    width: 40%;
    padding-left: 15px;
  }
}

#detail-services {
  .price {
    float: right;
    margin-right: 20px;
    font-weight: bold;
  }
}

#detail-metadata,
#detail-metadata-media-section,
#detail-metadata-album-section,
#detail-artist-metadata {
  dt {
    width: 20%;
  }

  dd {
    width: 30%;
  }

  @media (max-width: @screen-xs-max) {
    dt {
      width: 40%;
    }

    dd {
      width: 60%;
    }
  }

  dd,
  dt {
    border-bottom: 1px solid @color-borders;
  }
}

#detail-release-info {
  dl {
    margin-bottom: 0;
  }

  dt,
  dd {
    min-height: inherit;
  }

  @media (max-width: @screen-xs-max) {
    dd {
      text-align: center;
    }
  }
}

#earnings {
  p {
    margin: 25px 2%;
    font-weight: bold;
  }
}

.container {
  &.pending {
    border-color: @color-danger;
  }
}

.network-name {
  vertical-align: middle;
}
